const nl = {
  btnLoading: "Bezig met laden",
  filterNotification: "Je zocht naar {0}",
  general: {
    or: "of",
    next: "volgende",
    wait: "Even wachten svp...",
    warning: "Let op!",
    demo: "Demo",
  },

  messages: {
    unknownError:
      "Er is een fout opgetreden. Probeer het opnieuw of neem contact op met de klantenservice.",
    formError:
      "Er is een fout opgetreden. Controleer het formulier en probeer het opnieuw",
    saved: "Opgeslagen",
    success: "Succes!",
    textCopied: "Tekst gekopieerd naar klembord",
    textCopyError:
      "Er is een fout opgetreden tijdens het kopiëren van de tekst. Selecteer de tekst en kopieer deze handmatig.",
    csrfError:
      "Ongeldige of ontbrekende CSRF-token. Probeer uw verzoek opnieuw in te dienen. Als het probleem aanhoudt, neem dan contact op met de ondersteuning.",
  },

  labels: {
    beta: "Beta",
  },

  fields: {
    email: {
      placeholder: "E-mailadres",
    },
    password: {
      placeholder: "Wachtwoord",
      description: "Minimaal 8 karakters, inclusief 1 hoofdletter en 1 cijfer",
    },
    repeatPassword: {
      placeholder: "Herhaal het nieuwe wachtwoord",
    },
    domain: {
      errors: {
        invalid: "Ontbrekende of ongeldige domeinnaam",
        duplicated: "Duplicaat domeinnaam",
      },
    },
    errors: {
      incorrectValue: "Onjuiste waarde",
      fieldRequired: "Veld is vereist",
      invalidDate: "Ongeldige datum",
      overlappingDate: "Overlappende datum",
    },
  },

  buttons: {
    downloadExcel: "XLSX downloaden",
    downloadCsv: "CSV downloaden",
    generate: "Genereren",
    save: "Opslaan",
    submit: "Verzenden",
    dismiss: "Afwijzen",
    confirm: "Bevestigen",
    clear: "Wissen",
    loading: "Bezig met laden",
    addMore: "Voeg meer toe",
    copy: "Kopiëren",
    create: "Creëren",
    undo: "Ongedaan maken",
    close: "Sluiten",
    remove: "Verwijderen",
    continue: "Doorgaan",
  },

  pages: {
    activityLog: {
      activityLog: "Activiteitenlogboek",
      table: {
        columns: {
          activity: "Activiteit",
          user: "Gebruiker",
          created: "Aangemaakt",
        },
      },
    },
    auth: {
      clickToLogout: "Klik hier om uit te loggen",
      sso: {
        signIn: "Log in bij Knowingo{0}",
        ssoUnavailable:
          "Single Sign-On wordt niet ondersteund door jouw organisatie.",
        redirect: {
          redirectionInProgress: "U wordt doorgestuurd over {0} seconden.",
          redirectManually:
            "Als u niet automatisch wordt doorverwezen, klik dan hier.",
          pleaseWait: "Even wachten svp",
        },
      },
      mfa: {
        authentication: {
          tokenInputLabel: "Authenticatie token",
          tokenInputPlaceholder:
            "Voer de 6-cijferige code in die u ziet in de authenticator app",
          verify: "Verifieer",
        },
        setup: {
          multiFactorAuthentication: "Multi-factor authenticatie instellen",
          step1: "Stap 1: Download een authenticator-app",
          step1Description:
            "Download en installeer een authenticator-app uit een app store (Google Authenticator, Microsoft Authenticator, Authy). U kunt deze stap overslaan als u de app al hebt geïnstalleerd.",
          step2: "Stap 2: QR-code scannen",
          step2Description:
            "Open de Authenticator app scan de QR-code hieronder om Knowingo aan de lijst toe te voegen.",
          step3: "Stap 3: Token invoeren",
          step3Description:
            "Open de Authenticator-app en voer het gegenereerde token in het onderstaande veld in:",
          typeManually:
            'Als u de QR-code niet kunt scannen, tikt u op "Enter a setup key" in de Authenticator-app en gebruikt u de volgende configuratie:',
          continue: "Ga door",
          secret: "Secret",
          keyType: "Type key",
          timeBased: "Tijd gebaseerd",
          accountName: "Accountnaam",
          showManualConfiguration:
            "Klik hier als u de QR-code niet kunt scannen",
        },
      },
      forgotPassword: {
        resetPassword: "Wachtwoord herstellen",
        form: {
          successMessage:
            "Check je email. Je ontvangt een wachtwoord reset link binnen een paar minuten. Geen email ontvangen? Check of het ingevoerde mailadres correct is.",
          emailAddress: "E-mailadres",
        },
        goBack: "Terug naar de loginpagina",
      },
      login: {
        form: {
          emailAddress: "E-mailadres",
          password: "Wachtwoord",
          rememberMe: "Onthouden",
          login: "Log in",
          ssoLogin: "Inloggen met behulp van SSO",
          forgotPassword: "Wachtwoord vergeten?",
        },
        signIn: "Log in bij Knowingo{0}",
      },
    },
    confirmation: {
      forms: {
        passwordReset: {
          header: "Wachtwoord herstellen",
          enterPassword: "Nieuw wachtwoord",
          repeatPassword: "Nieuw wachtwoord bevestigen",
          errors: {
            notSamePasswords: "Wachtwoorden zijn niet gelijk",
          },
          submit: "Nieuw wachtwoord opslaan",
          passwordChanged: "Je wachtwoord is gewijzigd",
          goBack: "Terug naar de loginpagina",
        },
        emailChange: {
          header: "Wijziging e-mailadres",
          emailChangedTo: "Jouw emailadres is aangepast naar {0}",
          contactSupport:
            "Heb je een probleem met inloggen? Neem contact op met support {0}",
          goBack: "Terug naar de loginpagina",
        },
        emailConfirmation: {
          emailConfirmed: "E-mailadres is bevestigd",
        },
        passwordWasReset: "Uw wachtwoord is succesvol gereset!",
        loginToDashboard: "U kunt doorgaan door in te loggen op de {0}.",
        loginToApp:
          "Als u de Knowingo Applicatie gebruikte, kunt u verder spelen door terug te gaan naar de mobiele applicatie of door de {0} te gebruiken.",
        knowingoDashboard: "Knowingo Dashboard",
        knowingoWebApplication: "Knowingo Web Application",
      },
      errors: {
        invalidConfirmation: {
          header: "Ongeldige bevestigingslink",
          paragraph: "De URL die je bezocht is ongeldig of verlopen",
          contactAdministrator:
            "Neem contact op met uw beheerder voor meer informatie.",
        },
        unknownError: {
          header: "Er is een fout opgetreden",
          contactSupport:
            "Er is een fout opgetreden en we konden uw verzoek niet verwerken. Neem contact op met support op {0}",
        },
      },
    },
    trainingConfirmation: {
      thankYouForFeedback: "Dank u voor uw feedback",
      giveFeedback: "Laat ons hieronder weten of u aanwezig was:",
      yes: "Ja",
      no: "Nee",
    },
    signup: {
      errors: {
        outOfSeats: {
          header: "Onvoldoende seats",
          paragraph:
            "Je kunt helaas niet meer inloggen omdat het aantal beschikbare seats in Knowingo voor je organisatie allemaal in gebruik zijn.",
          contactAdministrator:
            "Neem contact op met uw beheerder voor meer informatie.",
        },
        invalidCode: {
          header: "Ongeldig sign-up link",
          paragraph: "De URL die je bezocht is ongeldig of verlopen",
          contactAdministrator:
            "Neem contact op met uw beheerder voor meer informatie.",
        },
        unknownError: {
          header: "Er is een fout opgetreden",
          contactAdministrator:
            "Neem contact op met uw beheerder voor meer informatie.",
        },
        logoutRequired: {
          header: "Uitloggen vereist",
          paragraph:
            "U probeert toegang te krijgen tot bronnen die alleen voor nieuwe gebruikers beschikbaar zijn. Log uit en probeer het opnieuw",
          logout: "Uitloggen",
        },
        signupNotAllowed: {
          header: "Aanmelden niet toegestaan",
          paragraph:
            "Deze organisatie heeft de inschrijving gesloten. Als je je wilt aansluiten bij dit bedrijf in Knowingo neem dan contact op met de beheerder.",
        },
      },
      forms: {
        fillInFields: "Vul alsjeblieft de velden hieronder in",
        personalInformation: "Persoonlijke informatie",
        accountInformation: "Accountinformatie",
        errors: {
          notSamePasswords: "Wachtwoorden zijn niet gelijk",
        },
        firstName: "Voornaam",
        lastName: "Achternaam",
        workEmailAddress: "Zakelijk e-mailadres",
        password: "Wachtwoord",
        repeatPassword: "Wachtwoord bevestigen",
        getStarted: "Begin nu",
        consentCheckbox:
          "Vink hier aan om te bevestigen dat u {0} en {1} hebt gelezen en ermee akkoord gaat.",
        termsOfService: "Knowingo’s Algemene Voorwaarden",
        privacyPolicy: "Privacy policy",
        allowedDomains:
          "Om veiligheidsredenen worden alleen e-mailadressen van goedgekeurde domeinen geaccepteerd. Neem contact op met uw accountbeheerder of de ondersteuning van Knowingo voor meer details.",
      },
      accountActivated:
        "Jouw Knowingo account is geactiveerd en klaar om gebruikt te worden.",
      startLearning:
        "Nu hoef je alleen de app te downloaden en te starten met leren!",
      confirmationSent: "We hebben je zojuist een bevestiginsmail gestuurd.",
      clickOnLink: "Klik op de link in de e-mail en start met leren!",
      usedEmail: "E-mail adres gebruikt voor registratie:",
      step: "Stap",
      createAccount: "Maak je Knowingo account aan",
      downloadApp: "Download de Knowingo app",
      appLinks:
        "Download Knowingo app op je mobiele apparaat door te zoeken naar Knowingo in de app store, of klik op een van de onderstaande buttons",
      readyToPlay: "Klaar om te spelen",
      haveFunLearning: "Veel plezier met leren!",
      broughtBy: "Knowingo wordt je aangeboden door:",
    },
    dashboard: {
      betaNotification:
        "Je gebruikt de beta versie van het Knowingo dashboard.",
      modals: {
        selectCompany: {
          changeCompany: "Verander van organisatie",
          open: "Open",
          cancel: "Annuleren",
        },
      },
    },
    enterpriseStatistics: {
      loading: "Bezig met laden",
      enterpriseStatistics: "Bedrijfsstatistieken",
      beta: "Beta",
      expirationBanner:
        "Vanwege de verbeteringen aan onze Dashboard-widgets en Rapportages heeft Knowingo besloten de Enterprise-statistieken te beëindigen. We hebben de informatie van de Enterprise-statistiekenpagina verplaatst naar andere bronnen. Deze pagina blijft online tot {0}.",
      adoption: {
        adoption: "Adoptie",
        adoptionPerGroup: "Adoptie per groep",
        joined: "Geregistreerd",
        joinedLastWeek: "Geregistreerd afgelopen 7 dagen",
        missing: "Ontbreekt",
        joinedStatus:
          "{0} gebruikers die zich geregistreerd hebben en hun eerste quiz gespeeld hebben.",
        joinedLastWeekStatus:
          "{0} gebruikers die in de laatste 7 dagen geregistreerd en gespeeld hebben. Dit overlapt met geregistreerd.",
        missingStatus:
          "{0} gebruikers zijn mensen die zijn uitgenodigd maar hun account nog niet hebben geregistreerd.",
        status: "Status",
        loading: "Bezig met laden",
        error: "Fout",
        noGroupSelected: "Geen groep geselecteerd",
        noData: "Geen gegevens",
        showMore: "Toon meer",
        email: "E-mail",
        firstName: "Voornaam",
        lastName: "Achternaam",
        users: "Gebruikers",
      },
      certification: {
        certificates: "Certificaten",
        certification: "Certificatie",
        certificationPerGroup: "Certificatie per groep",
        fullyCertified: "Volledig gecertificeerd",
        onTrack: "Op schema",
        expired: "Verlopen",
        overdue: "Verstreken",
        missing: "Ontbreekt",
        fullyCertifiedStatus:
          "{0} gebruikers hebben een geldig certificaat gekregen.",
        onTrackStatus: "{0} gebruikers doorlopen hun leerplan.",
        expiredStatus:
          "{0} gebruikerscertificaten hebben hun geldigheidsduur overschreden.",
        overdueStatus:
          "{0} gebruikers hebben de deadline voor hun verplichte onderwerpen gemist.",
        missingStatus:
          "{0} gebruikers zijn mensen die zijn uitgenodigd maar hun account nog niet hebben geregistreerd.",
        noGroupSelected: "Geen groep geselecteerd",
        noData: "Geen gegevens",
        showMore: "Toon meer",
        email: "E-mail",
        firstName: "Voornaam",
        lastName: "Achternaam",
        topic: "Topic",
        status: "Status",
        downloadAll: "Download alles",
      },
      experts: {
        initialKnowledgeScore: "Initiële kennis",
        selectTopic: "Selecteer topic",
        topic: "Topic",
        expert: "Expert",
        noData: "Geen gegevens",
        yourExperts: "Uw experts",
        loading: "Bezig met laden",
        error: "Fout",
        collapse: "Instorten",
        expand: "Uitbreiden",
      },
      groupSelection: {
        description:
          "Dit zal een op groepen gebaseerd filter toevoegen over alle onderstaande statistieken en de excel download.",
        groupFilter: "Groepsfilter",
      },
      misconception: {
        misconception: "Misconceptie",
        selectTopic: "Selecteer topic",
        topic: "Topic",
        question: "Vraag",
        correctAnswer: "Juiste antwoord",
        noData: "Geen gegevens",
        error: "Fout",
        loading: "Bezig met laden",
      },
      modal: {
        download: "Download",
        close: "Close",
      },
      widget: {
        downloadExcel: "Excel downloaden",
      },
    },
    overview: {
      header: "Dashboard",
      cards: {
        answers: "Juiste antwoorden",
        users: "Actieve gebruikers",
        multiplier: "Kennis multiplier",
      },
      charts: {
        knowledge: {
          header: "Kennis",
          legend: {
            initialKnowledge: "Initiële kennis",
            currentKnowledge: "Huidige kennis",
            potentialKnowledge: "Potentiële kennis",
          },
          inputs: {
            groups: "Alle groepen",
          },
        },
        trainingTime: {
          header: "Tijd getraind",
          legend: {
            minutes: "Minuten",
          },
          inputs: {
            groups: "Alle groepen",
            topics: "Alle topics",
            topicsInLearningPlan: "Kennistopics in leerplan",
          },
        },
        questionsMastered: {
          header: "Mastered vragen",
          legend: {
            questionsMastered: "Mastered vragen",
          },
          inputs: {
            groups: "Alle groepen",
          },
        },
        learningProgress: {
          header: "Gebruiker leerprogressie",
          legend: {
            users: "Gebruikers",
          },
          inputs: {
            groups: "Alle groepen",
            topics: "Alle topics",
          },
        },
      },
      components: {
        dropdown: {
          default: "Standaard",
          companyDashboards: "Bedrijfsdashboards",
          createNew: "Nieuw dashboard maken",
          copy: "Dashboard kopiëren",
          edit: "Dashboard bewerken",
          delete: "Dashboard verwijderen",
        },
        toolbar: {
          addWidget: "Widget toevoegen",
          settings: "Instellingen",
          save: "Dashboard opslaan",
          cancel: "Bewerken annuleren",
        },
        settings: {
          header: "Dashboardinstellingen",
          dashboardName: "Dashboardnaam",
          companyDefault: "Bedrijfsstandaard",
        },
        widgets: {
          header: "Selecteer widgets",
          add: "Toevoegen",
          remove: "Verwijderen",
          charts: "Grafiek",
          knowledge: {
            header: "Kennis",
            description:
              "Bekijk hier de initiële, huidige en potentiële kennis van actieve Knowingo-gebruikers.",
            initialKnowledge:
              "initiële kennis (rood) geeft aan hoeveel kennis gebruikers hadden voordat ze begonnen te leren met Knowingo",
            currentKnowledge:
              "huidige kennis (groen) laat zien hoeveel kennis gebruikers op dit moment hebben geleerd",
            potentialKnowledge:
              "potentiële kennis (blauw) is de hoeveelheid kennis die gebruikers kunnen bereiken op basis van wat er in hun leerplan staat",
          },
          masteredQuestions: {
            header: "Gemasterde vragen",
            description:
              "Het totale aantal vragen dat Knowingo uw gebruikers met succes heeft geleerd!",
          },
          trainingTime: {
            header: "Trainingstijd",
            description:
              "Deze grafiek toont het aantal gespeelde minuten per geselecteerd tijdsbestek, gebruikersgroep en leerplan.",
            minutes: "Minuten",
          },
          learningProgress: {
            header: "Leren voortgang",
            description:
              "Deze grafiek laat zien hoeveel vooruitgang uw medewerkers hebben geboekt in hun leerplan. Nieuwe gebruikers beginnen bij 0% en gebruikers die hun leerplan volledig hebben voltooid, zijn te zien in de kolom 90-100%.",
          },
          leaderBoard: {
            header: "Leaderboard",
            description:
              "Leaderboard toont uw spelersbestand gerangschikt op basis van gespeelde minuten, meeste certificaten en hun expertise. U kunt deze filteren op basis van groepen en onderwerpen. Deze widget laat zien welke spelers de beste leerlingen in uw organisatie zijn.",
          },
          timeToCertify: {
            header: "Tijd om te certificeren",
            description:
              "Tijd om te certificeren toont de gemiddelde tijd die gebruikers nodig hebben om hun certificaat te behalen voor elk onderwerp in minuten.",
            minutes: "Minuten",
          },
          knowledgeGain: {
            header: "Kenniswinst",
            description:
              "De kenniswinst grafiek laat zien hoe de kenniskloof in uw populatie in de loop der tijd kleiner wordt. De Knowledge Gain toont de hoeveelheid kennis die je spelers hebben opgedaan gedurende een bepaalde tijd.",
            knowledgeGain: "Kenniswinst",
            knowledgeDecrease: "Kennisafname",
          },
          certificateStatus: {
            header: "Certificaatstatus",
            description:
              "Deze grafiek toont een uitsplitsing van de certificaatstatussen per onderwerp. Gebruik het om te zien hoe uw gebruikers vorderen met het behalen van hun certificaat.",
            isCertified: "Gecertificeerd",
            isExpired: "Vervallen certificaten",
            isMissing: "Ongeregistreerde gebruikers",
            isMissed: "Gemiste deadline",
            isOnTrack: "Binnen de deadline",
            isUncertified: "Niet gecertificeerd",
            detailedView: "Eenvoudige/gedetailleerde weergave",
          },
          questionAnalytics: {
            header: "Vraag analytics",
            description:
              "Deze widget toont een overzicht van hoeveel vragen de waarschuwingsdrempels hebben bereikt op de verschillende metrieken: misvatting, complexiteit, vanzelfsprekendheid en foutenscore.",
            metric: "Metrisch",
            questions: "Vragen",
            highMisconception: "Hoge misvatting",
            highObviousness: "Hoge overduidelijk",
            highComplexity: "Hoge complexiteit",
            highErrorScore: "Hoge foutenscore",
          },
          adoption: {
            header: "Adoptie",
            description:
              "Deze grafiek toont de globale adoptiestatus van uw populatie. Dit helpt je te bepalen of je gebruikers het registratieproces hebben voltooid en begonnen zijn met het spelen van hun eerste quiz.",
            invited: "Uitgenodigd",
            registered: "Geregistreerd",
            activated: "Geactiveerd",
          },
          overallCompliance: {
            header: "Algemene compliance",
            compliant: "Compliant",
            notCompliant: "Niet compliant",
            current: "Huidig",
            allTime: "Altijd",
          },
          error: {
            header: "Er is een fout opgetreden",
            reload: "Klik hier om te herladen",
          },
          noData: "Geen data om weer te geven",
        },
        dialog: {
          deleteDashboard: {
            header: "Dashboard verwijderen",
            content: "Weet u zeker dat u dashboard {0} wilt verwijderen?",
          },
        },
      },
      messages: {
        dashboardDeleted: "Dashboard is verwijderd",
        dashboardSaved: "Dashboard is opgeslagen",
      },
    },
    profile: {
      form: {
        basicInformation: "Basis informatie",
        firstName: "Voornaam",
        lastName: "Achternaam",
        email: "E-mail",
        passwordSettings: "Wachtwoord instellingen",
        currentPassword: "Huidig wachtwoord",
        newPassword: "Nieuw wachtwoord",
        confirmNewPassword: "Bevestig nieuw wachtwoord",
        languageSettings: "Taalinstellingen",
        dashboardLanguage: "Dashboard taal",
        appLanguage: "App taal",
        default: "Standaard",
        errors: {
          missingUppercase: "Wachtwoord ontbreekt hoofdletter.",
          missingLowercase: "Wachtwoord ontbreekt in kleine letters.",
          missingDigit: "Wachtwoord mist een cijfer.",
        },
      },
      profile: "Mijn profiel",
      api: {
        header: "API-instellingen",
        buttons: {
          showKey: "Toon sleutel",
          hideKey: "Verberg sleutel",
        },
        form: {
          generateKey: "Genereer API-sleutel",
          manageKeys: "Beheer API-toegang",
          keysDescription:
            "Genereer een unieke sleutel om veilig toegang te krijgen tot onze API. Houd er rekening mee dat elke gebruiker slechts één actieve API-sleutel tegelijk mag hebben. Om een nieuwe API-sleutel te genereren, klikt u eenvoudig op de knop hierboven. Uw huidige actieve sleutel, indien aanwezig, wordt ongeldig gemaakt bij het genereren van een nieuwe sleutel.",
          value: "Waarde",
          expiration: "Verloopt op",
          deleteKey: "API-sleutel verwijderen",
          deleteConfirmationPrompt:
            "U staat op het punt een API-sleutel te verwijderen. Alle services die momenteel deze sleutel gebruiken, zullen onmiddellijk de toegang verliezen. Zorg ervoor dat u alle relevante services hebt bijgewerkt met een alternatieve sleutel voordat u doorgaat met het verwijderen.",
        },
        dialogs: {
          delete: {
            header: "Sleutel ongeldig maken",
            confirm:
              "De huidige sleutel wordt ongeldig gemaakt. Wilt u doorgaan?",
          },
        },
        rateLimits: "Snelheidslimieten",
        rateLimitDescription:
          "API-snelheidslimieten worden gebruikt om misbruik te voorkomen, eerlijk gebruik te garanderen en de stabiliteit en prestaties van het systeem te behouden. Als u vragen heeft over het gebruik van de API of als de snelheidslimieten niet voldoende zijn, neem dan contact op met de ondersteuning op {0}.",
        userRateLimit: "Gebruikerssnelheidslimiet",
        companyRateLimit: "Bedrijfssnelheidslimiet",
        requestsPerMinute: "verzoeken per minuut",
        docs: "De OpenAPI-documentatie, die uitgebreide informatie en specificaties biedt voor interactie met de API, is te vinden op {0}",
        authentication: "API-authenticatie",
        authenticationDescription:
          "Volgende verzoeken aan de API moeten de API-sleutel bevatten in de {0}-header om de gebruiker te authenticeren en toegang te verlenen tot de gevraagde bronnen.",
        exampleRequest: "Voorbeeldverzoek",
        gettingStarted: "Aan de slag",
      },
    },
    users: {
      header: "Gebruikers",
      cards: {
        totalUsers: "Gebruikers totaal",
        availableSeats: "Beschikbare seats",
        registeredUsers: "Geregistreerde gebruikers",
        unregisteredUsers: "Ongeregistreerde gebruikers",
        onlineUsers: "Aantal gebruikers online",
      },
      table: {
        messages: {
          usersActivated: "Geselecteerde gebruikers zijn geactiveerd",
          usersDeactivated: "Geselecteerde gebruikers zijn gedeactiveerd",
          invitationSent: "De uitnodiging zal spoedig worden verzonden",
          invitationsSent: "De uitnodigingen zullen snel worden verstuurd",
        },
        controls: {
          inviteButton: "Uitnodigen",
          importButton: "Importeren",
          actionsButton: {
            label: "Acties",
            items: {
              activate: "Activeer",
              deactivate: "Deactiveren",
              resendInvitations: "Uitnodigingen opnieuw versturen",
              delete: "Verwijderen",
            },
          },
          filtersDropdown: {
            registered: "Geregistreerd",
            unregistered: "Ongeregistreerd",
            enabled: "Geactiveerd",
            disabled: "Ongactiveerd",
          },
        },
        columns: {
          select: "Selecteer",
          firstname: "Voornaam",
          lastname: "Achternaam",
          email: "E-mail",
          learningProgress: "Leerprogressie",
          trainingTime: "Minuten getraind",
          certificationStatus: "Certificering status",
          lastSeenOnline: "Laatst online",
          moderator: "Beheerder",
          disabled: "Ongactiveerd",
          invitationDate: "Uitgenodigd",
          invitationStatus: "Status van uitnodiging",
          actions: {
            edit: "Bewerken",
            activate: "Activeren",
            deactivate: "Deactiveren",
            delete: "Verwijderen",
            resendInvitation: "Uitnodigingen opnieuw versturen",
          },
          sso: "SSO",
        },
        dialogs: {
          bulkDelete: {
            header: "Verwijder gebruikers",
            content:
              "Weet u zeker dat u geselecteerde gebruikers wilt verwijderen?",
            confirmationMessage: "Geselecteerde gebruikers zijn verwijderd",
          },
          activationConfirmation: {
            header: "Gebruiker activeren",
            content: "Weet u zeker dat u gebruiker {0} wilt activeren?",
            confirmationMessage: "Gebruiker is geactiveerd",
          },
          deactivationConfirmation: {
            header: "Deactiveer gebruiker",
            content: "Weet u zeker dat u gebruiker {0} wilt deactiveren?",
            confirmationMessage: "Gebruiker is gedeactiveerd",
          },
          deleteConfirmation: {
            header: "Gebruiker verwijderen",
            content: "Weet u zeker dat u gebruiker {0} wilt verwijderen?",
            confirmationMessage: "Gebruiker is verwijderd",
          },
        },
      },
      components: {
        certification: {
          certified: "Gecertificeerd",
          notCertified: "Nog niet gecertificeerd",
          expired: "Verlopen",
          expires: "Verloopt",
          columns: {
            mandatoryContent: "Verplichte inhoud",
            optionalContent: "Aanbevolen inhoud",
            complianceContent: "Compliance inhoud",
            status: "Status",
            startDate: "Startdatum",
            endDate: "Einddatum",
            certifiedAt: "Datum behaald",
            expiresAt: "Geldigheid",
            certifyTo: "Deadline",
          },
          certificateStatus: "Certificaat status",
          downloadAll: "Download alles",
        },
        roles: {
          user: "Gebruiker",
          moderator: "Beheerder",
          admin: "Admin",
          user_manager: "Manager",
          content_creator: "Content beheerder",
          group_leader: "Groeps beheerder",
        },
      },
      details: {
        editUser: "Bewerken",
        inviteUsers: "Gebruikers uitnodigen",
        userInformation: "Gebruiker info",
        certification: "Certificatie",
        trainings: "Opleidingen",
        password: "Wachtwoord",
        passwordForm: {
          label: "Reset gebruikerswachtwoord",
          sendEmail: "Stuur wachtwoord reset link via e-mail",
          generate: "Genereer wachtwoord reset link",
          resetPassword: "Reset",
          userDisabledError:
            "Kan wachtwoord van gedeactiveerde gebruiker niet resetten",
          userNotConfirmedError:
            "Kan wachtwoord van niet bevestigde gebruiker niet resetten",
          userProviderError:
            "Kan wachtwoord van SSO-gebaseerde gebruiker niet resetten",
          linkGeneratedMessage: "Wachtwoord reset link is gegenereerd",
          linkSentMessage:
            "Wachtwoord reset link is naar de gebruiker gestuurd",
        },
        form: {
          firstName: "Voornaam",
          lastName: "Achternaam",
          email: "E-mail",
          groups: "Groepen",
          role: "Rollen",
          dyslexic: "Dyslexie",
          registered: "Geregistreerd",
          status: "Status",
          confirmed: "Bevestigd",
          deactivated: "Gedeactiveerd",
          waitingForConfirmation: "Wachten op bevestiging",
          lastOnline: "Laatst online",
          totalPlaytime: "Totale speeltijd",
          resendConfirmation: "Bevestigingse-mail opnieuw verzenden",
          resendInvitation: "Uitnodiging opnieuw versturen",
          generateLink: "Bevestigingslink genereren",
          invitationSent: "Uitnodiging is verzonden",
          confirmationRequestSent: "Bevestigingslink is verzonden",
          confirmationLink: "Bevestigingslink",
          emailStatus: "E-mailstatus",
          minutes: "minuten",
          dialogs: {
            linkConfirmation: {
              header: "Bevestigingslink aanvragen",
              content:
                "De bevestigingslink staat de gebruiker toe om zijn account te bevestigen en Knowingo te gebruiken. De verbinding is uniek en vertrouwelijk. Deel dit alleen met de eigenaar van het geselecteerde account.",
            },
          },
          metaFields: "Meta-velden",
          fieldName: "Veldnaam",
          fieldData: "Velddata",
        },
      },
      invitation: {
        form: {
          email: "E-mail adres",
          emails: "E-mail adressen",
          groups: "Groepen",
          role: "Rol",
          addMore: "Voeg meer toe",
          groupsDescription:
            "Uitgenodigde gebruikers worden lid van de geselecteerde groepen.",
          groupManagerNote:
            "Uitgenodigde gebruikers moeten handmatig worden toegewezen aan een groep op de Groepen-pagina nadat de uitnodiging is verzonden.",
        },
        successMessage: "De uitnodigingen zijn verstuurd",
        submitButton: "Verzenden",
      },
      import: {
        header: "Gebruikers importeren",
        selectFile: "Bestand kiezen",
        currentlySelectedFile: "Huidig geselecteerd bestand:",
        successMessage: "Upload compleet!",
        usersCreated: "Gebruikers zijn aangemaakt",
        loadingText: "Gegevens uploaden...",
        step1: "Stap 1: Bestand kiezen",
        step2: "Stap 2: Werkblad kiezen",
        step3: "Stap 3: Verifieer gegevens",
        step4: "Stap 4: Upload",
        headers: {
          email: "E-mail",
          groups: "Groepen",
          firstname: "Voornaam",
          lastname: "Achternaam",
          disable_after: "Deactiveren na",
        },
        upload: "Upload",
        back: "Terug naar gebruikerslijst",
      },
      warnings: {
        no_managed_groups:
          "Bezoek de groepenpagina en wijs deze gebruiker toe als manager van ten minste één groep of wijzig de rol.",
      },
    },
    groups: {
      header: "Groepen",
      createGroup: "Groep aanmaken",
      group: "Groep",
      editGroup: "Groep bewerken",
      errors: {
        teamNotEmpty: "Team is niet leeg",
      },
      modals: {
        move: {
          title: "Groepen verplaatsen",
          moving: "In beweging",
          newParent: "Selecteer nieuwe bovenliggende groep",
          move: "Verplaatsen",
        },
      },
      details: {
        form: {
          group: "Groep",
          description: "Beschrijving",
          parent: "Bovenliggende groep",
          name: "Naam",
          parentManager: "Hoofdmanager",
          parentManagerDescription:
            "Deze manager is ingesteld als manager in een van de bovenliggende groepen en kan hier niet worden verwijderd.",
          noManagersAssigned: "Geen managers toegewezen",
        },
        groupInformation: "Groep informatie",
        learningPlan: {
          label: "Leerplan",
          course: "Cursus",
          deadlineOptions: {
            days: "Dagen",
            weeks: "Weken",
            months: "Maanden",
            years: "Jaren",
          },
          table: {
            title: "Titel",
            status: "Status",
            deadline: "Deadline",
            startDate: "Startdatum",
            occurrences: "Voorkomens",
          },
          deadline: "Deadline",
          questions: "vragen",
          published: "Gepubliceerd",
          unpublished: "Niet-gepubliceerd",
          delete: "Verwijderen",
          change: "Wijzigen",
          complianceContent: "Compliancesinhoud",
          mandatoryContent: "Verplichte inhoud",
          optionalContent: "Aanbevolen inhoud",
          indirectTopic: "Indirect topic",
          indirectTopicInfo:
            "Dit topic is overgenomen van een van de ouders en kan op deze plaats niet gewijzigd of verwijderd worden",
          conflictingTopic: "Tegenstrijdig topic",
          conflictingTopicInfo:
            "De deadline of het belang van dit onderwerp kan worden overschreven door een van de bovenliggende topics.",
          topicPresentInCourse: "Topic bestaat al",
          topicPresentInCourseInfo:
            "Dit topic maakt deel uit van een cursus die aan dit leerplan is toegeweze",
          noOccurrences: "Geen voorkomens",
          noOccurrencesInfo:
            "Deze nalevingsinhoud heeft geen voorkomens ingesteld. Er is minstens één voorkomen vereist.",
          accept: "Accepteer",
          cancel: "Annuleren",
          complianceSettings: "Complianceinstellingen",
          startDate: "Startdatum",
          endDate: "Einddatum",
          cutoffDate: "Niet starten na",
          name: "Naam",
          addOccurrence: "Voorkomen toevoegen",
          occurrence: "Voorkomen",
          tooltips: {
            compliance: {
              title: "Wat is compliance?",
              description:
                "Complianceonderwerpen moeten door gebruikers binnen hun ingestelde tijdsbestek worden voltooid. Vragen hoeven slechts één keer te worden beantwoord en bieden een manier om uw gebruikersbestand te controleren.",
              startDate: "Startdatum",
              startDateDescription:
                "Dit is de datum waarop het onderwerp beschikbaar wordt voor de populaties",
              endDate: "Einddatum",
              endDateDescription:
                "Dit is de deadline voor gebruikers om hun nalevingsdatum te bereiken",
              dontStartAfter: "Niet starten na",
              dontStartAfterDescription:
                "Deze datum voorkomt dat het onderwerp verschijnt voor gebruikers die na deze datum aan het leerplan zijn toegevoegd. Dit maakt het beschikbaar voor hen op de volgende startdatum.",
            },
          },
        },
        trainings: {
          label: "Opleidingen",
          indirectTraining: "Indirecte opleiding",
          indirectTrainingInfo:
            "Deze training wordt geërfd van een van de ouders",
        },
        settings: {
          groupLeaders: "Groepsleiders",
          reporting: "Rapportage",
          label: "Instellingen",
          groupRanking: "Groepsklassement",
          impact: "Impact",
          adoption: "Adoptie",
          certification: "Certificatie",
          enableReporting: "Rapportage inschakelen",
          reportType: "Selecteer rapporttype",
          reportFormat: "Selecteer rapport formaat",
          startDate: "Aanvangsdatum",
          hour: "Uur",
          endDate: "Einddatum",
          frequency: "Frequentie",
          dayOfTheWeek: "Dag van de week",
          azureSelectDescription:
            "Leden van geselecteerde Azure groep zullen automatisch gesynchroniseerd worden tijdens SSO sign-in",
        },
        users: {
          label: "Gebruikers",
          table: {
            registered: "Geregistreerd",
            invited: "Uitgenodigd",
            columns: {
              user: "Gebruiker",
              member: "Lid",
              status: "Status",
              actions: "Acties",
            },
            controls: {
              filters: {
                registered: "Geregistreerd",
                invited: "Uitgenodigd",
                member: "Lid",
                notMember: "Geen lid",
              },
            },
          },
        },
      },
      table: {
        columns: {
          select: "Selecteer",
          group: "Groep",
          description: "Beschrijving",
          users: "Gebruikers",
          topics: "Topics",
          actions: {
            edit: "Bewerken",
            delete: "Verwijderen",
            openInTab: "Openen in nieuw tabblad",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Groep verwijderen",
              content: "Weet u zeker dat u groep {0} wilt verwijderen?",
              confirmationMessage: "Groep is verwijderd",
              dataLostWarning:
                "Alle gegevens met betrekking tot deze groep en al zijn subgroepen, zoals groepslidmaatschappen en leerplannen, zullen verloren gaan.",
            },
          },
        },
        controls: {
          createButton: "Groep aanmaken",
          editGroupButton: "Instellingen wijzigen voor {0}",
          actionsButton: "Acties",
          deleteButton: "Verwijderen",
          moveButton: "Verplaatsen",
          dialogs: {
            deleteConfirmation: {
              header: "Groepen verwijderen",
              content:
                "Weet u zeker dat u geselecteerde groepen wilt verwijderen?",
              confirmationMessage: "Geselecteerde groepen zijn verwijderd",
              dataLostWarning:
                "Alle gegevens met betrekking tot de geselecteerde groepen en al hun subgroepen, zoals groepslidmaatschappen en leerplannen, zullen verloren gaan.",
            },
          },
        },
        tooltips: {
          defaultGroup: {
            header: "Standaardgroep",
            content:
              "De standaardgroep bestaat uit alle gebruikers. Leden kunnen niet worden gewijzigd, maar u kunt wel leerplannen beheren die op alle leden van uw organisatie van toepassing zijn.",
          },
        },
      },
    },
    topics: {
      header: "Topics",
      import: {
        successMessage: "Topic is toegevoegd aan uw bibliotheek",
        errors: {
          ownTopic: "Topic bestaat al in uw bibliotheek",
          invalidCode: "Ongeldige share code",
        },
        form: {
          enterCode: "Share code invoeren",
        },
      },
      generate: {
        header: "AI-gegenereerd topic maken",
        form: {
          fields: {
            input: {
              label: "Invoer",
              placeholder: "Waar moet het topic over gaan?",
            },
            numQuestions: {
              label: "Aantal vragen",
            },
            numIntroduction: {
              label: "Hoeveelheid introductiemateriaal",
            },
            baseLanguage: {
              label: "Taal",
            },
            context: {
              label: "Context",
              description:
                "Selecteer het bestand dat door het model zal worden gebruikt om inhoud te genereren.",
            },
          },
          questions: "Vragen",
          introduction: "Introductie",
          introductionMaterials: "Introductie materialen",
          review: "Beoordeling",
          advanced: "Geavanceerd",
        },
        generatingContent: "Inhoud genereren...",
      },
      details: {
        multiLanguage: {
          details: {
            topic: "Topic",
            localization: "Lokalisatie",
            info: "Informatie",
            createdMessage: "Topic is aangemaakt",
            updatedMessage: "Topic data is geüpdatet",
          },
          form: {
            useBaseImage: "Basismedia gebruiken",
            title: "Titel",
            description: "Beschrijving",
            baseLanguage: "Basistaal",
            certified: "Gecertificeerd",
            questions: "vragen",
            backgroundInfo: "achtergrondinformatie",
            introduction: "introductie",
            users: "gebruikers",
            selectLanguage: "Taal kiezen",
            delete: "Verwijderen",
            tooltips: {
              baseTopic: {
                header: "Basistaal",
                content: "Basistaal kan niet worden verwijderd",
              },
            },
          },
          localizationForm: {
            language: "Taal",
            base: "Standaardtaal",
            baseDescription:
              "De als standaard ingestelde lokalisatie is de taal waarin het onderwerp wordt weergegeven op het dashboard. Het zal ook worden gebruikt als bron voor automatische vertaling. En het zal ook worden gebruikt als terugvaloptie in de Knowingo-app als de door de gebruiker geprefereerde taal niet beschikbaar is.",
            addLocalization: "Lokalisatie toevoegen",
          },
        },
        standard: {
          details: {
            createdMessage: "Topic is aangemaakt",
            updatedMessage: "Topic data is geüpdatet",
          },
          form: {
            useBaseImage: "Basismedia gebruiken",
            title: "Titel",
            description: "Beschrijving",
            certified: "Gecertificeerd",
            questions: "vragen",
            backgroundInfo: "achtergrondinformatie",
            introduction: "introductie",
            users: "gebruikers",
            delete: "Verwijderen",
          },
        },
      },
      modals: {
        clone: {
          header: "Topic klonen",
          label: "Voer topicnaam in",
          copy: "kopie",
          clone: "Clone",
          cancel: "Annuleren",
          enterName: "Voer topicnaam in",
        },
        convert: {
          header: "Converteren naar meertalig topic",
          description:
            "After conversion users will keep their statistics and certification.",
          label: "Taal",
          convert: "Convert",
          cancel: "Annuleren",
          selectLanguage: "Taal kiezen",
        },
        publish: {
          header: "Publiceren",
          publish: "Publiceren",
          cancel: "Annuleren",
          multiLanguageTopicInfo:
            "Je staat op het punt om meertalig topic {0} te publiceren.",
          regularTopicInfo:
            "Je staat op het punt {0} vragen te publiceren in topic {1}.",
          publishableQuestionsInfo: "{0} vragen zullen worden gepubliceerd",
          unpublishableQuestionsInfo:
            "{0} onvolledige vragen kunnen niet gepubliceerd worden",
          contentFetchingError:
            "We konden geen data ophalen. Probeer het later nog eens.",
          publishedNotification: "Topic is gepubliceerd",
          publishTopic: "Je staat op het punt om topic {0} te publiceren",
          addMoreQuestions:
            "Voeg {0} meer vragen toe om dit topic te publiceren",
        },
      },
      table: {
        header: "Topics",
        createTopic: "Topic aanmaken",
        importTopic: "Topic importeren",
        editTopic: "Topic aanpassen",
        status: {
          published: "Gepubliceerd",
          publishedDescription: "Dit topic is gepubliceerd en bevat {0} vragen",
          unpublished: "Niet-gepubliceerd",
          metaUnpublishedDescription:
            "Ten minste één van de vertalingen bevat niet-gepubliceerde vragen",
          unpublishedDescription:
            "Er zijn {0} ongepubliceerde vragen in dit topic",
          changed: "Niet-gepubliceerde veranderingen",
          metaChangedDescription:
            "Ten minste één van de vertalingen bevat niet-gepubliceerde wijzigingen",
          changedDescription:
            "Er zijn {0} gewijzigde en {1} ongepubliceerde vragen in dit topic",
          shared: "Gedeeld",
          sharedDescription:
            "Dit topic wordt met u gedeeld door een andere organisatie",
          locked: "Gesloten",
          lockedDescription:
            "Dit topic is gesloten en kan niet worden gewijzigd",
          users: "Niet toegewezen topic",
          usersDescription: "Dit topic is niet toegewezen aan een leerplan",
          meta: "Meertalig topic",
          metaDescription:
            "Dit topic is klaar om naar meerdere talen te worden vertaald ",
          cannotPublish: "Voeg nog {0} vragen toe om dit topic te publiceren.",
        },
        components: {
          languageList: {
            tooltip: {
              title: "Lokalisaties",
              description:
                "Voeg vertalingen toe aan standaardinhoud om ervoor te zorgen dat iedereen evenveel leert",
              unpublishedQuestions: "{0} ongepubliceerde vragen",
              changedQuestions: "{0} veranderde vragen",
              noQuestions: "Geen vragen",
              fullyTranslated: "Volledig vertaald",
              translationMissing: "Vertaling ontbreekt",
            },
          },
        },
        controls: {
          filters: {
            published: "Gepubliceerd",
            unpublished: "Niet-gepubliceerd",
            changed: "Niet-gepubliceerde veranderingen",
          },
          actions: {
            label: "Acties",
            publish: "Publiceren",
            unpublish: "Offline halen",
            delete: "Verwijderen",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Topics verwijderen",
              content:
                "Weet u zeker dat u geselecteerde topics wilt verwijderen?",
              successMessage: "Geselecteerde topics zijn verwijderd",
            },
          },
          enterShareCode: "Share code invoeren",
          createTopic: "Topic toevoegen",
          createTopicAI: "Topic toevoegen met AI",
          createMultiLanguageTopic: "Meertalig topic toevoegen",
        },
        columns: {
          select: "Selecteer",
          topic: "Titel",
          description: "Beschrijving",
          questions: "Vragen",
          users: "Gebruikers",
          status: "Status",
          thumbnail: "Afbeelding",
          comments: "Opmerkingen",
          language: "Taal",
          actions: {
            openQuestions: "Vragen",
            openIntroduction: "Introductie",
            edit: "Bewerken",
            publish: "Publiceren",
            unpublish: "Offline halen",
            delete: "Verwijderen",
            clone: "Clone",
            convert: "Omzetten naar meertalig",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Topic verwijderen",
              content:
                "Ben je zeker dat je topic {0} en al zijn vragen wil verwijderen?",
              successMessage: "Topic is verwijderd",
            },
          },
        },
        publishedSuccessMessage: "Topic is gepubliceerd",
        incompleteItemsWarning:
          "Sommige van de inhoud kon niet worden gepubliceerd vanwege een fout. Controleer of alle inhoud compleet is en probeer het opnieuw.",
        unpublishedSuccessMessage: "Topic is gedepubliceerd",
        removedSuccessMessage: "Topic is verwijderd",
        conversionStarted: "Topic zal spoedig worden omgezet",
        errors: {
          deleteSharedTopic:
            "Kan gedeeld topic niet verwijderen. Trek alle bestaande shares in en probeer het opnieuw.",
        },
      },
      components: {
        tooltips: {
          status: {
            published: "Gepubliceerd",
            unpublished: "Niet-gepubliceerd",
            changed: "Niet-gepubliceerde wijzigingen",
          },
        },
      },
    },
    courses: {
      header: "Cursussen",
      table: {
        controls: {
          create: "Cursus maken",
          shareCode: "Deelcode cursus",
          actions: {
            label: "Acties",
            delete: "Verwijder",
          },
          filters: {
            published: "Gepubliceerd",
            unpublished: "Ongepubliceerd",
          },
        },
        dialogs: {
          deleteConfirmation: {
            header: "Cursussen verwijderen",
            content:
              "Weet je zeker dat je geselecteerde cursussen wilt verwijderen?",
          },
        },
        columns: {
          select: "Selecteer",
          thumbnail: "Afbeelding",
          name: "Naam",
          description: "Beschrijving",
          num_published_topics: "# Gepubliceerde topics",
          languages: "Talen",
          num_topics: "# Topics",
          num_questions: "# Vragen",
          num_users: "# Gebruikers",
          actions: {
            label: "Acties",
            edit: "Bewerk",
            delete: "Verwijder",
          },
        },
      },
      details: {
        header: "Cursus",
        create: "Nieuw",
        navigation: {
          course: "Cursus",
          localization: "Lokalisatie",
          topics: "Topics",
          info: "Informatie",
        },
        form: {
          inputs: {
            name: {
              label: "Naam",
            },
            description: {
              label: "Beschrijving",
            },
            defaultLanguage: {
              label: "Standaard taal",
            },
            courseSettings: {
              label: "Cursusinstellingen",
              text: "Ordening en vergrendeling van onderwerpen",
            },
          },
        },
        topicsForm: {
          topics: "Onderwerpen",
          selectTopic: "Selecteer topic",
          openTopic: "Topic in nieuw tabblad openen",
          showTranslations: "Toon vertalingen",
          hideTranslations: "Verberg vertalingen",
          noTopics: "Geen topics",
        },
        localizationForm: {
          language: "Taal",
          default: "Standaard",
          addLocalization: "Lokalisatie toevoegen",
        },
        localizationModal: {
          header: "Lokalisatie instellen",
          selectDefaultLanguage: "Selecteer standaardtaal",
          selectTranslations: "Selecteer vertalingen",
          saveSettings:
            "Deze instellingen opslaan voor nieuw aangemaakte cursussen",
          changesPossible:
            "Je kunt de standaardtaal nog steeds wijzigen en vertalingen toevoegen of verwijderen nadat dit dialoogvenster is gesloten.",
        },
      },
      dialogs: {
        deleteConfirmation: {
          header: "Delete course",
          content: "Are you sure you want to delete course {0}?",
        },
      },
      tooltips: {
        languageList: {
          translations: "Vertalingen",
        },
        publishedTopics: {
          publishedTopics: "Gepubliceerde topics",
          noPublishedTopics: "Deze cursus bevat geen gepubliceerde topics",
          hasUnpublishedTopics: "Deze cursus bevat onuitgegeven topics",
        },
      },
    },
    shares: {
      library: {
        header: "Kennisbibliotheek",
        createLibraryShare: "Bibliotheekdeel maken",
        editLibraryShare: "Bibliotheekdeel bewerken",
        details: {
          form: {
            required: "Topic is verplicht",
            mustBePublished:
              "Topic moet gepubliceerd zijn om het aan de kennisbibliotheek toe te voegen",
            imageRequired:
              "Topic afbeelding is nodig om het toe te voegen aan kennisbibliotheek",
            sharingExplanation:
              "Delen naar de kennisbibliotheek stelt andere organisaties in staat uw inhoud te gebruiken in hun leerplannen",
            submissionWarning:
              "Vragen, achtergrondinformatie en theorie materiaal kunnen pas worden gewijzigd nadat de indiening is voltooid.",
          },
        },
        table: {
          declined: "geweigerd",
          outdated: "verouderd",
          published: "gepubliceerd",
          inReview: "in herziening",
          columns: {
            select: "Selecteer",
            topic: "Topic",
            holders: "Organisaties",
            created: "Gedeeld",
            hide_questions: "Verberg vragen",
            num_users: "Gebruikers",
            num_total_certificates: "Behaalde certificaten",
            training_minutes: "Getrainde minuten",
            status: "Status",
            actions: {
              label: "Acties",
              update: "Update",
            },
          },
          controls: {
            createShare: "Share toegevoegen",
            actions: {
              label: "Acties",
              updateSelected: "Update geselecteerd",
            },
          },
        },
      },
      sharelinks: {
        header: "Sharelinks",
        createShare: "Share link toegevoegen",
        editShare: "Share link aanpassen",
        details: {},
        table: {
          yes: "Ja",
          no: "Nee",
          controls: {
            createShare: "Share link toegevoegen",
            actions: {
              label: "Acties",
              revoke: "Intrekken",
            },
            dialogs: {
              revokeConfirmation: {
                header: "Share links intrekken",
                content:
                  "Weet u zeker dat u geselecteerde links wilt intrekken?",
                successMessage: "Geselecteerde share links zijn ingetrokken",
              },
            },
          },
          columns: {
            select: "Selecteer",
            topic: "Topic",
            holders: "Organisaties",
            created: "Gedeeld",
            hide_questions: "Verberg vragen",
            num_users: "Gebruikers",
            num_total_certificates: "Behaalde certificaten",
            training_minutes: "Getrainde minuten",
            actions: {
              label: "Acties",
              edit: "Bewerken",
              unhide: "Toon vragen",
              hide: "Verberg vragen",
              revoke: "Intrekken",
            },
            dialogs: {
              revokeConfirmation: {
                header: "Share link intrekken",
                content:
                  "Weet u zeker dat u de geselecteerde share link wilt intrekken?",
                successMessage: "Share link is ingetrokken",
              },
            },
          },
        },
      },
    },
    signupURLs: {
      header: "Sign-up links",
      createUrl: "Sign-up link toegevoegen",
      editUrl: "Sign-up link aanpassen",
      details: {
        urlCreated: "Sign-up link is aangemaakt",
        form: {
          signupURL: "Sign-up link",
          groups: "Groepen",
        },
      },
      settings: {
        form: {
          domainPlaceholder: "Domeinnaam, bijv. knowingo.com",
          add: "Toevoegen",
          enableNotifications: "Meldingen inschakelen",
          frequency: "Frequentie",
          dayOfTheWeek: "Dag van de week",
          dayOfTheWeekDescription:
            "Rapporten worden automatisch verzonden op de laatste gelegenheid van de geselecteerde dag van elke maand.",
          hour: "Uur",
          allowedDomains: "Toegestane domeinen",
          allowedDomainsDescription:
            "Als je een of meer domeinen invoert in het opgegeven veld, worden alleen e-mailadressen die zijn gekoppeld aan die domeinen geaccepteerd voor aanmelding. Gebruikers met e-mailadressen buiten deze gespecificeerde domeinen kunnen geen toegang krijgen tot het platform. Als het veld voor de domeinlijst leeg wordt gelaten, kunnen gebruikers met e-mailadressen van elk domein zich zonder beperkingen aanmelden.",
          notifications: "Meldingen",
          save: "Opslaan",
          addDomain: "Domein toevoegen",
        },
      },
      table: {
        controls: {
          createLink: "Sign-up link toegevoegen",
          settings: "Instellingen",
          actions: {
            label: "Acties",
            refresh: "Vernieuwen",
            delete: "Verwijderen",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Sign-up links verwijderen",
              content:
                "Weet u zeker dat u geselecteerde sign-up links wilt verwijderen?",
              successMessage: "Geselecteerde sign-up links zijn verwijderd",
            },
          },
        },
        columns: {
          code: "Code",
          groups: "Groepen",
          created: "Aangemaakt",
          actions: {
            copyLink: "Kopiëren",
            edit: "Bewerken",
            refresh: "Vernieuwen",
            delete: "Verwijderen",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Sign-up link verwijderen",
              content:
                "Weet u zeker dat u de geselecteerde sign-up links wilt verwijderen?",
              successMessage: "Sign-up links is verwijderd",
            },
          },
        },
      },
    },
    feedback: {
      header: "Feedback",
      details: {
        commentDeleted: "Opmerking verwijderd",
        cannotDeleteComment:
          "Kon opmerking niet verwijderen. Probeer het later opnieuw",
        delete: "Verwijderen",
        status: "Status",
        new: "Nieuw",
        resolved: "Opgelost",
        closed: "Gesloten",
        responseSaved: "Reactie opgeslagen",
        yourResponse: "Uw reactie",
        addResponse: "Reactie toevoegen",
        notifyFeedbackAuthor: "Stuur reactie naar afzender",
      },
      table: {
        filters: {
          new: "Nieuw",
          resolved: "Opgelost",
          closed: "Gesloten",
        },
        controls: {
          actions: {
            label: "Acties",
            delete: "Verwijderen",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Feedback verwijderen",
              content:
                "Weet u zeker dat u geselecteerde feedback items wilt verwijderen?",
              successMessage: "Geselecteerde feedback items zijn verwijderd",
            },
          },
        },
        columns: {
          select: "Selecteer",
          firstname: "Voornaam",
          lastname: "Achternaam",
          email: "Email",
          title: "Titel",
          text: "Tekst",
          status: "Status",
          created: "Aangemaakt",
          responses: "Reacties",
          actions: {
            label: "Acties",
            delete: "Verwijderen",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Feedback verwijderen",
              content: "Weet u zeker dat u feedback {0} wilt verwijderen?",
              successMessage: "Feedback is verwijderd",
            },
          },
          deletedUser: "Verwijderde gebruiker",
        },
      },
    },
    questionAnalytics: {
      header: "Vraaganalyse",
      details: {
        components: {
          cards: {
            misconception: "Misconceptie",
            misconceptionDescription:
              "Er is sprake van misconceptie als een significant deel van de gebruikers hetzelfde verkeerde antwoord geeft. Gebruik deze analyse om foutieve veronderstellingen te onderkennen.",
            complexity: "Complexiteit",
            complexityDescription:
              "Vragen zijn complex als de gebruikers meer tijd nodig hebben dan verwacht om de vraag te beantwoorden. Dit kan het gevolg zijn van de lengte van de vraag, gebruik van moeilijke jargon, of de vraag hoort niet thuis in het topic. Verbeter de moeilijkste vragen voor betere leerresultaten.",
            obviousness: "Overduidelijk",
            obviousnessDescription:
              "Vragen zijn overduidelijk als de gebruikers het goede antwoord al weten zonder het te leren. Als Knowingo+ merkt dat een groot deel van de populatie de eerste keer het juiste antwoord geeft dan gaat de overduidelijkheid score omhoog.",
            error: "Error",
            errorDescription:
              "De fout score geeft aan of de experts het niet eens lijken te zijn met het correcte antwoord op deze vraag. Wanneer het merendeel van de experts in een topic hetzelfde foute antwoord kiezen, is het zeer waarschijnlijk dat er een probleem met je vraag is, of dat het foute antwoord dat je experts hebben gekozen eigenlijk het correcte antwoord is.",
            checkmarkDescription:
              "U kunt op het vinkje klikken om te voorkomen dat de foutscore wordt weergegeven in de lijst Question Analytics.",
            hideErrorScoreWarning: "Waarschuwing foutscore uitschakelen",
          },
        },
        answerContribution:
          "{0} van je gebruikers heeft {1} gekozen als het correcte antwoord",
        expertsAgree:
          "De meerderheid van experts zijn het erover eens dat dit het correcte antwoord is",
        expertsNotAvailable:
          "Er zijn nog geen expertstatistieken beschikbaar voor deze vraag ",
        expertsContribution:
          "{0} van de experts denkt dat {1} het correcte antwoord is",
        sec: "sec",
        avgAnsweringSpeed: "Gemiddelde antwoord snelheid",
        usersIntroduced: "Gebruikers geïntroduceerd",
        answerStatistics: "Antwoord statistieken",
        openQuestion: "Open de vraag",
      },
      table: {
        published: "Gepubliceerd",
        changed: "Niet-gepubliceerde wijzigingen",
        unpublished: "Niet-gepubliceerd",
        incomplete: "Incomplete",
        details: "Details",
        goToQuestion: "Ga naar vraag",
        columns: {
          category_name: "Topic",
          title: "Vraag",
          status: "Status",
          misconception_score: "Misconceptie",
          complexity_score: "Complexiteit",
          obviousness_score: "Overduidelijk",
          content_error_score: "Fout score",
          num_users_seen: "Gezien door",
          num_users: "Beschikbaar voor",
        },
        controls: {
          showWithoutMetrics: "Toon vragen zonder statistieken",
          filters: {
            published: "Gepubliceerd",
            unpublished: "Niet-gepubliceerd",
            changed: "Niet-gepubliceerde wijzigingen",
            high_misconception: "Hoge misvatting",
            high_obviousness: "Hoge overduidelijk",
            high_complexity: "Hoge complexiteit",
            high_error_score: "Hoge fout score",
          },
          allTopics: "Alle topics",
          allGroups: "Alle groepen",
          allLanguages: "Alle talen",
        },
      },
    },
    contentLibrary: {
      header: "Kennisbibliotheek",
      grid: {
        questions: "vragen",
        backgroundInformation: "achtergrondinformatie",
        introductionMaterial: "introductie materiaal",
        owned: "Eigenaar",
        add: "Toevoegen",
        languages: "Beschikbare talen",
        topicAdded: "Topic is toegevoegd aan uw bibliotheek",
      },
    },
    reporting: {
      newReport: "Nieuw rapport",
      table: {
        actions: {
          delete: "Verwijder",
          run: "Ren",
          edit: "Bewerk",
        },
        controls: {
          actions: {
            label: "Acties",
            delete: "Verwijder",
            run: "Ren",
          },
          create: "Rapportagelijn creëren",
        },
        columns: {
          name: "Naam",
          report: "Rapporteer",
          users: "Ontvangers",
          creator: "Gemaakt door",
          frequency: "Frequentie",
          enabled: "Ingeschakeld",
        },
        filters: {
          ownReports: "Mijn rapporten",
        },
      },
      dialogs: {
        delete: {
          header: "Rapport verwijderen",
          content: "Weet je zeker dat je rapport {0} wilt verwijderen?",
        },
        deleteMany: {
          header: "Geselecteerde rapporten verwijderen",
          content:
            "Weet je zeker dat je geselecteerde rapporten wilt verwijderen?",
        },
      },
      components: {
        allTeams: "Alle groepen",
        allTopics: "Alle topics",
        selected: "Geselecteerd",
        reportFormat: "Rapport formaat",
        selectGroups: "Selecteer groepen",
        selectCompliance: "Selecteer compliance",
      },
      adoption: "Adoptie",
      groupRanking: "Groepsklassement",
      questionAnalytics: "Vraaganalyse",
      impact: "Impact",
      certificates: "Certificatie",
      userDump: "Gebruikers dump",
      compliance: "Compliance",
      header: "Rapportage",
      selectReportType: "Selecteer rapport formaat",
      details: {
        reportSettings: "Rapportinstellingen",
        recipients: "Ontvangers",
      },
      oneTimeReport: "Eenmalig rapport",
      reoccurringReport: "Terugkerend rapport",
    },
    settings: {
      account: {
        header: "Accountinformatie",
        form: {
          basicInformation: "Basis informatie",
          companyName: "Organisatie",
          street: "Straat",
          postcode: "Postcode",
          city: "Stad",
          state: "Provincie/Staat",
          country: "Land",
          phone: "Telefoonnummer",
          vat: "BTW-nr.",
          contact: "Contact",
          firstName: "Voornaam",
          lastName: "Achternaam",
          email: "E-mail adres",
        },
      },
      general: {
        header: "Algemene instellingen",
        form: {
          languageSettings: "Taal instellingen",
          dashboardLanguage: "Dashboard taal",
          appLanguage: "App taal",
          description:
            "Taalinstellingen zijn van toepassing op nieuwe gebruikers en kunnen in het gebruikersprofiel worden overschreven.",
          dashboardLanguageDescription:
            "Bepaal de standaardtaal van de gebruikersinterface.",
          appLanguageDescription:
            "Bepaal de standaardtaal van de app en de e-mails die je verstuurt.",
        },
      },
      whiteLabeling: {
        header: "White labelling",
        form: {
          companyLogo: "Bedrijfslogo",
          personalizeParagraph:
            "Personaliseer Knowingo met uw logo. Het logo zal in de app op verschillende schermen te zien zijn met een optioneel bericht. Op de registratiepagina, dashboard en op verschillende e-mails.",
          uploadLogoDark: "Upload je logo voor donkere achtergronden",
          uploadLogoLight: "Upload je logo voor lichte achtergronden",
          additionalText: "Extra logotekst",
          additionalTextDescription:
            "Extra logotekst wordt in de Knowingo app getoond aan al uw medewerkers.",
          additionalEmailText: "Extra e-mail tekst",
          additionalEmailTextDescription:
            "In alle uitnodigings- en herinneringsmails die Knowingo naar uw medewerkers stuurt, wordt een extra e-mailtekst opgenomen.",
        },
        preview: {
          appPreview: "App-voorbeeld",
          registrationPreview: "Registratie-voorbeeld",
          toggle: "Wisselen tussen lichte en donkere modusvoorbeelden",
        },
      },
      webhooks: {
        header: "Webhooks",
        table: {
          controls: {
            create: "Nieuwe webhook maken",
            shareCode: "Webhook-deelcode",
            actions: {
              label: "Acties",
              delete: "Verwijderen",
            },
            filters: {
              published: "Gepubliceerd",
              unpublished: "Niet gepubliceerd",
            },
          },
          dialogs: {
            createConfirmation: {
              successMessage: "Webhook gemaakt",
            },
            updateConfirmation: {
              successMessage: "Webhook bijgewerkt",
            },
            deleteConfirmation: {
              header: "Webhook verwijderen",
              content: "Weet je zeker dat je deze Webhook wilt verwijderen?",
              successMessage: "Webhook verwijderd.",
            },
            notifyConfirmation: {
              successMessage: "Geldige URL",
              invalidUrl: "Ongeldige URL",
              urlValiderrorMessage: "Geldige URL. POST-methode niet toegestaan",
              urlValiderrorNotFound: "Geldige URL. POST-methode niet gevonden",
            },
          },
          columns: {
            name: "Naam",
            event_names: "Gebeurtenisnamen",
            callback_url: "Terugbel-URL",
            enabled: "Ingeschakeld",
            num_failures: "# Mislukkingen",
            actions: {
              label: "Acties",
              delete: "Verwijderen",
              edit: "Webhook bewerken",
              trigger: "Trigger webhook",
            },
          },
        },
        details: {
          webhookCreated: "Webhook gemaakt",
          webhookUpdated: "Webhook bijgewerkt",
          form: {
            name: "Naam",
            eventName: "Evenement",
            callbackURL: "URL",
            notifyWebhook: "Test mij!",
            invalidUrl: "Ongeldige URL",
          },
        },
        tooltips: {
          languageList: {
            translations: "Vertalingen",
          },
          publishedWebhooks: {
            publishedWebhooks: "Gepubliceerde webhooks",
            noPublishedWebhooks:
              "Deze cursus bevat geen gepubliceerde webhooks",
            hasUnpublishedWebhooks:
              " Deze cursus bevat niet-gepubliceerde webhooks",
          },
        },
        createWebhook: "Webhook maken",
        editWebhook: "Webhook bewerken",
      },
      api: {
        header: "API-instellingen",
        form: {
          manageKeys: "Beheer API-sleutels",
          createApiKey: "API-sleutel maken",
          description: "Beschrijving",
          value: "Waarde",
          created: "Aangemaakt",
          lastUsed: "Laatst gebruikt",
          deleteKey: "API-sleutel verwijderen",
          deleteConfirmationPrompt:
            "Je staat op het punt een API-sleutel te verwijderen. Alle services die momenteel deze sleutel gebruiken, zullen onmiddellijk de toegang verliezen. Zorg ervoor dat je alle relevante services hebt bijgewerkt met een alternatieve sleutel voordat je doorgaat met het verwijderen.",
        },
      },
    },
    questions: {
      header: "Vragen",
      topics: "Topics",
      questions: "Vragen",
      introductionMaterial: "Introductie materiaal",
      createQuestion: "Vraag toevoegen",
      editQuestion: "Vraag aanpassen",
      modals: {
        copy: {
          header: "Kopieer vragen",
          successMessage: "Vragen zijn gekopieerd",
          metaTopicError:
            "Het topic van bestemming moet dezelfde set talen hebben als het topic van herkomst",
          save: "Kopiëren",
          cancel: "Annuleren",
        },
        move: {
          header: "Vragen verplaatsen",
          successMessage: "Vragen zijn verplaatst",
          metaTopicError:
            "Het topic van bestemming moet dezelfde set talen hebben als het topic van herkomst",
          save: "Verplaatsen",
          cancel: "Annuleren",
        },
        addTags: {
          header: "Tags toevoegen",
          save: "Opslaan",
          cancel: "Annuleren",
        },
        removeTags: {
          header: "Tags verwijderen",
          save: "Opslaan",
          cancel: "Annuleren",
        },
        autoTranslate: {
          header: "Automatisch vertalen",
          translate: "Vertalen",
          cancel: "Annuleren",
          warning:
            "Deze actie zal bestaande inhoud overschrijven in geselecteerde vragen voor geselecteerde talen.",
          generatingTranslations: "Vertalingen genereren",
          incompleteTranslations:
            "Het vertalen is voltooid, maar sommige inhoud is niet vertaald vanwege een fout. Bekijk deze stukken inhoud en probeer het opnieuw.",
        },
      },
      table: {
        status: {
          published: "Gepubliceerd",
          publishedDescription:
            "Deze vraag is gepubliceerd en beschikbaar om te spelen",
          unpublished: "Niet-gepubliceerd",
          unpublishedDescription: "Deze vraag is niet gepubliceerd",
          changed: "Niet-gepubliceerde wijzigingen",
          changedDescription: "Deze vraag bevat ongepubliceerde wijzigingen",
          incomplete: "Onvolledig",
          incompleteDescription:
            "Deze vraag is onvolledig en kan niet worden gepubliceerd",
          backgroundInfo: "Achtergrondinformatie",
          backgroundInfoDescription: "Deze vraag bevat achtergrondinformatie",
          noBackgroundInfoDescription:
            "Deze vraag bevat geen achtergrondinformatie",
        },
        controls: {
          createQuestion: "Vraag toevoegen",
          actions: {
            label: "Acties",
            publish: "Publiceren",
            unpublish: "Offline halen",
            copy: "Kopiëren",
            move: "Verplaatsen",
            delete: "Verwijderen",
            addTags: "Tags toevoegen",
            removeTags: "Tags verwijderen",
            autoTranslate: "Automatisch vertalen",
          },
          filters: {
            published: "Gepubliceerd",
            unpublished: "Niet-gepubliceerd",
            changed: "Niet-gepubliceerde wijzigingen",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Vragen verwijderen",
              content:
                "Weet u zeker dat u geselecteerde vragen wilt verwijderen?",
              successMessage: "Geselecteerde vragen zijn verwijderd",
            },
          },
        },
        columns: {
          select: "Selecteren",
          thumbnail: "Afbeelding",
          question: "Vraag",
          tags: "Tags",
          status: "Status",
          comments: "Opmerkingen",
          backgroundInfo: "Achtergrondinformatie",
          actions: {
            edit: "Bewerken",
            publish: "Publiceren",
            publishAll: "Alle vertalingen publiceren",
            unpublish: "Offline halen",
            unpublishAll: "Alle vertalingen offline halen",
            delete: "Verwijderen",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Vraag verwijderen",
              content: 'Weet u zeker dat u vraag "{0}" wilt verwijderen?',
              successMessage: "Vraag is verwijderd",
            },
          },
        },
      },
      details: {
        components: {
          comments: {
            deletedMessage: "Opmerking is verwijderd",
            savedMessage: "Opmerking is toegevoegd",
            delete: "Verwijderen",
            yourComment: "Jouw reactie",
            addComment: "Toevoegen reactie",
          },
        },
        form: {
          useBaseImage: "Basismedia gebruiken",
          question: "Vraag",
          answers: "Antwoorden",
          tags: "Tags",
          backgroundInformation: "Achtergrondinformatie",
          enableBackgroundInformation: "Achtergrondinformatie",
          majorChange: "Belangrijke wijziging",
          majorChangeDescription:
            "Schakel dit in wanneer je een inhoudelijke verandering hebt gemaakt in de vraag. Spelers krijgen deze dan als nieuwe vraag, daarnaast worden de statistieken gereset.",
          lastUpdate: "Laatst aangepast",
          title: "Titel",
          description: "Beschrijving",
          comments: "Opmerkingen",
          preview: "Voorbeeld",
          createdMessage: "Vraag is aangemaakt",
          updatedMessage: "Vraag is geüpdatet",
          commentsUnavailable:
            "Commentaarsectie is niet beschikbaar in gedeelde of vergrendelde topics",
        },
        errors: {
          itemChanged:
            "Vraag veranderd op server tijdens bewerken. Vernieuw de pagina en dien de wijzigingen opnieuw in.",
          problemsOccurred:
            "Er zijn problemen opgetreden bij het opslaan van de volgende vertalingen:",
        },
      },
      messages: {
        questionPublished: "Vraag is gepubliceerd",
        questionsPublished: "Geselecteerde vragen zijn gepubliceerd",
        questionUnpublished: "Vraag is niet gepubliceerd",
        questionsUnpublished: "Geselecteerde vragen zijn niet gepubliceerd",
        incompleteQuestionsNotPublished:
          "{0} onvolledige vragen konden niet worden gepubliceerd",
        incompleteQuestionNotPublished:
          "{0} onvolledige vraag kon niet worden gepubliceerd",
      },
    },
    introduction: {
      header: "Introductie",
      topics: "Topics",
      questions: "Vragen",
      introductionMaterial: "Introductie materiaal",
      createIntroduction: "Toevoegen",
      editIntroduction: "Bewerken",
      table: {
        status: {
          published: "Gepubliceerd",
          publishedDescription:
            "Introductie is gepubliceerd en beschikbaar om te spelen",
          unpublished: "Niet-gepubliceerd",
          unpublishedDescription: "Introductie is ongepubliceerd",
          changed: "Veranderd",
          changedDescription: "Introductie bevat een ongepubliceerde wijziging",
          incomplete: "Incomplete",
          incompleteDescription:
            "Introductie is onvolledig en kan niet worden gepubliceerd",
        },
        controls: {
          createIntroduction: "Toevoegen",
          actions: {
            label: "Acties",
            publish: "Publiceren",
            unpublish: "Offline halen",
            delete: "Verwijderen",
            autoTranslate: "Automatisch vertalen",
          },
          dialogs: {
            deleteConfirmation: {
              header: "Introductie verwijderen",
              content:
                "Weet u zeker dat u geselecteerde introductiematerialen wilt verwijderen?",
              successMessage: "Introductie materiaal is verwijderd",
            },
          },
          messages: {
            selectedPublished:
              "Geselecteerd introductiemateriaal is gepubliceerd",
            selectedUnpublished:
              "Geselecteerd introductiemateriaal is ongepubliceerd",
          },
        },
        messages: {
          published: "Introductiemateriaal is gepubliceerd",
          unpublished: "Introductiemateriaal is ongepubliceerd",
        },
        published: "Gepubliceerd",
        changed: "Veranderd",
        unpublished: "Niet-gepubliceerd",
        incomplete: "Incomplete",
        columns: {
          select: "Selecteer",
          title: "Introductie",
          thumbnail: "Afbeelding",
          status: "Status",
          actions: {
            edit: "Bewerken",
            moveUp: "Omhoog plaatsen",
            setIndex: "Index bepalen",
            moveDown: "Omlaag plaatsen",
            publish: "Publiceren",
            unpublish: "Offline halen",
            publishAll: "Alle vertalingen publiceren",
            unpublishAll: "Alle vertalingen offline halen",
            delete: "Verwijderen",
          },
          dialogs: {
            replaceIndex: {
              header: "Index wijzigen",
              save: "Opslaan",
              successMessage: "Index is ververst",
            },
            deleteConfirmation: {
              header: "Introductie verwijderen",
              content: 'Weet u zeker dat u introductie "{0}" wilt verwijderen',
              successMessage: "Introductie materiaal is verwijderd",
            },
          },
        },
      },
      details: {
        introduction: "Introductie",
        text: "Tekst",
        form: {
          useBaseImage: "Basismedia gebruiken",
        },
        createdMessage: "Introductie is aangemaakt",
        updatedMessage: "Introductie is geüpdatet",
        errors: {
          itemChanged:
            "Introductie veranderd op server tijdens bewerken. Vernieuw de pagina en dien de wijzigingen opnieuw in.",
          problemsOccurred:
            "Er zijn problemen opgetreden bij het opslaan van de volgende vertalingen:",
        },
      },
      modals: {
        autoTranslate: {
          header: "Automatisch vertalen",
          translate: "Vertalen",
          cancel: "Annuleren",
          warning:
            "Deze actie zal bestaande inhoud overschrijven in geselecteerde introductie-items voor geselecteerde talen.",
          generatingTranslations: "Vertalingen genereren",
          incompleteTranslations:
            "Het vertalen is voltooid, maar sommige inhoud is niet vertaald vanwege een fout. Bekijk deze stukken inhoud en probeer het opnieuw.",
        },
      },
      messages: {
        introductionItemPublished: "Introductieitem is gepubliceerd",
        introductionItemsPublished:
          "Geselecteerde introductieitems zijn gepubliceerd",
        introductionItemUnpublished:
          "Geselecteerd introductieitem is niet gepubliceerd",
        introductionItemsUnpublished:
          "Geselecteerde introductieitems zijn niet gepubliceerd",
        incompleteIntroductionItemsNotPublished:
          "{0} onvolledige introductieitems konden niet worden gepubliceerd",
        incompleteIntroductionItemNotPublished:
          "{0} onvolledig introductieitem kon niet worden gepubliceerd",
      },
    },
    certification: {
      header: "Certificering",
      table: {
        filters: {
          certified: "Gecertificeerd",
          onTrack: "Op schema",
          uncertified: "Niet-gecertificeerd",
          expired: "Verlopen",
          missed: "Achterstallig",
          mandatory: "Verplicht",
          optional: "Aanbevolen",
        },
        columns: {
          firstName: "Voornaam",
          lastName: "Achternaam",
          email: "E-mail",
          status: "Certificaatstatus",
          topic: "Topic",
          isMandatory: "Verplicht",
          deadline: "Deadline",
          certifiedAt: "Datum verkregen",
          expiresAt: "Geldigheid",
        },
      },
    },
    externalTraining: {
      header: "Externe opleiding",
      table: {
        controls: {
          actions: {
            label: "Acties",
            delete: "Verwijder",
            edit: "Bewerk",
          },
          create: "Opleiding creëren",
        },
        columns: {
          name: "Naam externe opleiding",
          num_users: "# Gebruikers",
          num_invited: "# Gebruikers uitgenodigd",
          num_completed: "# Gebruikers voltooid",
          tags: "Tags",
        },
      },
      details: {
        createTraining: "Opleiding creëren",
        editTraining: "Opleiding bewerken",
        form: {
          group: "Groep",
          users: "Gebruikers",
          noGroupsSelected: "Geen groepen geselecteerd",
          inputs: {
            name: {
              label: "Naam opleiding",
            },
            groups: {
              placeholder: "Groep toevoegen",
            },
          },
        },
      },
      dialogs: {
        deleteConfirmation: {
          header: "Opleiding schrappen",
          content: "Weet je zeker dat je training {0} wilt verwijderen?",
        },
        deleteManyConfirmation: {
          header: "Schrappen van opleidingen",
          content:
            "Weet u zeker dat u geselecteerde trainingen wilt verwijderen?",
          dataLostWarning:
            "Deze actie is permanent. Alle gegevens over de geselecteerde opleidingen gaan verloren.",
        },
      },
      users: {
        externalTraining: "Externe opleiding",
        table: {
          controls: {
            actions: {
              label: "Acties",
              invite: "Uitnodigen",
              setStatus: "Status wijzigen",
            },
            filters: {
              readyForInvite: "Klaar om uit te nodigen",
              invited: "Uitgenodigd",
              completed: "Voltooid",
              incomplete: "Onvolledig",
            },
            downloadList: "Lijst downloaden",
          },
          columns: {
            email: "E-mail",
            start: "Uitgenodigd voor",
            location: "Locatie",
            status: "Status",
            tags: "Tags",
          },
        },
        modals: {
          update: {
            header: "Geselecteerde update",
            selectStatus: "Selecteer status",
          },
        },
        invite: {
          header: "Gebruikers uitnodigen",
          form: {
            createNewEvent: "Nieuwe evenement creëren",
            selectExistingEvent: "Selecteer bestaande gebeurtenis",
            selectEvent: "Selecteer evenement",
            event: "Evenement",
            invite: "Uitnodigen",
            startDate: "Startdatum",
            selectStartDate: "Selecteer startdatum",
            endDate: "Einddatum",
            selectEndDate: "Selecteer einddatum",
            location: "Locatie",
            enableCustomInvitationMessage: "Aangepast uitnodigingsbericht",
            customInvitationMessage: "Aangepast bericht voor uitnodigingsmail",
            enableCustomCompletionMessage: "Aangepast voltooiingsbericht",
            customCompletionMessage: "Aangepast bericht voor voltooiing e-mail",
            errors: {
              startInPast:
                "De begindatum kan niet in het verleden worden ingesteld",
              endBeforeStart:
                "Einddatum kan niet worden ingesteld vóór begindatum",
            },
          },
        },
      },
    },
    marketplace: {
      header: "Marktplaats",
      bundle: {
        lastPublished: "Laatst gepubliceerd",
        availableIn: "Beschikbaar in",
        tags: "Tags",
        getQuote: "Ontvang een offerte",
        bundleIncludes: "Deze bundel bevat",
        numCourses: "{0} cursussen",
        numTopics: "Totaal {0} onderwerpen",
        numBackgroundInformation: "{0} achtergrondinformatie",
        numIntroductionMaterial: "{0} introductiemateriaal",
        numQuestions: "{0} vragen",
        continuousSupport: "Continue Knowingo-ondersteuning",
        certifiedContent: "Knowingo-gecertificeerd materiaal",
        openBundle: "Open bundel",
        owned: "In bezit",
        topics: "Topics",
        courses: "Cursussen",
        quoteModal: {
          header: "Vraag een offerte aan",
          content:
            "Door dit in te dienen, wordt het Knowingo verkoopteam op de hoogte gebracht om een offerte te verstrekken. Ze zullen contact met je opnemen om de voorwaarden voor toegang tot deze inhoud vast te stellen.",
          confirmation: "Offerteaanvraag is verzonden",
        },
      },
      components: {
        tagFilter: {
          tags: "Tags",
        },
        languageFilter: {
          languages: "Talen",
        },
        contentFilter: {
          generalContent: "Algemene inhoud",
          privateContent: "Privé inhoud",
        },
        ownedFilter: {
          bundles: "Bundels",
          all: "Alle bundels",
          owned: "Alleen eigendomsbundels",
          not_owned: "Alleen niet-eigendomsbundels",
        },
      },
    },
    messages: {
      header: "Berichten",
      table: {
        controls: {
          createMessage: "Bericht maken",
        },
        columns: {
          select: "Selecteren",
          subject: "Onderwerp",
          status: "Status",
          recipients: "Ontvangers",
        },
        scheduledFor: "Gepland voor",
        processing: "Verwerken",
        sentAt: "Verzonden op",
        more: "+{0} meer",
      },
      details: {
        editMessage: "Bericht bewerken",
        createMessage: "Bericht maken",
        now: "Nu",
        scheduleFor: "Plannen voor",
        notifications: {
          messageSent:
            "Het bericht is opgeslagen en wordt binnenkort verzonden.",
          messageScheduled:
            "Het bericht is opgeslagen en wordt verzonden om: {0}",
        },
        messageSubject: "Onderwerp van het bericht",
        subject: "Onderwerp",
        messageBody: "Berichtinhoud",
        body: "Bericht",
        recipientGroups: "Ontvanger groepen",
        send: "Verzenden",
        scheduledFor: "Gepland voor",
        language: "Taal",
        languageDescription:
          "De berichttaal wordt gebruikt om de tekstopmaak in de client-app te bepalen. De taal heeft geen invloed op de geselecteerde ontvangers.",
        readonlyWarning:
          "Berichten die al zijn verzonden, kunnen niet meer worden gewijzigd.",
      },
      dialogs: {
        deleteMessage: {
          header: "Bericht verwijderen",
          content:
            "Weet je zeker dat je het bericht {0} wilt verwijderen? Na verwijdering zal het bericht niet zichtbaar zijn in de inbox van de app.",
        },
        deleteMessages: {
          header: "Geselecteerde berichten verwijderen",
          content:
            "Weet je zeker dat je de geselecteerde berichten wilt verwijderen? Na verwijdering zullen de berichten niet zichtbaar zijn in de inbox van de app.",
        },
      },
      notifications: {
        messageDeleted: "Het bericht is verwijderd",
        messagesDeleted: "Geselecteerde berichten zijn verwijderd",
      },
    },
  },

  components: {
    header: {
      change: "wijzigen",
      tryBetaDashboard: "Probeer ons nieuwe beta-dashboard",
    },
    trainingStatus: {
      notReady: "Nog niet klaar om uit te nodigen",
      ready: "Klaar om uit te nodigen",
      invited: "Uitgenodigd",
      completed: "Voltooid",
      incomplete: "Onvolledig",
    },
    select: {
      report: {
        reportType: "Type rapport",
        adoption: {
          name: "Adoptie",
          description:
            "Het adoptierapport laat je precies zien hoe je populatie ervoor staat om zich te registreren en te spelen. Dit helpt je om je te richten op de verschillende groepen gebruikers en geeft inzicht in hoe je adoptieproces werkt. De groepen in dit rapport worden gecombineerd tot één lijst van gebruikers.",
        },
        group_ranking: {
          name: "Groepsrangschikking",
          description:
            "Het groepsrapport helpt je bij het identificeren van de mensen die de afgelopen maand het meest hebben geleerd voor elke groep. Als er beloningen zijn, kun je zo zien wie het beste zijn best heeft gedaan.",
        },
        question_analytics: {
          name: "Vraaganalyse",
          description:
            "Het rapport Question Analytics toont alle statistieken voor elke vraag. Dit helpt je te bepalen welke vragen goed werken en van welke vragen je moeilijker kunt leren.",
        },
        impact: {
          name: "Impact",
          description:
            "Het Knowingo Impact Rapport laat zien wat voor impact Knowingo heeft gehad op uw gebruikers. U kunt de toename in kennis van uw populatie zien. Deze toename wordt getoond door middel van Initial Knowledge, Current Knowledge en Potential Knowledge.",
        },
        certificates: {
          name: "Certificaten",
          description:
            "Het certificeringsrapport toont de status van gebruikers met betrekking tot hun certificaten. Je kunt bepalen wat de huidige status van je populatie is met hun voortgang.",
        },
        user_dump: {
          name: "Gebruikers dump",
          description:
            "Het rapport bevat verschillende gegevens over gebruikerscertificering, kennis en groepen.",
        },
        group_certificates: {
          name: "Groepscertificaten",
          description:
            "Het certificeringsrapport toont de status van gebruikers met betrekking tot hun certificaten. U kunt bepalen wat de huidige status is van uw populatie wat betreft hun voortgang.",
        },
        certificates_postnl: {
          name: "Certificaten (PostNL)",
          description:
            "Het certificeringsrapport toont de status van gebruikers met betrekking tot hun certificaten. Je kunt bepalen wat de huidige staat is van je populatie met hun voortgang.",
        },
        compliance: {
          name: "Compliance",
          description:
            "Het compliancerapport toont gegevens die zijn verzameld van gebruikers die nalevingsonderwerpen toegewezen hebben gekregen. Dit helpt u om de nalevingsniveaus van uw populatie te beoordelen.",
        },
      },
      group: {
        selectGroup: "Selecteer groep",
        loading: "Bezig met laden",
        noResults: "Geen zoekresultaten voor {0}",
      },
      tags: {
        selectTags: "Selecteer tags",
        successMessage: "Tag {0} is aangemaakt",
        createOption: "Tag toevoegen",
      },
      company: {
        selectCompany: "Selecteer organisatie",
      },
      language: {
        selectLanguage: "Taal kiezen",
        en: "Engels",
        nl: "Nederlands",
        de: "Duits",
        fr: "Frans",
        ru: "Russisch",
        ar: "Arabisch",
        pt: "Portugees",
        es: "Spaans",
        cs: "Tsjechisch",
        he: "Hebreeuws",
        ur: "Urdu",
        th: "Thais",
        zh: "Chinese",
        hi: "Hindi",
        it: "Italiaans",
        pl: "Pools",
      },
      topic: {
        selectTopic: "Selecteer topic",
        selectTopics: "Selecteer topics",
      },
      content: {
        placeholder: "Selecteer inhoud",
        course: "Cursus",
        topic: "Topic",
        publishedQuestions: "gepubliceerde vragen",
      },
      compliance: {
        selectCompliance: "Selecteer compliance",
      },
      user: {
        notConfirmed: "Nog niet bevestigd",
        selectUser: "Selecteer gebruiker",
      },
      webhook: {
        placeholder: "Selecteer evenement",
      },
      timezone: {
        timezone: "Tijdzone",
      },
      leaders: {
        groupLeaders: "Groepsmanager",
        selectGroupManager: "Selecteer groepsmanager",
      },
      role: {
        label: "Rol",
        placeholder: "Selecteer rol",
        userRoles: "Gebruikersrollen",
        whatRoles: "Wat zijn de rollen?",
        user: {
          header: "Gebruiker",
          description:
            "Volledige toegang tot de mobiele en webapplicatie (Knowingo App) en geen toegang tot het Dashboard.",
        },
        moderator: {
          header: "Beheerder",
          description:
            "Volledige toegang tot de Knowingo App en Dashboard. Moderator heeft de gecombineerde permissies en toegangsrechten van de rollen Inhoudmaker en Gebruikersbeheerder, met de mogelijkheid om toegang te krijgen tot alle pagina's van het Dashboard en om alle informatie of inhoud op het Dashboard te bewerken. Moderators kunnen andere moderators bewerken, aanmaken of verwijderen van het platform.",
        },
        user_manager: {
          header: "Manager",
          description:
            "Volledige toegang tot de Knowingo App en gedeeltelijke toegang tot het Dashboard. Managers kunnen leerstatistieken bekijken en Gebruikers, Groepen en hun Leerplannen beheren, maar kunnen geen inhoud aanmaken of bewerken.",
        },
        group_manager: {
          header: "Groeps beheerder",
          description:
            "Volledige toegang tot de Knowingo App en gedeeltelijke toegang tot het Dashboard. Groepsbeheerders kunnen leerstatistieken bekijken en toegewezen Groepen, Leerplannen en hun Gebruikers beheren. Groepsbeheerders kunnen ook rapporten genereren voor hun groepen en hebben alleen-lezen toegang tot alle inhoud. Beheerde groepen kunnen worden toegewezen op de groepenpagina nadat de gebruiker is uitgenodigd.",
        },
        content_creator: {
          header: "Content beheerder",
          description:
            "Volledige toegang tot de Knowingo App en gedeeltelijke toegang tot het Dashboard. Inhoudmakers kunnen inhoud van Onderwerpen aanmaken en bewerken en hebben toegang tot analyse op vraagniveau, maar kunnen geen wijzigingen aanbrengen in Gebruikers en Leerplannen.",
        },
      },
    },
    clipboard: {
      successMessage: "Waarde is naar het klembord gekopiëerd",
      errorMessage: "Kon niet kopiëren. Kopieer geselecteerde tekst handmatig",
    },
    spanDropdown: {
      day: "Dagen",
      week: "Weken",
      month: "Maanden",
      quarter: "Kwartalen",
      year: "Jaren",
    },
    comments: {
      deletedMessage: "Opmerking is verwijderd",
      savedMessage: "Opmerking is toegevoegd",
      delete: "Verwijderen",
      yourComment: "Jouw reactie",
      addComment: "Toevoegen reactie",
    },
    sidebar: {
      avatar: {
        alt: "Avatar",
        roles: {
          admin: "Admin",
          moderator: "Beheerder",
        },
      },
      items: {
        dashboard: "Dashboard",
        users: "Gebruikers",
        groups: "Groepen",
        topics: "Topics",
        courses: "Cursussen",
        certification: "Certificering",
        accountInfo: "Accountinformatie",
        api: "API",
        companySettings: "Instellingen",
        whiteLabeling: "White labelling",
        webHooks: "Webhooks",
        documentation: "Documentatie",
        moderatorCompanies: "Organisaties",
        contentSharing: "Kennistopics delen",
        questionAnalytics: "Vraaganalyse",
        feedback: "Feedback",
        shareOverview: "Gedeelde topics",
        myProfile: "Mijn profiel",
        signupURLs: "Sign-up links",
        logout: "Uitloggen",
        newTag: "Nieuw!",
        proTag: "PRO",
        shareLink: "Gedeelde link",
        library: "Bibliotheek",
        disabledItemNotice:
          "Beheerder ziet dit niet omdat de feature uit staat",
        contentLibrary: "Kennisbibliotheek",
        reporting: "Rapportage",
        activityLog: "Activiteitenlogboek",
        enterpriseStats: "Bedrijfsstatistieken",
        externalTraining: "Externe opleiding",
        marketplace: "Marktplaats",
        messages: "Berichten",
      },
    },
    table: {
      controls: {
        filterDropdown: {
          filterBy: "Filter door",
          clearFilters: "Filters verwijderen",
          filters: {
            sent: "Verzonden",
            scheduled: "Gepland",
          },
        },
        actionsDropdown: {
          label: "Acties",
          delete: "Geselecteerden verwijderen",
        },
        searchBox: {
          search: "Zoeken",
        },
      },
      actions: {
        delete: "Verwijderen",
      },
      noSearchResults: "Geen zoekresultaten voor: {0}",
      noData: "Geen data",
      moreItems:
        "{0} meer items. Klik hier om alle items in een nieuw tabblad te openen.",
    },
    charts: {
      week: "Week",
      quarter: "Kwart",
    },
    submissionConfirmation: {
      successMessage: "Opgeslagen",
    },
    statusIcon: {
      published: "Gepubliceerd",
      unpublished: "Niet-gepubliceerd",
      changed: "Veranderd",
      incomplete: "Incomplete schets",
    },
    cropper: {
      errors: {
        unsupportedFileType: "Niet ondersteund bestandstype",
      },
      buttons: {
        upload: "Uploaden",
        delete: "Verwijder",
        confirm: "Bevestig",
      },
    },
    form: {
      buttons: {
        save: "Opslaan",
        loading: "Bezig met laden",
        actionAndClose: "{0} en sluiten",
      },
      fields: {
        repeatSelect: {
          every: "Elke",
          days: "Dagen",
          weeks: "Weken",
          months: "Maanden",
          daily: "Dagelijks",
          weekly: "Wekelijks",
          monthly: "Maandelijks",
          custom: "Aangepast",
        },
        weekdaySelect: {
          mon: "Ma",
          tue: "Di",
          wed: "Wo",
          thu: "Do",
          fri: "Vr",
          sat: "Za",
          sun: "Zo",
        },
      },
      validators: {
        required: "Veld is verplicht",
        invalidUrl: "Ongeldige URL",
      },
    },
    richTextEditor: {
      errors: {
        textTooLong: "Ingevoerde tekst is te lang",
      },
      toolbox: {
        bold: "Vet",
        italic: "Italic",
        underline: "Onderstrepen",
        strikethrough: "Doorhalen",
        superscript: "Superscript",
        subscript: "Subscript",
        link: "Link",
        fontSize: "Lettergrootte",
        fontColor: "Kleur van het lettertype",
        highlightColor: "Markeer kleur",
        clearFormatting: "Heldere opmaak",
      },
    },
    downloader: {
      title: "Download",
      generatingFile:
        "Het gevraagde bestand wordt gegenereerd. Even geduld aub...",
      fileReady:
        "Uw bestand is klaar! Klik op een knop hieronder om het op uw computer op te slaan.",
      loading: "Bezig met laden",
      download: "Download",
      close: "Sluiten",
    },
    confirm: {
      confirm: "Bevestigen",
      cancel: "Annuleren",
    },
    certification: {
      unknown: "Onbekend",
      certified: "Gecertificeerd",
      on_track: "Op schema",
      expiring: "Aflopend",
      expired: "Verlopen",
      overdue: "Termijn gemist",
      uncertified: "Niet-gecertificeerd",
    },
    mediaComponent: {
      type: "Type",
      text: "Tekst",
      image: "Afbeelding",
      video: "Video",
      videoUrl: "Video URL",
      thumbnail: "Thumbnail",
      videoPreview: "Videopreview",
      videoWarning: "Video kan niet worden geladen",
    },
    topicInfo: {
      assignedGroups: "Toegewezen groepen",
      assignedCourses: "Toegewezen cursussen",
      noAssignedGroups: "Geen toegewezen groepen",
      noAssignedCourses: "Geen toegewezen cursussen",
      topicOwner: "Eigenaar van het topic",
    },
    datePicker: {
      hour: "Uur",
      minutes: "Minuten",
      date: "Datum",
    },
    input: {
      originalText: "Oorspronkelijke tekst",
      errorWhileTranslating:
        "Er is een fout opgetreden bij het vertalen van de inhoud. Probeer het later opnieuw.",
    },
  },

  inputs: {
    startDate: {
      label: "Startdatum",
    },
    endDate: {
      label: "Einddatum",
    },
    frequency: {
      label: "Frequentie",
    },
    weekday: {
      label: "Weekdag",
    },
    includeDisabledUsers: {
      label: "Gedeactiveerde gebruikers opnemen",
    },
    includeMetaFields: {
      label: "Metavelden opnemen",
    },
    ignoreMinAnswers: {
      label: "Vragen zonder metingen negeren",
      warning:
        "Sommige metingen met betrekking tot Vraaganalyse kunnen onbetrouwbaar zijn vanwege een lage blootstelling aan spelers.",
    },
    name: {
      label: "Naam",
    },
    repeatSelect: {
      daily: "Dagelijks",
      weekly: "Wekelijks",
      monthly: "Maandelijks",
    },
    enabled: {
      label: "Ingeschakeld",
      description:
        "Je kunt dit veld uitvinken om het verzenden van e-mails naar geselecteerde ontvangers op te schorten.",
    },
    monthSelect: {
      label: "Selecteer maand",
    },
    filters: {
      name: "Naam",
      created: "Aangemaakt",
      updated: "Bijgewerkt",
      published: "Gepubliceerd",
      num_questions: "# Vragen",
      num_topics: "# Onderwerpen",
      num_courses: "# Cursussen",
      num_tips: "# Tips",
      num_theories: "# Inleidingen",
    },
    noFiltersAvailable: "Geen filters beschikbaar",
    url: {
      placeholder: "Voer URL in",
    },
  },

  errors: {
    resourceUpdated: "Vernieuw de pagina en probeer het opnieuw.",
    topicIsShared:
      "Kan gedeeld onderwerp niet verwijderen. Trek alle bestaande shares in en probeer het opnieuw.",
    tryAgain: "Probeer het opnieuw",
  },
  errorBoundary: {
    updateAvailableHeader: "Update beschikbaar",
    updateAvailableContent:
      "Er is een nieuwe versie van het Knowingo dashboard beschikbaar. Klik op een knop hieronder om de pagina te herladen.",
    reloadButton: "Opnieuw laden",
    somethingWentWrongHeader: "Oeps... Er ging iets mis.",
    somethingWentWrongContent:
      "Het lijkt erop dat we wat interne problemen hebben. Ons team is op de hoogte gebracht.",
    pleaseReload:
      "Herlaad de pagina en probeer het opnieuw. Als het probleem aanhoudt, neem dan contact op met support op {0}",
    eventIdentifier: "Identificator",
  },

  form: {
    errors: {
      start_in_past: "De begindatum kan niet in het verleden worden ingesteld",
      end_before_start: "De einddatum kan niet vóór de begindatum liggen",
      "Missing data for required field.":
        "Ontbrekende gegevens voor verplicht veld",
      "Length must be between 1 and 35.":
        "De lengte moet tussen 1 en 35 liggen",
      "Length must be between 3 and 120.":
        "De lengte moet tussen 3 en 120 liggen",
      unknownError: "Onbekende fout",
      "Not a valid URL.": "Ongeldige URL",
      "Invalid domain": "Ongeldig e-maildomein.",
    },
  },

  // OLD
  loginPage: {
    htmlTitle: "Knowingo | Inloggen",
    header: "Log in bij Knowingo{0}",
    emailAddress: "E-mailadres",
    password: "Wachtwoord",
    rememberMe: "Onthouden",
    loginBtn: "Log in",
    loginWithSSOBtn: "Inloggen met behulp van SSO",
    forgotPassword: "Wachtwoord vergeten?",
    goBack: "Ga terug",
    here: "hier",
    inputs: {
      domain: {
        placeholder: "Domein",
      },
    },
    errors: {
      invalidCredentials: "Emailadres of wachtwoord is niet correct.",
      disabledAccount:
        "Je Knowingo+ is gedeactiveerd. Neem contact op met support@knowingo.com.",
      deletedAccount:
        "Je Knowingo+ is verwijderd. Neem contact op met support@knowingo.com.",
      tooManyAttempts:
        "Helaas, je hebt te vaak onjuist ingelogd. Hierdoor is je account tijdelijk geblokkeerd. Wacht 5 minuten en probeer het daarna nog een keer.",
      permissionError:
        "Inloggen is alleen toegestaan voor moderators. Download de Knowingo app op je telefoon of tablet. Neem contact op met support@knowingo.com voor meer informatie.",
      cookiesError:
        "Het lijkt erop dat uw browser cookies blokkeert. Om van ons dashboard gebruik te kunnen maken moet u cookies toestaan of een uitzondering voor knowingo.com toevoegen in uw browser-instellingen.",
      invalidEmail: "Ongeldig e-mailadres",
      invalidPassword: "Ongeldig wachtwoord",
      notConfirmed:
        "Uw account is niet bevestigd. Controleer uw mailbox en klik op de activeringslink.",
      ssoAccount:
        "Uw account is verbonden met een andere loginprovider. Gebruik SSO als login methode.",
      companyAccessDenied: "Je hebt geen toegang tot deze organisatie.",
      emailNotConfirmed:
        "Controleer uw mailbox en bevestig uw emailadres voordat u inlogt. Klik hier om de e-mail met bevestigingslink opnieuw te versturen.",
      noSeats: "Organisatie heeft geen seats beschikbaar",
      alreadyLoggedIn:
        "Je bent al ingelogd. Klik hier om het dashboard te openen.",
      unknownError: "Onbekende fout",
    },
    confirmationRequestSent: "E-mail met bevestigingslink is verzonden",
    successLogin: "Succesvol ingelogd",
  },
  notFound: {
    header: "Pagina niet gevonden",
    textCompany:
      "Het lijkt erop dat het ingevoerde organisatie niet bestaat in Knowingo. Controleer de ingevoerde URL.",
  },
  ssoLoginPage: {
    header: "Selecteer SSO provider",
    errors: {
      ssoNotSupported:
        "Single Sign-On wordt niet ondersteund door jouw organisatie.",
    },
  },
  domainPage: {
    errors: {
      companyNotFound: "Organisatie niet gevonden.",
    },
  },
  forgotPassword: {
    htmlTitle: "Knowingo | Wachtwoord vergeten",
    formHeader: "Wachtwoord herstellen",
    formEmail: "E-mailadres",
    formSubmit: "Wachtwoord herstellen",
    backButton: "Terug naar inloggenpagina",
    success: "Controleer uw mailbox",
    errors: {
      invalidEmail: "Ongeldig e-mailadres",
      alreadyLoggedIn: "Je bent al ingelogd",
      userNotFound: "De gebruiker is niet bekend",
      unknownError: "Onbekende fout",
    },
    inputs: {
      email: {
        placeholder: "E-mailadres",
      },
    },
  },
  dashboard: {
    htmlTitle: "Knowingo | Dashboard",
    toggleNavigation: "Navigatie schakelen",
    welcome: "Hoi {0}",
  },
  pageNotFound: "Pagina niet gevonden",
  overview: {
    title: "Dashboard",
  },
  activityLog: {
    columns: {
      activity: "Activiteit",
      created: "Aangemaakt",
      user: "Gebruiker",
    },
  },
};

module.exports = { nl };
