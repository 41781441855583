import React, { useState } from "react";
import { connect } from "react-redux";

import { DropdownList } from "./components/widgets";

import strings from "./l10n";
import { Modal } from "react-bootstrap";
import Button from "./components/button/button";

export const ROLES = {
  admin: "admin",
  moderator: "moderator",
  content_creator: "content_creator",
  user_manager: "user_manager",
  group_leader: "group_leader",
  user: "user",
};

export const getUserRoles = ({ roles }) => {
  const isAdmin = roles.includes(ROLES.admin);
  const isContentCreator = roles.includes(ROLES.content_creator);
  const isUserManager = roles.includes(ROLES.user_manager);
  const isModerator = roles.includes(ROLES.moderator);
  const isGroupLeader = roles.includes(ROLES.group_leader);

  const allRoles = Object.keys(ROLES);

  if (isAdmin) {
    return allRoles;
  }
  if (isModerator) {
    return allRoles.slice(1);
  }
  if (isContentCreator) {
    return [ROLES.content_creator, ROLES.user];
  }
  if (isUserManager) {
    return [ROLES.user_manager, ROLES.user];
  }
  if (isGroupLeader) {
    return [ROLES.group_leader, ROLES.user];
  }
  return [ROLES.user];
};

export const getRoleName = (role) => {
  switch (role) {
    case ROLES.user:
      return strings.pages.users.components.roles.user;
    case ROLES.moderator:
      return strings.pages.users.components.roles.moderator;
    case ROLES.admin:
      return strings.pages.users.components.roles.admin;
    case ROLES.user_manager:
      return strings.pages.users.components.roles.user_manager;
    case ROLES.content_creator:
      return strings.pages.users.components.roles.content_creator;
    case ROLES.group_leader:
      return strings.pages.users.components.roles.group_leader;
    default:
      return null;
  }
};

const hasRole = (user, role) => user.roles.indexOf(role) > -1;

const isAdmin = (user) => hasRole(user, ROLES.admin);

const isModerator = (user) => hasRole(user, ROLES.moderator);

const isUserManager = (user) => hasRole(user, ROLES.user_manager);

const isContentManager = (user) => hasRole(user, ROLES.content_creator);

const isGroupLeader = (user) => hasRole(user, ROLES.group_leader);

export const getRole = (user) => {
  if (isAdmin(user)) return ROLES.admin;
  if (isModerator(user)) return ROLES.moderator;
  if (isUserManager(user)) return ROLES.user_manager;
  if (isContentManager(user)) return ROLES.content_creator;
  if (isGroupLeader(user)) return ROLES.group_leader;

  if (hasRole(user, ROLES.user)) return ROLES.user;
  return null;
};

export const canSetRoles = (user) => {
  if (isAdmin(user))
    return [
      ROLES.user,
      ROLES.moderator,
      ROLES.user_manager,
      ROLES.content_creator,
      ROLES.group_leader,
    ];
  if (!hasRole(user, ROLES.moderator)) return [];
  if (isModerator(user))
    return [
      ROLES.user,
      ROLES.moderator,
      ROLES.user_manager,
      ROLES.content_creator,
      ROLES.group_leader,
    ];
  if (isUserManager(user))
    return [ROLES.user, ROLES.user_manager, ROLES.content_creator];
  return [];
};

let UserRoles = ({ input: { onChange, value }, canChange, allRoles }) => {
  const trans = strings.components.select.role;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <label>{trans.label}</label>

      <DropdownList
        disabled={!canChange}
        onChange={onChange}
        data={allRoles}
        placeholder={trans.placeholder}
        textField={(item) => getRoleName(item)}
        value={value}
      />

      <Button accent={"link"} size="xs" onClick={() => setShowModal(true)}>{trans.whatRoles}</Button>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        animation={false}
        className="modal-custom modal-accent-light"
      >
        <Modal.Header>
            <div className={"d-flex w-100 "}>
              <div className={"flex-grow-1 d-flex align-items-center"}>
                <h5 className={"m-0"}>{trans.userRoles}</h5>
              </div>
              <div>
                <Button accent={"transparent"} size={"sm"} icon={"times"} onClick={() => setShowModal(false)}/>
              </div>
            </div>
        </Modal.Header>
        <Modal.Body>
          <h6>{trans.user.header}</h6>
          <p>{trans.user.description}</p>

          <h6>{trans.moderator.header}</h6>
          <p>{trans.moderator.description}</p>

          <h6>{trans.content_creator.header}</h6>
          <p>{trans.content_creator.description}</p>

          <h6>{trans.user_manager.header}</h6>
          <p>{trans.user_manager.description}</p>

          <h6>{trans.group_manager.header}</h6>
          <p>{trans.group_manager.description}</p>
        </Modal.Body>

      </Modal>
    </>
  );
};

UserRoles = connect((state) => {
  const { user: currentUser } = state.auth;
  const { currentUser: targetUser } = state.users;

  const allRoles = canSetRoles(currentUser);
  const targetUserRole = getRole(targetUser);

  return {
    canChange: !targetUserRole || allRoles.indexOf(targetUserRole) > -1,
    allRoles: allRoles,
    userRole: targetUserRole,
  };
})(UserRoles);

export default UserRoles;
