const ur = {
  btnLoading: "لوڈ ہو رہا ہے۔۔۔",
  filterNotification: "آپ نے {0} تلاش کیا",
  general: {
    or: "یا",
    next: "اگلا",
    wait: "برائے مہربانی انتظار کریں۔۔۔",
    warning: "وارننگ!",
    demo: "ڈیمو",
  },

  messages: {
    unknownError:
      "ایک خرابی واقع ہو گئی۔ برائے مہربانی دوبارہ کوشش کریں اور سپورٹ سے رابطہ کریں۔",
    formError:
      "ایک خرابی واقع ہو گئی۔ برائے مہربانی فارم چیک کریں اور دوبارہ کوشش کریں",
    saved: "محفوظ کیا گیا۔",
    success: "کامیابی!",
    textCopied: "متن کلپ بورڈ پر کاپی ہو گیا",
    textCopyError:
      "متن کا کاپی کرتے وقت ایک خرابی واقع ہوئی ہے۔ براہ کرم متن کو منتخب کریں اور اسے دستی طریقے سے کاپی کریں۔",
    csrfError:
      "غیر موزوں یا غائب CSRF ٹوکن۔ براہ کرم اپنی درخواست دوبارہ جمع کرنے کی کوشش کریں۔ اگر مسئلہ برقرار رہے تو براہ کرم سپورٹ سے رابطہ کریں۔",
  },

  labels: {
    beta: "بیٹا",
  },

  fields: {
    email: {
      placeholder: "ای میل اڈریس",
    },
    password: {
      placeholder: "پاس ورڈ",
      description: "کم از کم 8 کیریکٹر، جن میں 1 بڑا حرف اور 1 عدد ہو",
    },
    repeatPassword: {
      placeholder: "پاس ورڈ دہرائیں",
    },
    domain: {
      errors: {
        invalid: "غائب یا غیر موزوں ڈومین نام",
        duplicated: "ڈپلیکیٹ ڈومین نام",
      },
    },
    errors: {
      incorrectValue: "غلط قدر",
      fieldRequired: "فیلڈ درکار ہے",
      invalidDate: "غلط تاریخ",
      overlappingDate: "اوور لیپنگ تاریخ",
    },
  },

  buttons: {
    downloadExcel: "XLSX ڈاؤن لوڈ کریں۔",
    downloadCsv: "CSV ڈاؤن لوڈ کریں۔",
    generate: "پیدا کریں۔",
    save: "محفوظ کریں۔",
    submit: "جمع کریں",
    dismiss: "رد کریں",
    confirm: "تصدیق کریں",
    loading: "لوڈ ہو رہا ہے۔",
    clear: "صاف کریں",
    addMore: "مزید شامل کریں۔",
    copy: "کاپی",
    create: "تخلیق کریں",
    undo: "واپس کریں",
    close: "بند کریں",
    remove: "ہٹانا",
    continue: "جاری رکھیں",
  },

  pages: {
    activityLog: {
      activityLog: "سرگرمی کا اندراج",
      table: {
        columns: {
          activity: "سرگرمی",
          user: "صارف",
          created: "تخلیق کیا گیا",
        },
      },
    },
    auth: {
      clickToLogout: "لاگ آؤٹ کرنے کے لئے یہاں کلک کریں",
      sso: {
        signIn: "نوونگو {0} میں سائن ان کریں",
        ssoUnavailable:
          "واحد سائن-آن غیر دستیاب۔ مزید معلومات کے لئے اپنے منتظم سے رابطہ کریں۔",
        redirect: {
          redirectionInProgress:
            "آپ کو {0} سیکنڈ میں واپس آپ کی سائٹ پر لے جایا جائے گا۔",
          redirectManually:
            "اگر آپ کو خود کار طور پر واپس آپ کی سائٹ پر نہیں لے جایا جاتا ہے تو برائے مہربانی یہاں کلک کریں۔",
          pleaseWait: "برائے مہربانی انتظار کریں",
        },
      },
      mfa: {
        authentication: {
          tokenInputLabel: "تصدیقی ٹوکن",
          tokenInputPlaceholder:
            "آپ کو تصدیقی ایپ میں جو 6 عددی کوڈ نظر آ رہا ہے اسے داخل کریں",
          verify: "تصدیق کریں",
        },
        setup: {
          multiFactorAuthentication: "کثیر عامل والا تصدیقی سیٹ اپ",
          step1: "مرحلہ 1: ایک تصدیقی ایپ ڈاؤن لوڈ کریں",
          step1Description:
            "کسی اسٹور (گوگل آتھینٹکیٹر، مائیکروسافٹ آتھینٹکیٹر، آتھی) سے کوئی تصدیقی ایپ ڈاؤن لوڈ اور انسٹال کریں۔ اگر آپ نے پہلے ہی انسٹال کر رکھا ہے تو آپ اس مرحلہ کو پھلانگ سکتے ہیں۔",
          step2: "مرحلہ 2: کیو-آر کوڈ اسکین کریں",
          step2Description:
            "تصدیقی ایپ پر نیچے دیا گیا کیو-آر کوڈ اسکین کر کے نوونگو کو فہرست میں شامل کریں۔",
          step3: "مرحلہ 3: ٹوکن داخل کریں",
          step3Description:
            "تصدیقی ایپ کھولیں اور بنایا گیا ٹوکن نیچے خانہ میں داخل کریں:",
          typeManually:
            'اگر آپ کیو آر-کوڈ اسکین نہیں کر پا رہے ہیں تو، تصدیقی ایپ میں "ایک سیٹ اپ کلید داخل کریں" پر ٹیپ کریں اور درج ذیل کنفیگریشن استعمال کریں:',
          continue: "جاری رکھیں",
          secret: "خفیہ",
          keyType: "کلید کی قسم",
          timeBased: "وقت پر مبنی",
          accountName: "اکاؤنٹ کا نام",
          showManualConfiguration:
            "اگر آپ کیو آر-کوڈ اسکین کرنے سے قاصر ہیں تو یہاں کلک کریں",
        },
      },
      forgotPassword: {
        resetPassword: "پاس ورڈ دوبارہ ترتیب دیں",
        form: {
          successMessage:
            "اپنا ای میل چیک کریں۔ آپ کو چند منٹوں میں ایک پاس ورڈ دوبارہ ترتیب دینے والی لنک ملے گی۔ کوئی ای میل موصول نہیں ہوا؟ برائے مہربانی چیک کریں کہ آیا جمع کردہ ای میل پتہ درست ہے۔",
          emailAddress: "ای میل پتہ",
        },
        goBack: "لاگ ان صفحہ پر واپس جائیں",
      },
      login: {
        form: {
          emailAddress: "ای میل پتہ",
          password: "پاس ورڈ",
          rememberMe: "مجھے یاد رکھیں",
          forgotPassword: "میں اپنا پاس ورڈ بھول گیا",
          login: "لاگ ان کریں",
          ssoLogin: "ایس ایس او کے ساتھ لاگ ان کریں",
        },
        signIn: "نوونگو{0} میں سائن ان کر یں",
      },
    },
    confirmation: {
      forms: {
        passwordReset: {
          header: "پاس ورڈ کی دوبارہ ترتیب",
          enterPassword: "نیا پاس ورڈ داخل کریں",
          repeatPassword: "نیا پاس ورڈ دہرائیں",
          errors: {
            notSamePasswords: "پاس ورڈ ایک جیسے نہیں ہیں",
          },
          submit: "نیا پاس ورڈ محفوظ کریں",
          passwordChanged: "آپ کا پاس ورڈ بدل دیا گیا ہے",
          goBack: "لاگ ان صفحہ پر واپس جائیں",
        },
        emailChange: {
          header: "ای میل پتہ میں تبدیلی",
          emailChangedTo: "آپ کا ای میل پتہ بدل کر {0} ہو گیا ہے",
          contactSupport:
            "اگر آپ کو اپنے اکاؤنٹ میں لاگ ان کرنے میں مسئلہ ہو تو نوونگو سپورٹ سے {0} پر رابطہ کریں",
          goBack: "لاگ ان صفحہ پر واپس جائیں",
        },
        emailConfirmation: {
          emailConfirmed: "ای میل ایڈریس کی تصدیق ہوگئی ہے۔",
        },
        passwordWasReset: "آپ کا پاس ورڈ کامیابی سے دوبارہ ترتیب دیا گیا!",
        loginToDashboard: "آپ {0} میں لاگ ان کرکے جاری رکھ سکتے ہیں۔",
        loginToApp:
          "اگر آپ Knowingo ایپلیکیشن استعمال کر رہے تھے تو آپ موبائل ایپلیکیشن پر واپس جا کر یا {0} استعمال کر کے کھیلنا جاری رکھ سکتے ہیں۔",
        knowingoDashboard: "Knowingo Dashboard",
        knowingoWebApplication: "Knowingo Web Application",
      },
      errors: {
        invalidConfirmation: {
          header: "غیردرست توثیقی لنک",
          paragraph:
            "آپ کے ذریعہ داخل کردہ یو آر ایل غیردرست ہے یا اس کی میعاد ختم ہو گئی ہے۔",
          contactAdministrator:
            "مزید تفصیلات کے لئے برائے مہربانی اپنے منتظم سے رابطہ کریں۔",
        },
        unknownError: {
          header: "ایک خرابی واقع ہو گئی",
          contactSupport:
            "ایک خرابی واقع ہو گئی اور ہم آپ کی درخواست پر عمل کاری نہیں کر سکے۔ برائے مہربانی سپورٹ سے {0} پر رابطہ کریں",
        },
      },
    },
    trainingConfirmation: {
      thankYouForFeedback: "آپ کے تبصرے کا شکریہ",
      giveFeedback: "براہ کرم ہمیں بتائیں کہ کیا آپ نے نیچے شرکت کی:",
      yes: "جی ہاں",
      no: "نہیں",
    },
    signup: {
      errors: {
        outOfSeats: {
          header: "سیٹیں نہیں ہیں",
          paragraph:
            "آپ اکاؤنٹ تخلیق نہیں کر سکتے ہیں، کیونکہ یہ نوونگو پر اس تنظیم سے جڑنے کے سلسلے میں اجازت یافتہ صارفین کی زیادہ سے زیادہ تعداد سے تجاوز ہو جائے گا۔",
          contactAdministrator:
            "مزید تفصیلات کے لئے برائے مہربانی اپنے منتظم سے رابطہ کریں۔",
        },
        invalidCode: {
          header: "ان ویلڈ سائن اپ لنک",
          paragraph:
            "آپ کے ذریعہ داخل کردہ یو آر ایل ویلڈ نہیں ہے یا اس کی میعاد ختم ہو گئی ہے",
          contactAdministrator:
            "مزید تفصیلات کے لئے برائے مہربانی اپنے منتظم سے رابطہ کریں۔",
        },
        unknownError: {
          header: "ایک خرابی واقع ہو گئی",
          contactAdministrator:
            "مزید تفصیلات کے لئے برائے مہربانی اپنے منتظم سے رابطہ کریں۔",
        },
        logoutRequired: {
          header: "لاگ آؤٹ ضروری ہے",
          paragraph:
            "آپ ان وسائل تک رسائی کی کوشش کر رہے ہیں جو صرف نئے صارفین کے لئے دستیاب ہیں۔ لاگ آؤٹ کریں اور دوبارہ کوشش کریں۔",
          logout: "لاگ آؤٹ کریں",
        },
        signupNotAllowed: {
          header: "سائن اپ کی اجازت نہیں ہے",
          paragraph:
            "اس کمپنی نے سائن اپ بند کر دیا ہے۔ اگر آپ کوونگو میں اس کمپنی سے جڑنا چاہتے ہیں تو منتظم سے رابطہ کریں۔",
        },
      },
      forms: {
        fillInFields: "برائے مہربانی نیچے کے خانے پر کریں",
        personalInformation: "ذاتی معلومات",
        accountInformation: "اکاؤنٹ کی معلومات",
        errors: {
          notSamePasswords: "پاس ورڈ ایک جیسے نہیں ہیں",
        },
        firstName: "نام کا پہلا حصہ",
        lastName: "نام کا آخری حصہ",
        workEmailAddress: "کاروباری ای میل ایڈریس",
        password: "پاس ورڈ",
        repeatPassword: "پاس ورڈ دہرائیں",
        getStarted: "شروع ہو جائیں",
        consentCheckbox:
          "یہ توثیق کرنے کے لئے کہ آپ نے {0} اور {1} کو پڑھ اور سمجھ لیا ہے یہاں کلک کریں",
        termsOfService: "سروس کی شرائط",
        privacyPolicy: "رازداری کی پالیسی",
        allowedDomains:
          "حفاظتی وجوہات کے لئے صرف منظور شدہ ڈومینوں کے ای میل ایڈریس قبول ہیں۔ مزید تفصیلات کے لئے اپنے اکاؤنٹ ایڈمنسٹریٹر یا نونگو سپورٹ سے رابطہ کریں۔",
      },
      accountActivated:
        "آپ کا نوونگو اکاؤنٹ فعال کر دیا گیا ہے اور یہ چلنے کو تیار ہے۔",
      startLearning:
        "آپ کو اب صرف یہ کرنا ہے کہ ایپ ڈاؤن لوڈ کر کے سیکھنا شروع کریں!",
      confirmationSent: "ہم نے آپ کو ابھی ایک توثیقی ای میل بھیجا ہے۔",
      clickOnLink: "اندر کی لنک پر کلک کریں اور سیکھنا شروع کریں!",
      usedEmail: "اندراج کے لئے استعمال ہونے والا ای میل پتہ:",
      step: "مرحلہ",
      createAccount: "اپنا نوونگو اکاؤنٹ بنائیں",
      downloadApp: "نوونگو ایپ ڈاؤن لوڈ کریں",
      appLinks:
        "اپنے ایپلی کیشن اسٹور پر جانے کے لئے ان میں سے کسی ایک لنک پر کلک کریں، یا اسٹور میں نوونگو تلاش کریں۔",
      readyToPlay: "کھیلنے کے لئے تیار",
      haveFunLearning: "مزے لے کر سیکھیں!",
      broughtBy: "آپ کے پاس لایا گیا بذریعہ",
    },
    dashboard: {
      betaNotification: "آپ نوونگو ڈیش بورڈ کا بیٹا ورژن استعمال کر رہے ہیں۔",
      modals: {
        selectCompany: {
          changeCompany: "کمپنی بدلیں",
          open: "کھولیں",
          cancel: "منسوخ کریں",
        },
      },
    },
    enterpriseStatistics: {
      loading: "لوڈ ہو رہا ہے",
      enterpriseStatistics: "انٹرپرائز کے اعدادوشمار",
      beta: "بیٹا",
      expirationBanner:
        "ہمارے ڈیش بورڈ وجیٹس اور رپورٹنگ میں بہتری کی وجہ سے، نوئنگو نے انٹرپرائز شماریات کو ختم کرنے کا فیصلہ کیا ہے۔ ہم نے انٹرپرائز شماریات صفحہ کی معلومات دیگر ذرائع میں منتقل کر دی ہیں۔ یہ صفحہ {0} تک آن لائن رہے گا۔",
      adoption: {
        adoption: "اختیار کاری",
        adoptionPerGroup: "اختیار کاری فی گروپ",
        joined: "شامل افراد",
        joinedLastWeek: "گزشتہ 7 دنوں میں شامل افراد",
        missing: "غائب",
        joinedStatus:
          "{0} صارفین جنہوں نے اندارج کرایا اور اپنی پہلی کوئز کھیلی ہے۔",
        joinedLastWeekStatus:
          "{0} صارفین جنہوں نے گزشتہ 7 دنوں میں اندراج کرایا اور کھیلا ہے۔ اس میں شامل افراد کا بھی شمار ہوتا ہے۔",
        missingStatus:
          "{0} صارفین ایسے افراد ہیں جنہیں دعوت تو دی گئی مگر انھوں نے اپنے اکاؤنٹ کا ابھی تک اندراج نہیں کرایا ہے۔",
        status: "حالت",
        loading: "لوڈ ہو رہا ہے",
        error: "خرابی",
        noGroupSelected: "کوئی گروپ منتخب نہیں کیا گیا",
        noData: "کوئی ڈیٹا نہیں",
        showMore: "مزید دکھائیں",
        email: "ای میل",
        firstName: "نام کا پہلا حصہ",
        lastName: "نام کا آخری حصہ",
        users: "صارفین",
      },
      certification: {
        certificates: "سرٹیفکیٹس",
        certification: "سند دہندگی",
        certificationPerGroup: "سند دہندگی فی گروپ",
        fullyCertified: "پورے طور پر سند یافتہ",
        onTrack: "پٹری پر",
        expired: "میعاد ختم ہو گئی",
        overdue: "چھوٹے ہوئے",
        missing: "غائب",
        fullyCertifiedStatus: "{0} صارفین نے ویلڈ سند حاصل کی ہے۔",
        onTrackStatus: "{0} صارفین اپنے تعلیمی سفر پر ہیں۔",
        expiredStatus:
          "{0} صارفین کی سندیں اپنی ویلڈٹی کے وقت سے آگے نکل گئی ہیں۔",
        overdueStatus:
          "{0} صارفین سے اپنے لازمی موضوعات کے لئے ڈیڈلائن چھوٹ گئی ہے۔",
        missingStatus:
          "{0} صارفین ایسے افراد ہیں جنہیں دعوت تو دی گئی ہے مگر انھوں نے اپنے اکاؤنٹ کا ابھی تک اندراج نہیں کرایا ہے۔",
        noGroupSelected: "کوئی گروپ منتخب نہیں کیا گیا",
        noData: "کوئی ڈیٹا نہیں",
        showMore: "مزید دکھائیں",
        email: "ای میل",
        firstName: "نام کا پہلا حصہ",
        lastName: "نام کا آخری حصہ",
        topic: "موضوع",
        status: "حالت",
        downloadAll: "تمام ڈاؤن لوڈ کریں۔",
      },
      experts: {
        initialKnowledgeScore: "ابتدائی معلومات کا اسکور",
        selectTopic: "موضوع منتخب کریں",
        topic: "موضوع",
        expert: "ماہر",
        noData: "کوئی ڈیٹا نہیں",
        yourExperts: "آپ کے ماہرین",
        loading: "لوڈ ہو رہا ہے",
        error: "خرابی",
        collapse: "سکیڑیں",
        expand: "پھیلائیں",
      },
      groupSelection: {
        description:
          "اس سے نیچے کے سبھی اعداد شمار اور ایکسل ڈاؤن لوڈ پر گروپ پر مبنی فلٹر شامل ہو جائے گا۔",
        groupFilter: "گروپ فلٹر",
      },
      misconception: {
        misconception: "غلط فہمی",
        selectTopic: "موضوع منتخب کریں",
        topic: "موضوع",
        question: "سوال",
        correctAnswer: "درست جواب",
        noData: "کوئی ڈیٹا نہیں",
        error: "خرابی",
        loading: "لوڈ ہو رہا ہے",
      },
      modal: {
        download: "ڈاؤن لوڈ کریں",
        close: "بند کریں",
      },
      widget: {
        downloadExcel: "ایکسل ڈاؤن لوڈ کریں",
      },
    },
    overview: {
      header: "ڈیش بورڈ",
      cards: {
        answers: "درست جوابات",
        users: "فعال صارفین",
        multiplier: "علم کو کئی گنا کرنے والا",
      },
      charts: {
        knowledge: {
          header: "علم",
          legend: {
            initialKnowledge: "ابتدائی علم",
            currentKnowledge: "موجودہ علم",
            potentialKnowledge: "ممکنہ علم",
          },
          inputs: {
            groups: "سبھی گروپس",
          },
        },
        trainingTime: {
          header: "تربیت کا وقت",
          legend: {
            minutes: "منٹ",
          },
          inputs: {
            groups: "سبھی گروپس",
            topics: "سبھی موضوعات",
            topicsInLearningPlan: "تعلیمی منصوبے کے سبھی موضوعات",
          },
        },
        questionsMastered: {
          header: "سوالات جن میں مہارت حاصل کی گئی",
          legend: {
            questionsMastered: "سوالات جن میں مہارت حاصل کی گئی",
          },
          inputs: {
            groups: "سبھی گروپس",
          },
        },
        learningProgress: {
          header: "صارف کی تعلیم میں پیش رفت",
          legend: {
            users: "صارفین",
          },
          inputs: {
            groups: "سبھی گروپس",
            topics: "سبھی موضوعات",
          },
        },
      },
      components: {
        dropdown: {
          default: "پہلے سے طے شدہ",
          companyDashboards: "کمپنی ڈیش بورڈز",
          createNew: "نیا ڈیش بورڈ بنائیں",
          copy: "ڈیش بورڈ کاپی کریں",
          edit: "ڈیش بورڈ میں ترمیم کریں",
          delete: "ڈیش بورڈ کو حذف کریں",
        },
        toolbar: {
          addWidget: "ویجیٹ شامل کریں",
          settings: "ترتیبات",
          save: "ڈیش بورڈ کو بچائیں",
          cancel: "ترمیم منسوخ کریں",
        },
        settings: {
          header: "ڈیش بورڈ کی ترتیبات",
          dashboardName: "ڈیش بورڈ کا نام",
          companyDefault: "کمپنی ڈیفالٹ",
        },
        widgets: {
          header: "ویجٹ منتخب کریں",
          add: "شامل کریں",
          remove: "دور",
          charts: "چارٹ",
          knowledge: {
            header: "علم",
            description:
              "فعال جاننے والے صارفین کا ابتدائی ، موجودہ اور ممکنہ علم دیکھیں۔",
            initialKnowledge:
              "ابتدائی علم (سرخ) سے پتہ چلتا ہے کہ صارفین کے پاس جاننے کے ساتھ سیکھنا شروع کرنے سے پہلے کتنا علم تھا",
            currentKnowledge:
              "موجودہ علم (گرین) سے پتہ چلتا ہے کہ صارفین نے فی الحال کتنا علم سیکھا ہے",
            potentialKnowledge:
              "ممکنہ علم (نیلے) علم کی مقدار ہے جو صارفین ان کے سیکھنے کے منصوبے میں کیا ہے اس کی بنیاد پر حاصل کرسکتے ہیں",
          },
          masteredQuestions: {
            header: "مہارت حاصل کرنے والے سوالات",
            description:
              "سوالات کی کل رقم جو جاننے والوں نے کامیابی کے ساتھ آپ کے صارفین کو سکھایا ہے!",
          },
          trainingTime: {
            header: "تربیتی وقت",
            description:
              "یہ گراف منتخب کردہ ٹائم فریم ، صارف گروپ اور سیکھنے کے منصوبے کے مطابق کھیلے گئے منٹ کی مقدار کو ظاہر کرتا ہے۔",
            minutes: "منٹ",
          },
          learningProgress: {
            header: "سیکھنے کی پیشرفت",
            description:
              "اس گراف سے پتہ چلتا ہے کہ آپ کے ملازمین نے اپنے سیکھنے کے منصوبے میں کتنی ترقی کی ہے۔نئے صارفین 0 at سے شروع ہوتے ہیں اور وہ صارفین جنہوں نے اپنے سیکھنے کا منصوبہ مکمل طور پر مکمل کیا ہے وہ 90-100 ٪ کالم میں دیکھا جاسکتا ہے۔",
          },
          leaderBoard: {
            header: "لیڈر بورڈ",
            description:
              "لیڈر بورڈ آپ کے پلیئر بیس کو کھیلے گئے منٹس، سب سے زیادہ سرٹیفکیٹس اور ان کی مہارت کی بنیاد پر درجہ بندی دکھاتا ہے۔ آپ ان کو گروپس اور عنوانات کی بنیاد پر فلٹر کر سکتے ہیں۔ یہ ویجیٹ ظاہر کرتا ہے کہ آپ کی تنظیم میں کون سے کھلاڑی بہترین سیکھنے والے ہیں۔",
          },
          timeToCertify: {
            header: "تصدیق کرنے کا وقت",
            description:
              "تصدیق کرنے کا وقت بتاتا ہے کہ صارفین کو ہر موضوع کے لیے اپنے سرٹیفکیٹ تک منٹوں میں پہنچنے میں جو اوسط وقت لگتا ہے۔",
            minutes: "منٹس",
          },
          knowledgeGain: {
            header: "علم حاصل کرنا",
            description:
              "نالج گین گراف دکھاتا ہے کہ وقت کے ساتھ ساتھ آپ کی آبادی میں علم کا فرق کیسے ختم ہوتا ہے۔ نالج گین اس علم کی مقدار کو ظاہر کرتا ہے جو آپ کے کھلاڑیوں نے کسی بھی وقت کے دوران حاصل کیا ہے۔",
            knowledgeGain: "علم حاصل کرنا",
            knowledgeDecrease: "علم میں کمی",
          },
          certificateStatus: {
            header: "سرٹیفکیٹ کی حیثیت",
            description:
              "یہ گراف ہر موضوع پر سرٹیفکیٹ کی حالتوں کی خرابی دکھاتا ہے۔ اس کا استعمال یہ دیکھنے کے لیے کہ آپ کے صارفین اپنے سرٹیفکیٹ تک پہنچنے کی طرف کیسے ترقی کر رہے ہیں۔",
            isCertified: "تصدیق شدہ",
            isExpired: "میعاد ختم ہونے والے سرٹیفکیٹ",
            isMissing: "غیر رجسٹرڈ صارفین",
            isMissed: "آخری تاریخ چھوٹ گئی۔",
            isOnTrack: "ڈیڈ لائن کے اندر",
            isUncertified: "سند یافتہ نہیں۔",
            detailedView: "سادہ/تفصیلی نظارہ",
          },
          questionAnalytics: {
            header: "سوالات کے تجزیات",
            description:
              "یہ ویجیٹ اس بات کا جائزہ دکھاتا ہے کہ مختلف میٹرکس پر کتنے سوالات انتباہی حد تک پہنچ چکے ہیں: غلط فہمی، پیچیدگی، واضح اور غلطی کا سکور۔",
            metric: "میٹرک",
            questions: "سوالات",
            highMisconception: "اعلیٰ غلط فہمی۔",
            highObviousness: "اعلیٰ ظاہری۔",
            highComplexity: "اعلی پیچیدگی",
            highErrorScore: "اعلی غلطی کا سکور",
          },
          adoption: {
            header: "گود لینا",
            description:
              "یہ گراف آپ کی آبادی کے عالمی گود لینے کی حیثیت کو ظاہر کرتا ہے۔ اس سے آپ کو یہ شناخت کرنے میں مدد ملتی ہے کہ آیا آپ کے صارفین نے رجسٹریشن کا عمل مکمل کر لیا ہے اور اپنا پہلا کوئز کھیلنا شروع کر دیا ہے۔",
            invited: "مدعو کیا۔",
            registered: "رجسٹرڈ",
            activated: "چالو",
          },
          overallCompliance: {
            header: "مجموعی تعمیل",
            compliant: "مطابق",
            notCompliant: "غیر مطابق",
            current: "موجودہ",
            allTime: "ہمیشہ",
          },
          error: {
            header: "ایک خرابی آگئی",
            reload: "دوبارہ لوڈ کرنے کے لیے یہاں کلک کریں۔",
          },
          noData: "دکھانے کے لئے کوئی ڈیٹا نہیں",
        },
        dialog: {
          deleteDashboard: {
            header: "ڈیش بورڈ کو حذف کریں۔",
            content: "کیا آپ واقعی ڈیش بورڈ {0} کو حذف کرنا چاہتے ہیں؟",
          },
        },
      },
      messages: {
        dashboardDeleted: "ڈیش بورڈ کو حذف کر دیا گیا ہے۔",
        dashboardSaved: "ڈیش بورڈ محفوظ ہو گیا ہے۔",
      },
    },
    profile: {
      form: {
        basicInformation: "بنیادی معلومات",
        firstName: "نام کا پہلا حصہ",
        lastName: "نام کا آخری حصہ",
        email: "ای میل",
        passwordSettings: "پاس ورڈ کی ترتیبات",
        currentPassword: "موجودہ پاس ورڈ",
        newPassword: "نیا پاس ورڈ",
        confirmNewPassword: "نئے پاس ورڈ کی توثیق کریں",
        languageSettings: "زبان کی ترتیبات",
        dashboardLanguage: "ڈیش بورڈ کی زبان",
        appLanguage: "ایپ کی زبان",
        default: "طے شدہ",
        errors: {
          missingUppercase: "پاس ورڈ میں بڑے حروف کی کمی ہے۔",
          missingLowercase: "پاس ورڈ میں چھوٹے حروف کی کمی ہے۔",
          missingDigit: "پاس ورڈ میں ایک ہندسہ غائب ہے۔",
        },
      },
      profile: "میری پروفائل",
      api: {
        header: "ای پی آئی کی ترتیبات",
        buttons: {
          showKey: "کلید دکھائیں",
          hideKey: "کلید چھپائیں",
        },
        form: {
          generateKey: "ای پی آئی کلید بنائیں",
          manageKeys: "ای پی آئی رسائی کو منظم کریں",
          keysDescription:
            "ہماری API تک محفوظیت سے رسائی حاصل کرنے کے لئے ایک یکتا کلید بنائیں۔ یاد رہے کہ ہر صارف کو صرف ایک فعال ای پی آئی کلید کی اجازت ہے۔ ایک نیا ای پی آئی کلید بنانے کے لئے، بس اوپر کے بٹن پر کلک کریں۔ اگر موجودہ فعال کلید ہے تو، تو نئی کلید بنانے پر وہ غیر موثر ہو جائے گی۔",
          value: "قیمت",
          expiration: "ختم ہونے کی تاریخ",
          deleteKey: "ای پی آئی کلید حذف کریں",
          deleteConfirmationPrompt:
            "آپ ایک ای پی آئی کلید کو حذف کرنے والے ہیں۔ جو بھی خدمات حال ہیں اس چابی کا استعمال کر رہی ہیں، وہ فوراً رسائی کھو دیں گی۔ براہ کرم یہ یقینی بنائیں کہ آپ نے حذف کے ساتھ تمام متعلقہ خدمات کو ایک متبادل کلید کے ساتھ اپ ڈیٹ کر لیا ہے۔",
        },
        dialogs: {
          delete: {
            header: "کلید غیر موثر ہو جائے گی",
            confirm:
              "موجودہ کلید غیر موثر ہو جائے گی۔ کیا آپ جاری رکھنا چاہتے ہیں؟",
          },
        },
        rateLimits: "شرح حدود",
        rateLimitDescription:
          "API شرح حدود کا استعمال بے انصافی کو روکنے، منصفانہ استعمال یقینی بنانے اور نظام کی استحکام اور کارکردگی کو برقرار رکھنے کے لئے استعمال ہوتے ہیں۔ اگر آپ کے پاس API کے استعمال یا شرح حدود کے بارے میں کوئی سوال ہو یا شرح حدود کافی نہ ہوں تو براہ کرم ہم سے رابطہ کریں {0}۔",
        userRateLimit: "صارف کی شرح حدود",
        companyRateLimit: "کمپنی کی شرح حدود",
        requestsPerMinute: "منٹ فی درخواست",
        docs: "OpenAPI دستاویزات، جو API کے ساتھ عمل کرنے کی مکمل معلومات اور معیارات فراہم کرتی ہیں، {0} پر دستیاب ہیں۔",
        authentication: "API تصدیق",
        authenticationDescription:
          "API کیلئے درخواستوں کو بعد کیلئے صارف کی تصدیق اور درخواست شدہ وسائل تک رسائی حاصل کرنے کے لئے {0} ہیڈر میں API کی چابی شامل ہونی چاہئے۔",
        exampleRequest: "مثال کا درخواست",
        gettingStarted: "آغاز کرنا",
      },
    },
    users: {
      header: "صارفین",
      cards: {
        totalUsers: "مجموعی صارفین",
        availableSeats: "دستیاب سیٹیں",
        registeredUsers: "اندراج شدہ صارفین",
        unregisteredUsers: "غیراندرج شدہ صارفین",
        onlineUsers: "آن لائن صارفین",
      },
      table: {
        messages: {
          usersActivated: "منتخب صارفین فعال کر دیے گئے ہیں",
          usersDeactivated: "منتخب صارفین غیر فعال کر دیے گئے ہیں",
          invitationSent: "دعوت بھیج دی گئی ہے",
          invitationsSent: "دعوتیں بھیج دی گئی ہیں",
        },
        controls: {
          inviteButton: "دعوت دیں",
          importButton: "درآمد کریں",
          actionsButton: {
            label: "کارروائیاں",
            items: {
              activate: "فعال کریں",
              deactivate: "غیرفعال کریں",
              resendInvitations: "دعوتیں دوبارہ بھیجیں",
              delete: "حذف کریں",
            },
          },
          filtersDropdown: {
            registered: "اندراج شدہ",
            unregistered: "غیراندراج شدہ",
            enabled: "فعال کردہ",
            disabled: "غیرفعال کردہ",
          },
        },
        columns: {
          select: "منتخب کریں",
          firstname: "نام کا پہلا حصہ",
          lastname: "نام کا آخری حصہ",
          email: "ای میل",
          learningProgress: "سیکھنے میں پیش رفت",
          trainingTime: "کتنے منٹ ٹریننگ ہوئی",
          certificationStatus: "سند دہندگی کی حالت",
          lastSeenOnline: "آخری بار آن لائن نظر آنے کا وقت",
          moderator: "موڈریٹر",
          disabled: "غیرفعال کردہ",
          invitationDate: "مدعو",
          invitationStatus: "دعوت کی حالت",
          actions: {
            edit: "تدوین کریں",
            activate: "فعال کریں",
            deactivate: "غیرفعال کریں",
            delete: "حذف کریں",
            resendInvitation: "دعوت دوبارہ بھیجیں",
          },
          sso: "SSO",
        },
        dialogs: {
          bulkDelete: {
            header: "صارفین کو حذف کریں",
            content: "کیا آپ منتخب صارفین کو واقعی حذف کرنا چاہتے ہیں؟",
            confirmationMessage: "منتخب صارفین حذف کر دیے گئے ہیں!",
          },
          activationConfirmation: {
            header: "صارف کو فعال کریں",
            content: "کیا آپ صارف {0} کو واقعی فعال کرنا چاہتے ہیں؟",
            confirmationMessage: "صارف فعال کر دیا گیا ہے!",
          },
          deactivationConfirmation: {
            header: "صارف کو غیرفعال کریں",
            content: "کیا آپ صارف {0} کو واقعی غیرفعال کرنا چاہتے ہیں؟",
            confirmationMessage: "صارف کو غیرفعال کر دیا گیا ہے!",
          },
          deleteConfirmation: {
            header: "صارف کو حذف کریں",
            content: "کیا آپ صارف {0} کو واقعی حذف کرنا چاہتے ہیں؟",
            confirmationMessage: "صارف حذف کر دیا گیا ہے!",
          },
        },
      },
      components: {
        certification: {
          certified: "سند یافتہ",
          notCertified: "ابھی سند یافتہ نہیں",
          expired: "ختم المیعاد",
          expires: "میعاد ختم ہو رہی ہے",
          columns: {
            mandatoryContent: "لازمی مواد",
            optionalContent: "اختیاری مواد",
            complianceContent: "تعمیل کا مواد",
            status: "حالت",
            startDate: "شروع ہونے کی تاریخ",
            endDate: "اختتام کی تاریخ",
            certifiedAt: "حاصل کرنے کی تاریخ",
            expiresAt: "ویلڈٹی",
            certifyTo: "ڈیڈلائن",
          },
          certificateStatus: "سند دہندگی کی حالت",
          downloadAll: "تمام ڈاؤن لوڈ کریں۔",
        },
        roles: {
          user: "صارف",
          moderator: "موڈریٹر",
          admin: "ایڈمن",
          user_manager: "صارف منتظم",
          content_creator: "مشمولات تخلیق کار",
          group_leader: "گروپ مینیجر",
        },
      },
      details: {
        editUser: "صارف کی تدوین کریں",
        inviteUsers: "صارفین کو دعوت دیں",
        userInformation: "معلومات استعمال کریں",
        certification: "سند دہندگی",
        trainings: "تربیت",
        password: "پاس ورڈ",
        passwordForm: {
          label: "صارف کا پاس ورڈ دوبارہ ترتیب دیں۔",
          sendEmail: "ای میل کے ذریعے پاس ورڈ دوبارہ ترتیب دینے کا لنک بھیجیں۔",
          generate: "پاس ورڈ دوبارہ ترتیب دینے کا لنک بنائیں",
          resetPassword: "پاس ورڈ ری سیٹ",
          userDisabledError:
            "غیر فعال صارف کا پاس ورڈ دوبارہ ترتیب نہیں دے سکتا",
          userNotConfirmedError:
            "تصدیق شدہ صارف کا پاس ورڈ دوبارہ ترتیب نہیں دیا جا سکتا",
          userProviderError:
            "SSO پر مبنی صارف کا پاس ورڈ دوبارہ ترتیب نہیں دیا جا سکتاSSO",
          linkGeneratedMessage:
            "پاس ورڈ دوبارہ ترتیب دینے کا لنک تیار ہو گیا ہے۔",
          linkSentMessage:
            "پاس ورڈ دوبارہ ترتیب دینے کا لنک صارف کو بھیج دیا گیا ہے۔",
        },
        form: {
          firstName: "نام کا پہلا حصہ",
          lastName: "نام کا آخری حصہ",
          email: "ای میل",
          groups: "گروپس",
          role: "کردار",
          dyslexic: "لکھے ہوئے الفاظ کو سمجھنے سے معذور",
          registered: "اندراج شدہ",
          status: "حالت",
          confirmed: "توثیق شدہ",
          deactivated: "غیر فعال",
          waitingForConfirmation: "توثیق کے لئے انتظار",
          lastOnline: "آخری بار کب آن لائن ہوا",
          totalPlaytime: "مجموعی کھیلنے کا وقت",
          resendConfirmation: "توثیقی ای میل دوبارہ بھیجیں",
          resendInvitation: "دعوت دوبارہ بھیجیں",
          generateLink: "توثیقی لنک بنائیں",
          invitationSent: "دعوت بھیج دی گئی ہے",
          confirmationRequestSent: "توثیقی درخواست بھیج دی گئی ہے",
          confirmationLink: "توثیقی لنک",
          emailStatus: "ای میل کی حیثیت",
          minutes: "منٹ",
          dialogs: {
            linkConfirmation: {
              header: "توثیقی لنک کی درخواست کریں",
              content:
                "توثیقی لنک کی مدد سے صارف اپنے اکاؤنٹ کی توثیق کر سکتا اور نوونگو استعمال کرنا شروع کر سکتا ہے۔ یہ لنک منفرد اور خفیہ ہوتی ہے۔ اس کا اشتراک صرف منتخب اکاؤنٹ کے مالک کے ساتھ ہی کریں۔",
            },
          },
          metaFields: "میٹا فیلڈز",
          fieldName: "فیلڈ کا نام",
          fieldData: "فیلڈ ڈیٹا",
        },
      },
      invitation: {
        form: {
          email: "ای میل پتہ",
          emails: "ای میل پتے",
          groups: "گروپس",
          role: "کردار",
          addMore: "مزید شامل کریں",
          groupsDescription:
            "مدعو کیے گئے صارفین منتخب گروپوں کے رکن بن جائیں گے۔",
          groupManagerNote:
            "مدعو کیے گئے صارفین کو دعوت نامہ بھیجنے کے بعد گروپس کے صفحہ پر ایک گروپ میں دستی طور پر تفویض کیا جائے گا۔",
        },
        successMessage: "دعوتیں بھیج دی گئی ہیں",
        submitButton: "بھیجیں",
      },
      import: {
        header: "صارفین درآمد کریں",
        selectFile: "فائل منتخب کریں",
        currentlySelectedFile: "فی الحال منتخب فائل:",
        successMessage: "اپ لوڈ مکمل!",
        usersCreated: "صارفین تخلیق کر دیے گئے ہیں",
        loadingText: "ڈیٹا ان لوڈ کر رہا ہے۔۔۔",
        step1: "مرحلہ 1: فائل منتخب کریں",
        step2: "مرحلہ 2: ورک شیٹ منتخب کریں",
        step3: "مرحلہ 3: ڈیٹا کی تصدیق کریں",
        step4: "مرحلہ 4: اپ لوڈ کریں",
        headers: {
          email: "ای میل",
          groups: "گروپس",
          firstname: "نام کا پہلا حصہ",
          lastname: "نام کا آخری حصہ",
          disable_after: "کے بعد غیرفعال کریں",
        },
        upload: "اپ لوڈ کریں",
        back: "صارف فہرست پر واپس",
      },
      warnings: {
        no_managed_groups:
          "گروپس کے صفحے پر جائیں اور اس صارف کو کم از کم ایک گروپ کے مینیجر کے طور پر تفویض کریں یا کردار کو تبدیل کریں۔",
      },
    },
    groups: {
      header: "گروپس",
      createGroup: "گروپ تخلیق کریں",
      group: "گروپ",
      editGroup: "گروپ کی تدوین کریں",
      errors: {
        teamNotEmpty: "ٹیم خالی نہیں ہے",
      },
      modals: {
        move: {
          title: "گروپوں کو منتقل کریں۔",
          moving: "حرکت پذیر",
          newParent: "نیا پیرنٹ گروپ منتخب کریں۔",
          move: "اقدام",
        },
      },
      details: {
        form: {
          group: "گروپ",
          description: "تفصیلات",
          parent: "پیرنٹ گروپ",
          name: "نام",
          parentManager: "مرکزی مینیجر",
          parentManagerDescription:
            "یہ مینیجر والدین کے ایک گروپ میں مینیجر کے طور پر مقرر ہے اور اسے یہاں سے ہٹایا نہیں جا سکتا۔",
          noManagersAssigned: "کوئی مینیجرز مقرر نہیں کیے گئے",
        },
        groupInformation: "گروپ کی معلومات",
        learningPlan: {
          label: "سیکھنے کا منصوبہ",
          course: "کورس",
          deadlineOptions: {
            days: "دن",
            weeks: "ہفتے",
            months: "مہینے",
            years: "سال",
          },
          table: {
            title: "عنوان",
            status: "حالت",
            deadline: "ڈیڈ لائن",
            startDate: "شروع ہونے کی تاریخ",
            occurrences: "وقوعات",
          },
          deadline: "ڈیڈ لائن",
          questions: "سوالات",
          published: "شائع",
          unpublished: "غیرشائع",
          delete: "حذف کریں",
          change: "تبدیلی",
          complianceContent: "تعمیل کا مواد",
          mandatoryContent: "لازمی مواد",
          optionalContent: "اختیاری مواد",
          indirectTopic: "بالواسطہ موضوع",
          indirectTopicInfo:
            "یہ موضوع والدین سے وراثت میں ملا ہے اور اسے اس جگہ تبدیل یا ہٹایا نہیں جا سکتا",
          conflictingTopic: "متضاد موضوع",
          conflictingTopicInfo:
            "اس موضوع کی آخری تاریخ یا اہمیت کو والدین کے عنوانات میں سے کسی ایک سے اوور رائڈ کیا جا سکتا ہے۔",
          topicPresentInCourse: "موضوع پہلے سے موجود ہے",
          topicPresentInCourseInfo:
            "یہ موضوع ایک کورس کا حصہ ہے جو اس تعلیمی منصوبے کو تعین کیا گیا ہے",
          noOccurrences: "کوئی وقوعہ نہیں",
          noOccurrencesInfo:
            "اس تعمیل کے مواد کے لئے کوئی وقوعہ سیٹ نہیں ہے۔ کم از کم ایک وقوعہ درکار ہے۔",
          accept: "قبول کریں۔",
          cancel: "منسوخ کریں۔",
          complianceSettings: "تعمیل کی ترتیبات",
          startDate: "شروع ہونے کی تاریخ",
          endDate: "اختتام کی تاریخ",
          cutoffDate: "اس کے بعد شروع نہ کریں",
          name: "نام",
          addOccurrence: "وقوعہ شامل کریں",
          occurrence: "وقوعہ",
          tooltips: {
            compliance: {
              title: "تعمیل کیا ہے؟",
              description:
                "تعمیل کے موضوعات کو صارفین کو ان کے مقررہ وقت کے اندر مکمل کرنا ضروری ہے۔ سوالات کا جواب صرف ایک بار دینا ہوتا ہے اور یہ آپ کے صارفین کی جانچ کا ایک طریقہ فراہم کریں گے۔",
              startDate: "شروع ہونے کی تاریخ",
              startDateDescription:
                "یہ وہ تاریخ ہے جب موضوع آبادیوں کے لئے دستیاب ہو جاتا ہے",
              endDate: "اختتام کی تاریخ",
              endDateDescription:
                "یہ وہ آخری تاریخ ہے جب تک صارفین کو اپنی تعمیل کی تاریخ تک پہنچنا ہوتا ہے",
              dontStartAfter: "اس کے بعد شروع نہ کریں",
              dontStartAfterDescription:
                "یہ تاریخ اس موضوع کو روک دیتی ہے کہ وہ صارفین کے لئے ظاہر ہو جو اس تاریخ کے بعد سیکھنے کے منصوبے میں شامل کیے گئے ہیں۔ یہ اسے اگلی شروع ہونے کی تاریخ پر دستیاب کرے گا۔",
            },
          },
        },
        trainings: {
          label: "تربیت",
          indirectTraining: "بالواسطہ تربیت",
          indirectTrainingInfo:
            "یہ تربیت والدین میں سے کسی ایک سے وراثت میں ملتی ہے۔",
        },
        settings: {
          groupLeaders: "گروپ لیڈرز",
          reporting: "رپورٹنگ",
          label: "ترتیبات",
          groupRanking: "گروپ درجہ بندی",
          impact: "اثر",
          adoption: "اختیارکاری",
          certification: "سند دہندگی",
          enableReporting: "رپورٹنگ فعال کریں",
          reportType: "رپورٹ کی قسم",
          reportFormat: "رپورٹ کا فارمیٹ",
          startDate: "شروعات کی تاریخ",
          hour: "گھنٹہ",
          endDate: "اختتام کی تاریخ",
          frequency: "کتنی بار",
          dayOfTheWeek: "ہفتہ کا دن",
          azureSelectDescription:
            "منتخب Azure گروپ کے اراکین ایس ایس او سائن ان کے دوران خودکار طور پر سنکرونائز ہو جائیں گے۔",
        },
        users: {
          label: "صارفین",
          table: {
            registered: "اندراج شدہ",
            invited: "مدعو",
            columns: {
              user: "صارف",
              member: "رکن",
              status: "حالت",
              actions: "کارروائیاں",
            },
            controls: {
              filters: {
                registered: "اندراج شدہ",
                invited: "مدعو",
                member: "رکن",
                notMember: "رکن نہیں",
              },
            },
          },
        },
      },
      table: {
        columns: {
          select: "منتخب کریں",
          group: "گروپ",
          description: "تفصیلات",
          users: "صارفین",
          topics: "موضوعات",
          actions: {
            edit: "تدوین کریں",
            delete: "حذف کریں",
            openInTab: "نئے ٹیب میں کھولیں۔",
          },
          dialogs: {
            deleteConfirmation: {
              header: "گروپ حذٖف کریں",
              content: "کیا آپ گروپ {0} واقعی حذف کرنا چاہتے ہیں؟",
              confirmationMessage: "گروپ حذف کر دیا گیا ہے!",
              dataLostWarning:
                "اس گروپ اور اس کے تمام ذیلی گروپس سے متعلق تمام ڈیٹا، جیسے کہ گروپ کی رکنیت اور سیکھنے کے منصوبے، ضائع ہو جائیں گے۔",
            },
          },
        },
        controls: {
          createButton: "گروپ تخلیق کریں",
          editGroupButton: "کے لیے ترتیبات تبدیل کریں۔ {0}",
          actionsButton: "کارروائیاں",
          deleteButton: "حذف کریں",
          moveButton: "اقدام",
          dialogs: {
            deleteConfirmation: {
              header: "گروپس حذف کریں",
              content: "کیا آپ منتخب گروپس واقعی حذف کرنا چاہتے ہیں؟",
              confirmationMessage: "منتخب گروپ حذف ہو گئے ہیں!",
              dataLostWarning:
                "منتخب گروپس اور ان کے تمام ذیلی گروپس سے متعلق تمام ڈیٹا، جیسے کہ گروپ کی رکنیت اور سیکھنے کے منصوبے، ضائع ہو جائیں گے۔",
            },
          },
        },
        tooltips: {
          defaultGroup: {
            header: "ڈیفالٹ گروپ",
            content:
              "ڈیفالٹ گروپ میں سبھی صارفین شامل ہوتے ہیں۔ اراکین کو بدلا نہیں جا سکتا ہے، مگر آپ تعلیمی منصوبے کا نظم کر سکتے ہیں جو تنظیم کے سبھی اراکین پر لاگو ہو گا۔",
          },
        },
      },
    },
    topics: {
      header: "موضوعات",
      import: {
        successMessage: "موضوع آپ کی لائبریری میں شامل ہو گیا ہے",
        errors: {
          ownTopic: "موضوع آپ کی لائبریری میں پہلے سے موجود ہے",
          invalidCode: "اِن ویلڈ شیئر کوڈ",
        },
        form: {
          enterCode: "شیئر کوڈ داخل کریں",
        },
      },
      generate: {
        header: "AI سے تیار کردہ موضوع بنائیں",
        form: {
          fields: {
            input: {
              label: "ان پٹ",
              placeholder: "موضوع کیا ہونا چاہیے؟ ",
            },
            numQuestions: {
              label: "سوالات کی مقدار",
            },
            numIntroduction: {
              label: "تعارفی مواد کی مقدار",
            },
            baseLanguage: {
              label: "بنیادی زبان",
            },
            context: {
              label: "سیاق و سباق",
              description:
                "ماڈل کونٹینٹ بنانے کے لئے استعمال ہونے والے فائل کا انتخاب کریں۔",
            },
          },
          questions: "سوالات",
          introduction: "تعارف",
          introductionMaterials: "تعارفی مواد",
          review: "جائزہ لیں",
          advanced: "اعلی درجے کی",
        },
        generatingContent: "مواد تیار ہو رہا ہے...",
      },
      details: {
        multiLanguage: {
          details: {
            topic: "موضوع",
            localization: "مقامی تقاضوں سے ہم آہنگ کرنا",
            info: "معلومات",
            createdMessage: "موضوع تخلیق ہو گیا ہے",
            updatedMessage: "موضوع اپڈیٹ ہو گیا ہے",
          },
          form: {
            useBaseImage: "بیس میڈیا کا استعمال کریں۔",
            title: "عنوان",
            description: "تفصیلات",
            baseLanguage: "بنیادی زبان",
            certified: "سند یافتہ",
            questions: "سوالات",
            backgroundInfo: "پس منظر معلومات",
            introduction: "تعارف",
            users: "صارفین",
            selectLanguage: "زبان منتخب کریں",
            delete: "حذف کریں",
            tooltips: {
              baseTopic: {
                header: "بنیادی زبان",
                content: "بنیادی زبان کو ہٹایا نہیں جا سکتا",
              },
            },
          },
          localizationForm: {
            language: "زبان",
            base: "ڈیفالٹ زبان",
            baseDescription:
              "جو مقام بطور ڈیفالٹ سیٹ کیا گیا ہے وہ زبان ہے جس میں موضوع ڈیش بورڈ میں دکھایا جاتا ہے۔ اسے خودکار ترجمے کے لیے بھی استعمال کیا جائے گا۔ اور اگر صارف کی ترجیحی زبان دستیاب نہیں ہے تو Knowingo ایپ میں اسے بطور بیک اپ بھی استعمال کیا جائے گا۔",
            addLocalization: "لوکلائزیشن شامل کریں",
          },
        },
        standard: {
          details: {
            createdMessage: "موضوع تخلیق ہو گیا ہے",
            updatedMessage: "موضوع اپڈیٹ ہو گیا ہے",
          },
          form: {
            useBaseImage: "بیس میڈیا کا استعمال کریں۔",
            title: "عنوان",
            description: "تفصیلات",
            certified: "تصدیق شدہ",
            questions: "سوالات",
            backgroundInfo: "پس منظر معلومات",
            introduction: "تعارف",
            users: "صارفین",
            delete: "حذف کریں",
          },
        },
      },
      modals: {
        clone: {
          header: "موضوع کلون کریں",
          label: "موضوع کا نام داخل کریں",
          copy: "کاپی کریں",
          clone: "کلون کریں",
          cancel: "منسوخ کریں",
          enterName: "موضوع کا نام داخل کریں",
        },
        convert: {
          header: "کثیر لسانی موضوع میں بدلیں",
          description:
            "بدلنے کے بعد صارفین کے پاس ان کے اعداد و شمار اور سندیں ہوں گی۔",
          label: "زبان",
          convert: "بدلیں",
          cancel: "منسوخ کریں",
          selectLanguage: "بنیادی زبان منتخب کریں",
        },
        publish: {
          header: "موضوع شائع کریں",
          publish: "شائع کریں",
          cancel: "منسوخ کریں",
          multiLanguageTopicInfo:
            "آپ کثیر لسانی موضوع {0} شائع کرنے کے قریب ہیں۔",
          regularTopicInfo:
            "آپ موضوع {1} میں {0} سوالات شائع کرنے کے قریب ہیں۔",
          publishableQuestionsInfo: "{0} سوالات شائع کیے جائیں گے",
          unpublishableQuestionsInfo:
            "{0} نامکمل سوالات شائع نہیں کیے جا سکتے ہیں",
          contentFetchingError:
            "ہم ڈیٹا حاصل نہیں کر سکے۔ برائے مہربانی بعد میں دوبارہ کوشش کریں۔",
          publishedNotification: "موضوع شائع ہو گیا ہے",
          publishTopic: "آپ موضوع {0} شائع کرنے کے قریب ہیں",
          addMoreQuestions:
            "اس موضوع کو شائع کرنے کے لئے {0} مزید سوال شامل کریں",
        },
      },
      table: {
        header: "موضوعات",
        createTopic: "موضوع تخلیق کریں",
        importTopic: "موضوع درآمد کریں۔",
        editTopic: "موضوع کی تدوین کریں",
        status: {
          published: "شائع",
          publishedDescription: "یہ موضوع شائع ہے اور اس میں {0} سوالات ہیں",
          unpublished: "غیرشائع",
          metaUnpublishedDescription:
            "ترجمہ میں سے کم از کم ایک غیر مطبوعہ سوالات پر مشتمل ہے۔",
          unpublishedDescription: "اس موضوع میں {0} غیرشائع سوالات ہیں",
          changed: "تبدیلیاں غیرشائع کریں",
          metaChangedDescription:
            "ترجمہ میں سے کم از کم ایک غیر مطبوعہ تبدیلیوں پر مشتمل ہے۔",
          changedDescription:
            "اس موضوع میں {0} بدلے ہوئے اور {1} غیرشائع سوالات ہیں",
          shared: "اشتراک کردہ",
          sharedDescription:
            "اس موضوع کا آپ کے ساتھ کسی دوسری تنظیم کے ذریعہ اشتراک کیا گیا ہے",
          locked: "مقفل کردہ",
          lockedDescription: "یہ موضوع مقفل ہے اور اسے بدلا نہیں جا سکتا ہے",
          users: "غیرتفویض کردہ موضوع",
          usersDescription:
            "یہ موضوع کسی تعلیمی منصوبے کو تفویض نہیں کیا گیا ہے",
          meta: "کثیر لسانی موضوع",
          metaDescription: "یہ موضوع کئی ایک زبانوں میں ترجمہ کے لئے تیار ہے",
          cannotPublish:
            "اس موضوع کو شائع کرنے کے لئے {0} مزید سوال شامل کریں۔",
        },
        controls: {
          filters: {
            published: "شائع",
            unpublished: "غیرشائع",
            changed: "تبدیلیاں غیرشائع کریں",
          },
          actions: {
            label: "کارروائیاں",
            publish: "شائع کریں",
            unpublish: "غیرشائع کریں",
            delete: "حذف کریں",
          },
          dialogs: {
            deleteConfirmation: {
              header: "موضوعات حذف کریں",
              content: "کیا آپ منتخب موضوعات واقعی حذف کرنا چاہتے ہیں؟",
              successMessage: "منتخب موضوعات حذف کر دیے گئے ہیں",
            },
          },
          enterShareCode: "شیئر کوڈ داخل کریں",
          createTopic: "موضوع تخلیق کریں",
          createTopicAI: "AI کے ساتھ موضوع بنائیں",
          createMultiLanguageTopic: "کثیر لسانی موضوع تخلیق کریں",
        },
        components: {
          languageList: {
            tooltip: {
              title: "لوکلائزیشنز",
              description:
                "پہلے سے طے شدہ مواد میں ترجمے شامل کریں تاکہ یہ یقینی بنایا جا سکے کہ ہر کوئی یکساں مقدار میں سیکھتا ہے۔",
              unpublishedQuestions: "{0} غیر مطبوعہ سوالات",
              changedQuestions: "{0} تبدیل شدہ سوالات",
              noQuestions: "کوئی سوال نہیں۔",
              fullyTranslated: "مکمل ترجمہ",
              translationMissing: "ترجمہ غائب ہے۔",
            },
          },
        },
        columns: {
          select: "منتخب کریں",
          topic: "عنوان",
          description: "تفصیلات",
          questions: "سوالات",
          users: "صارفین",
          status: "حالت",
          thumbnail: "تصویر",
          comments: "تبصرے",
          language: "زبان",
          actions: {
            openQuestions: "سوالات کھولیں",
            openIntroduction: "تعارفی مواد کھولیں",
            edit: "تدوین کریں",
            publish: "شائع کریں",
            unpublish: "غیرشائع کریں",
            delete: "حذف کریں",
            clone: "کلون کریں",
            convert: "متعدد زبانوں میں بدلیں",
          },
          dialogs: {
            deleteConfirmation: {
              header: "موضوع حذف کریں",
              content:
                "کیا آپ موضوع {0} اور اس کے سبھی سوالات واقعی حذف کرنا چاہتے ہیں؟",
              successMessage: "موضوع حذف ہو گیا ہے",
            },
          },
        },
        publishedSuccessMessage: "موضوع شائع ہوگیا ہے",
        incompleteItemsWarning:
          "کچھ مواد کو ایک خرابی کی وجہ سے منظر عام نہیں کیا جا سکا۔ براہ کرم یقینی بنائیں کہ تمام مواد مکمل ہیں اور دوبارہ کوشش کریں۔",
        unpublishedSuccessMessage: "موضوع غیرشائع ہو گیا ہے",
        removedSuccessMessage: "موضوع ہٹا دیا گیا ہے",
        conversionStarted: "موضوع جلد ہی بدل دیا جائے گا",
        errors: {
          deleteSharedTopic:
            "اشتراک کردہ موضوع حذف نہیں کر سکتے۔ موجودہ سبھی اشتراکات منسوخ کریں اور دوبارہ کوشش کریں۔",
        },
      },
      components: {
        tooltips: {
          status: {
            published: "شائع",
            unpublished: "غیرشائع",
            changed: "غیرشائع تبدیلیاں",
          },
        },
      },
    },
    courses: {
      header: "کورسز",
      table: {
        controls: {
          create: "کورس بنائیں",
          shareCode: "کورس شیئر کوڈ",
          actions: {
            label: "اعمال",
            delete: "حذف کریں۔",
          },
          filters: {
            published: "شائع شدہ",
            unpublished: "غیر مطبوعہ",
          },
        },
        dialogs: {
          deleteConfirmation: {
            header: "کورسز کو حذف کریں۔",
            content: "کیا آپ واقعی منتخب کورسز کو حذف کرنا چاہتے ہیں؟",
          },
        },
        columns: {
          select: "منتخب کریں۔",
          thumbnail: "تصویر",
          name: "نام",
          description: "تفصیل",
          num_published_topics: "شائع شدہ عنوانات",
          languages: "زبانیں",
          num_topics: "عنوانات",
          num_questions: "سوالات",
          num_users: "صارفین",
          actions: {
            label: "اعمال",
            edit: "ترمیم",
            delete: "حذف کریں۔",
          },
        },
      },
      details: {
        header: "کورس",
        create: "نیا بنائیں",
        navigation: {
          course: "کورس",
          localization: "لوکلائزیشن",
          topics: "عنوانات",
          info: "معلومات",
        },
        form: {
          inputs: {
            name: {
              label: "نام",
            },
            description: {
              label: "تفصیل",
            },
            defaultLanguage: {
              label: "پہلے سے طے شدہ زبان",
            },
            courseSettings: {
              label: "کورس کی ترتیبات",
              text: "ٹاپک آرڈرنگ اور لاکنگ",
            },
          },
        },
        topicsForm: {
          topics: "عنوانات",
          selectTopic: "موضوع منتخب کریں۔",
          openTopic: "نئے ٹیب میں موضوع کھولیں۔",
          showTranslations: "ترجمے دکھائیں۔",
          hideTranslations: "ترجمے چھپائیں۔",
          noTopics: "کوئی موضوعات نہیں",
        },
        localizationForm: {
          language: "زبان",
          default: "پہلے سے طے شدہ",
          addLocalization: "لوکلائزیشن شامل کریں",
        },
        localizationModal: {
          header: "لوکلائزیشن سیٹ کریں",
          selectDefaultLanguage: "پہلے سے طے شدہ زبان کا انتخاب کریں",
          selectTranslations: "ترجمے منتخب کریں",
          saveSettings: "نئے بنائے گئے کورسز کے لیے یہ سیٹنگز محفوظ کریں",
          changesPossible:
            "آپ اب بھی اس ڈائیلاگ کو بند کرنے کے بعد ڈیفالٹ زبان تبدیل کر سکتے ہیں اور ترجمے شامل یا ہٹا سکتے ہیں۔",
        },
      },
      dialogs: {
        deleteConfirmation: {
          header: "کورس کو حذف کریں۔",
          content: "کیا آپ واقعی کورس {0} کو حذف کرنا چاہتے ہیں؟",
        },
      },
      tooltips: {
        languageList: {
          translations: "ترجمے",
        },
        publishedTopics: {
          publishedTopics: "شائع شدہ عنوانات",
          noPublishedTopics: "اس کورس میں شائع شدہ عنوانات نہیں ہیں۔",
          hasUnpublishedTopics: "یہ کورس غیر مطبوعہ موضوعات پر مشتمل ہے۔",
        },
      },
    },
    shares: {
      library: {
        header: "لائبریری کے اشتراکات",
        createLibraryShare: "لائبریری شیئر تخلیق کریں",
        editLibraryShare: "لائبریری شیئر میں تدوین کریں",
        details: {
          form: {
            required: "موضوع لازمی ہے",
            mustBePublished:
              "موضوع کو مشمولات کی لائبریری میں شامل کرنے کے لئے اس کا شائع ہونا لازمی ہے",
            imageRequired:
              "موضوع کو مشمولات کی لائبریری میں شامل کرنے کے لئے موضوع تصویر لازمی ہے",
            sharingExplanation:
              "مشمولات کی لائبریری میں اشتراک کرنے سے دیگر کمپنیاں آپ کے مشمولات کو اپنے تعلیمی منصوبوں میں استعمال کر پائیں گی",
            submissionWarning:
              "سوالات، پس منظر معلومات اور تعارفی مواد اس وقت تک قابل تدوین نہیں ہوں گے جب تک کہ جمع کاری نہ ہو جائے۔",
          },
        },
        table: {
          declined: "انکار کر دیا گیا",
          outdated: "تاریخ نکل گئی",
          published: "شائع",
          inReview: "زیر جائزہ",
          columns: {
            select: "منتخب کریں",
            topic: "موضوع",
            holders: "کمپنیاں",
            created: "اشتراک کردہ",
            hide_questions: "سوالات پوشیدہ کریں",
            num_users: "صارفین",
            num_total_certificates: "حاصل کردہ سندیں",
            training_minutes: "کتنے منٹ تربیت ہوئی",
            status: "حالت",
            actions: {
              label: "کارروائیاں",
              update: "اپڈیٹ کریں",
            },
          },
          controls: {
            createShare: "شیئر تخلیق کریں",
            actions: {
              label: "کارروائیاں",
              updateSelected: "منتخب کو اپڈیٹ کریں",
            },
          },
        },
      },
      sharelinks: {
        header: "شیئر لنکس",
        createShare: "شیئر لنک تخلیق کریں",
        editShare: "شیئر لنک کی تدوین کریں",
        details: {
          one: "",
          other: "",
        },
        table: {
          yes: "ہاں",
          no: "نہیں",
          controls: {
            createShare: "شیئر تخلیق کریں",
            actions: {
              label: "کارروائیاں",
              revoke: "منسوخ کریں",
            },
            dialogs: {
              revokeConfirmation: {
                header: "شیئر لنکس منسوخ کریں",
                content: "کیا آپ منتخب شیئر لنکس واقعی منسوخ کرنا چاہتے ہیں؟",
                successMessage: "منتخب شیئر لنکس منسوخ ہو گئی ہیں",
              },
            },
          },
          columns: {
            select: "منتخب کریں",
            topic: "موضوع",
            holders: "کمپنیاں",
            created: "اشتراک کردہ",
            hide_questions: "سوالات پوشیدہ کریں",
            num_users: "صارفین",
            num_total_certificates: "حاصل کردہ سندیں",
            training_minutes: "کتنے منٹ تربیت ہوئی",
            actions: {
              label: "کارروائیاں",
              edit: "تدوین کریں",
              unhide: "سوالات غیرپوشیدہ کریں",
              hide: "سوالات پوشیدہ کریں",
              revoke: "منسوخ کریں",
            },
            dialogs: {
              revokeConfirmation: {
                header: "شیئر لنک منسوخ کریں",
                content: "کیا آپ منتخب شیئر لنک واقعی منسوخ کرنا چاہتے ہیں؟",
                successMessage: "شیئر لنک منسوخ ہو گئی ہے",
              },
            },
          },
        },
      },
    },
    signupURLs: {
      header: "سائن اپ یو آر ایلز",
      createUrl: "سائن اپ یو آر ایل تخلیق کریں",
      editUrl: "سائن اپ یو آر ایل کی تدوین کریں",
      details: {
        urlCreated: "سائن اپ یو آر ایل تخلیق ہو گیا ہے",
        form: {
          signupURL: "سائن اپ یو آر ایل",
          groups: "گروپس",
        },
      },
      settings: {
        form: {
          domainPlaceholder: "ڈومین کا نام، مثلاً knowingo.com",
          add: "شامل کریں",
          enableNotifications: "اطلاعات فعال کریں",
          frequency: "کتنی بار",
          dayOfTheWeek: "ہفتہ کا دن",
          dayOfTheWeekDescription:
            "رپورٹس خود بخود منتخب دن کے ہر مہینے آخری واقعہ پر بھیجی جائیں گی۔",
          hour: "گھنٹہ",
          allowedDomains: "اجازت یافتہ ڈومینز",
          allowedDomainsDescription:
            "اگر آپ موصول کردہ فیلڈ میں ایک یا زیادہ ڈومین درج کرتے ہیں، تو صرف ان ڈومینوں کے ساتھ جڑے ای میل ایڈریس قبول کیے جائیں گے۔ ان مخصوص ڈومینوں کے باہر ای میل ایڈریس رکھنے والے صارفین کو پلیٹ فارم تک رسائی حاصل نہیں ہوگی۔ اگر ڈومین کی فہرست کا فیلڈ خالی چھوڑ دیا جائے، تو کسی بھی ڈومین سے ای میل ایڈریس رکھنے والے صارف بلا پابندی سائن اپ کر سکتے ہیں۔",

          notifications: "اطلاعات",
          save: "محفوظ کریں",
          addDomain: "ڈومین شامل کریں",
        },
      },
      table: {
        controls: {
          createLink: "نئی لنک تخلیق کریں",
          settings: "ترتیبات",
          actions: {
            label: "کارروائیاں",
            refresh: "ریفریش کریں",
            delete: "حذف کریں",
          },
          dialogs: {
            deleteConfirmation: {
              header: "سائن اپ یو آر ایلز حذف کریں",
              content:
                "کیا آپ منتخب سائن اپ یو آر ایلز واقعی حذف کرنا چاہتے ہیں؟",
              successMessage: "منتخب سائن اپ یو آر ایلز حذف کر دیے گئے ہیں",
            },
          },
        },
        columns: {
          code: "کوڈ",
          groups: "گروپس",
          created: "تخلیق کردہ",
          actions: {
            copyLink: "لنک کاپی کریں",
            edit: "تدوین کریں",
            refresh: "ریفریش کریں",
            delete: "حذف کریں",
          },
          dialogs: {
            deleteConfirmation: {
              header: "سائن اپ یو آر ایل حذف کریں",
              content: "کیا آپ سائن اپ یو آر ایل واقعی حذف کرنا چاہتے ہیں؟",
              successMessage: "سائن اپ یو آر ایل حذف ہو گیا ہے",
            },
          },
        },
      },
    },
    feedback: {
      header: "تاثر",
      details: {
        commentDeleted: "تبصرہ حذف ہو گیا",
        cannotDeleteComment:
          "تبصرہ حذف نہیں کر سکا۔ برائے مہربانی بعد میں دوبارہ کوشش کریں",
        delete: "حذف کریں",
        status: "حالت",
        new: "نیا",
        resolved: "حل ہو گیا",
        closed: "بند ہو گیا",
        responseSaved: "جواب محفوظ ہو گیا",
        yourResponse: "آپ کا جواب",
        addResponse: "جواب شامل کریں",
        notifyFeedbackAuthor: "تاثرات مصنف کو مطلع کریں۔",
      },
      table: {
        filters: {
          new: "نیا",
          resolved: "حل ہو گیا",
          closed: "بند ہو گیا",
        },
        controls: {
          actions: {
            label: "کارروائیاں",
            delete: "حذف کریں",
          },
          dialogs: {
            deleteConfirmation: {
              header: "تاثر حذف کریں",
              content: "کیا آپ منتخب تاثراتی آئٹمز واقعی حذف کرنا چاہتے ہیں؟",
              successMessage: "منتخب تاثراتی آئٹمز حذف ہو گئے ہیں",
            },
          },
        },
        columns: {
          select: "منتخب کریں",
          firstname: "پہلا نام",
          lastname: "آخری نام",
          email: "ای میل",
          title: "عنوان",
          text: "عبارت",
          status: "حالت",
          created: "تخلیق کیا گیا",
          responses: "جوابات",
          actions: {
            label: "کارروائیاں",
            delete: "حذف کریں",
          },
          dialogs: {
            deleteConfirmation: {
              header: "تاثر حذف کریں",
              content: "کیا آپ تاثر {0} واقعی حذف کرنا چاہتے ہیں؟",
              successMessage: "تاثر حذف ہو گیا ہے",
            },
          },
          deletedUser: "حذف کیا گیا صارف",
        },
      },
    },
    questionAnalytics: {
      header: "سوال کے تجزیے",
      details: {
        components: {
          cards: {
            misconception: "غلط فہمی",
            misconceptionDescription:
              "غلط فہمی اس وقت ہوتی ہے جب آپ کے متعلمین کی کافی بڑی تعداد وہی غلط جواب فراہم کرے۔ اس میٹرک کا استعمال کر کے پتہ لگائیں کہ آپ کے متعلمین کو کس جگہ بڑی غلط فہمیاں ہو رہی ہیں۔",
            complexity: "پیچیدگی",
            complexityDescription:
              "سوالات کو اس وقت پیچیدہ سمجھا جاتا ہے جب آپ کے متعلمین ان کے جواب دینے کے لئے توقع سے زیادہ وقت لیتے ہیں۔ متعلمین اس سوال کو پیچیدہ اس لئے سمجھ سکتے ہیں کیونکہ وہ بہت لمبا ہے، اس میں نامعلوم عوامی بولی کے الفاظ ہیں، یا وہ اس موضوع سے متعلق نہیں ہے۔ بہتر نتائج کے لئے انتہائی پیچیدہ سوالات کی تدوین کریں۔",
            obviousness: "واضح پن",
            obviousnessDescription:
              "سوالات اس وقت واضح سمجھے جاتے ہیں جب آپ کے متعلمین کسی مزید معلومات کے بغیر ان کے جوابات درست طور پر دے لے جائیں۔ اگر نوونگو+ کے مشاہدہ میں یہ بات آتی ہے کہ آپ کے متعلمین کی ایک بڑی تعداد اس سوال کو پہلی بار دیکھنے پر ہی اس کا جواب درست طور پر دے لیتی ہے تو، اس سوال کا واضح پن اسکور بڑھ جاتا ہے۔",
            error: "خرابی",
            errorDescription:
              "خرابی کے اسکور سے پتہ چلتا ہے کہ آیا ماہرین اس سوال کے درست جواب سے بظاہر غیرمتفق ہیں۔ جب کسی موضوع کے ماہرین کی اکثریت وہی غلط جواب چنتے ہیں تو، اس بات کا انتہائی زیادہ امکان ہے کہ آپ کے سوال میں مسئلہ ہے، یا یہ کہ آپ کے ماہرین کے ذریعہ چنا گیا غلط جواب عام طور پر درست جواب ہوتا ہے۔",
            checkmarkDescription:
              "سوال کے تجزیات کی فہرست میں غلطی کے اسکور کو ظاہر ہونے سے روکنے کے لیے آپ چیک مارک پر کلک کر سکتے ہیں۔",
            hideErrorScoreWarning: "خراب اسکور سے متعلق وارننگ غیرفعال کریں",
          },
        },
        answerContribution:
          "آپ کے {0} صارف بیس نے {1} کے درست جواب ہونے کا انتخاب کیا ہے",
        expertsAgree: "اکثر ماہرین کا اتفاق ہے کہ یہ درست جواب ہے",
        expertsContribution: "{0} ماہرین کا ماننا ہے کہ {1} درست جواب ہے",
        expertsNotAvailable:
          "اس سوال کے لیے ماہرین کے اعداد و شمار ابھی دستیاب نہیں ہیں۔",
        sec: "سیکنڈ",
        avgAnsweringSpeed: "جواب دینے کی اوسط رفتار",
        usersIntroduced: "صارفین جن کا تعارف کرایا گیا",
        answerStatistics: "جواب کے اعداد و شمار",
        openQuestion: "سوال کھولیں",
      },
      table: {
        published: "شائع",
        changed: "غیرشائع تبدیلیاں",
        unpublished: "غیرشائع",
        incomplete: "نامکمل",
        details: "تفصیلات",
        goToQuestion: "سوال پر جائیں۔",
        columns: {
          category_name: "موضوع",
          title: "سوال",
          status: "حالت",
          misconception_score: "غلط فہمی",
          complexity_score: "پیچیدگی",
          obviousness_score: "واضح پن",
          content_error_score: "مشمولات کی خرابی",
          num_users_seen: "کے ذریعہ دیکھا گیا",
          num_users: "کے لئے دستیاب",
        },
        controls: {
          showWithoutMetrics: "سوالات بغیر میٹرکس کے دکھائیں",
          filters: {
            published: "شائع",
            unpublished: "غیرشائع",
            changed: "غیرشائع تبدیلیاں",
            high_misconception: "اعلیٰ غلط فہمی۔",
            high_obviousness: "اعلیٰ ظاہری۔",
            high_complexity: "اعلی پیچیدگی",
            high_error_score: "اعلی غلطی کا سکور",
          },
          allTopics: "سبھی موضوع",
          allGroups: "سبھی گروپس",
          allLanguages: "سبھی زبانیں",
        },
      },
    },
    contentLibrary: {
      header: "مشمولات کی لائبریری",
      grid: {
        questions: "سوالات",
        backgroundInformation: "پس منظر معلومات",
        introductionMaterial: "تعارفی مواد",
        owned: "زیر ملکیت",
        add: "شامل کریں",
        languages: "دستیاب زبانیں",
        topicAdded: "موضوع آپ کی لائبریری میں شامل کر دیا گیا ہے",
      },
    },
    reporting: {
      newReport: "نئی رپورٹ",
      table: {
        actions: {
          delete: "حذف کریں۔",
          run: "رن",
          edit: "ترمیم",
        },
        controls: {
          actions: {
            label: "اعمال",
            delete: "حذف کریں۔",
            run: "رن",
          },
          create: "رپورٹنگ لائن بنائیں",
        },
        columns: {
          name: "نام",
          report: "رپورٹ",
          users: "وصول کنندگان",
          creator: "بنائی گئی",
          frequency: "تعدد",
          enabled: "فعال",
        },
        filters: {
          ownReports: "میری رپورٹس",
        },
      },
      dialogs: {
        delete: {
          header: "رپورٹ کو حذف کریں۔",
          content: "کیا آپ واقعی رپورٹ {0} کو حذف کرنا چاہتے ہیں؟",
        },
        deleteMany: {
          header: "منتخب رپورٹس کو حذف کریں۔",
          content: "کیا آپ واقعی منتخب رپورٹس کو حذف کرنا چاہتے ہیں؟",
        },
      },
      components: {
        allTeams: "سبھی ٹیمیں",
        allTopics: "سبھی موضوعات",
        selected: "منتخب",
        reportFormat: "رپورٹ کا فارمیٹ",
        selectGroups: "گروپس کو منتخب کریں۔",
        selectCompliance: "تعمیل کا انتخاب کریں",
      },
      adoption: "اختیار کاری",
      groupRanking: "گروپ درجہ بندی",
      questionAnalytics: "سوالات کے تجزیے",
      impact: "اثر",
      certificates: "سندات",
      userDump: "صارف ڈَمپ",
      compliance: "تعمیل",
      header: "رپورٹ کرنا",
      selectReportType: "رپورٹ کی قسم منتخب کریں",
      details: {
        reportSettings: "رپورٹ کی ترتیبات",
        recipients: "وصول کنندگان",
      },
      oneTimeReport: "ایک بار کی رپورٹ",
      reoccurringReport: "بار بار آنے والی رپورٹ",
    },
    settings: {
      account: {
        header: "اکاؤنٹ کی معلومات",
        form: {
          basicInformation: "بنیادی معلومات",
          companyName: "کمپنی کا نام",
          street: "گلی",
          postcode: "پوسٹ کوڈ",
          city: "شہر",
          state: "ریاست/صوبہ",
          country: "ملک",
          phone: "فون",
          vat: "ویٹ/جی ایس ٹی نمبر",
          contact: "رابطہ",
          firstName: "نام کا پہلا حصہ",
          lastName: "نام کا آخری حصہ",
          email: "ای میل پتہ",
        },
      },
      general: {
        header: "عمومی ترتیبات",
        form: {
          languageSettings: "زبان کی ترتیبات",
          dashboardLanguage: "ڈیش بورڈ کی زبان",
          appLanguage: "ایپ کی زبان",
          description:
            "زبان کی ترتیبات نئے صارفین پر لاگو ہوتی ہیں اور صارف پروفائل میں اسے اوور رائٹ کیا جا سکتا ہے۔",
          dashboardLanguageDescription:
            "صارف انٹرفیس کی ڈیفالٹ زبان کا تعین کریں۔",
          appLanguageDescription:
            "ایپ کی ڈیفالٹ زبان اور آپ جو ای میلز بھیجتے ہیں ان کا تعین کریں۔",
        },
      },
      whiteLabeling: {
        header: "وائٹ لیبلنگ",
        form: {
          companyLogo: "کمپنی کا لوگو",
          personalizeParagraph:
            "Knowingo کو اپنے لوگو کے ساتھ ذاتی بنائیں۔ لوگو کو ایپ میں مختلف اسکرینوں پر اختیاری پیغام کے ساتھ دکھایا جائے گا۔ رجسٹریشن پیج، ڈیش بورڈ اور مختلف ای میلز پر۔",
          uploadLogoDark: "گہرے پس منظر کے لیے اپنا لوگو اپ لوڈ کریں۔",
          uploadLogoLight: "ہلکے پس منظر کے لیے اپنا لوگو اپ لوڈ کریں۔",
          additionalText: "لوگو کا اضافی متن",
          additionalTextDescription:
            "اضافی لوگو کا متن آپ کے تمام ملازمین کو Knowingo ایپ میں دکھایا جائے گا۔",
          additionalEmailText: "اضافی ای میل متن",
          additionalEmailTextDescription:
            "Knowingo کی طرف سے آپ کے ملازمین کو بھیجے گئے تمام دعوتی اور یاد دہانی ای میلز میں اضافی ای میل متن شامل کیا جائے گا۔",
        },
        preview: {
          appPreview: "ایپ کا پیش نظارہ",
          registrationPreview: "رجسٹریشن کا پیش نظارہ",
          toggle: "لائٹ اور ڈارک موڈ کے پیش نظاروں کو ٹوگل کریں۔",
        },
      },
      webhooks: {
        header: "Webhooks",
        table: {
          controls: {
            create: "إنشاء Webhook جديد",
            shareCode: "رمز مشاركة Webhook",
            actions: {
              label: "الإجراءات",
              delete: "حذف",
            },
            filters: {
              published: "منشور",
              unpublished: "غير منشور",
            },
          },
          dialogs: {
            createConfirmation: {
              successMessage: "تم إنشاء Webhook",
            },
            updateConfirmation: {
              successMessage: "تم تحديث Webhook",
            },
            deleteConfirmation: {
              header: "حذف Webhook",
              content: "هل أنت متأكد أنك تريد حذف هذا Webhook؟",
              successMessage: "تم حذف Webhook.",
            },
            notifyConfirmation: {
              successMessage: "عنوان URL صالح",
              invalidUrl: "عنوان URL غير صالح",
              urlValiderrorMessage: "عنوان URL صالح. طريقة POST غير مسموح بها",
              urlValiderrorNotFound: "عنوان URL صالح. طريقة POST غير موجودة",
            },
          },
          columns: {
            name: "نام",
            event_names: "واقعات کے نام",
            callback_url: "کال بیک یو آر ایل",
            enabled: "فعال",
            num_failures: "# ناکامیاں",
            actions: {
              label: "الإجراءات",
              edit: "تعديل",
              delete: "حذف",
              trigger: "ویب ہک کو ٹرگر کریں",
            },
          },
        },
        details: {
          webhookCreated: "تم إنشاء Webhook",
          webhookUpdated: "تم تحديث Webhook",
          form: {
            name: "نام",
            eventName: "حدث",
            callbackURL: "URL",
            notifyWebhook: "اختبرني!",
            invalidUrl: "عنوان URL غير صالح",
          },
        },
        tooltips: {
          languageList: {
            translations: "ترجمات",
          },
          publishedWebhooks: {
            publishedWebhooks: "Webhooks المنشورة",
            noPublishedWebhooks: "هذه الدورة لا تحتوي على Webhooks المنشورة",
            hasUnpublishedWebhooks:
              " هذه الدورة تحتوي على Webhooks غير المنشورة",
          },
        },
        createWebhook: "إنشاء Webhook",
        editWebhook: "تعديل Webhook",
      },
      api: {
        header: "API ترتیبات",
        form: {
          manageKeys: "API چابیوں کا منظر کار",
          createApiKey: "API چابی بنائیں",
          description: "تفصیل",
          value: "قیمت",
          created: "بنایا گیا",
          lastUsed: "آخری استعمال",
          deleteKey: "API چابی حذف کریں",
          deleteConfirmationPrompt:
            "آپ کوئی API چابی حذف کرنے والے ہیں۔ جو بھی خدمات حال ہیں اس چابی کا استعمال کر رہی ہیں، وہ فوراً رسائی کھو دیں گی۔ براہ کرم ہے یہ یقینی بنائیں کہ آپ نے حذف کے ساتھ آلٹرنیٹیو چابی کے ساتھ تمام متعلقہ خدمات کو اپ ڈیٹ کر لیا ہے۔",
        },
      },
    },
    questions: {
      header: "سوالات",
      topics: "موضوعات",
      questions: "سوالات",
      introductionMaterial: "تعارفی مواد",
      createQuestion: "سوال تخلیق کریں",
      editQuestion: "سوالات کی تدوین کریں",
      modals: {
        copy: {
          header: "سوالات کاپی کریں",
          successMessage: "سوالات کاپی ہو گئے ہیں",
          metaTopicError:
            "اصل موضوع اور مطلوبہ موضوع کی زبان ایک ہی ہونا لازمی ہے",
          save: "نقل کرنا",
          cancel: "منسوخ کریں",
        },
        move: {
          header: "سوالات ہٹا کر دوسری جگہ کریں",
          successMessage: "سوال ہٹا کر دوسری جگہ کر دیا گیا ہے",
          metaTopicError:
            "اصل موضوع اور مطلوبہ موضوع کی زبان ایک ہی ہونا لازمی ہے",
          save: "اقدام",
          cancel: "منسوخ کریں",
        },
        addTags: {
          header: "ٹیگز شامل کریں",
          save: "محفوظ کریں",
          cancel: "منسوخ کریں",
        },
        removeTags: {
          header: "ٹیگز مٹائیں",
          save: "محفوظ کریں",
          cancel: "منسوخ کریں",
        },
        autoTranslate: {
          header: "خود بخود ترجمہ کریں",
          translate: "ترجمہ کریں",
          cancel: "منسوخ کریں",
          warning:
            "اس کارروائی سے منتخب کردہ سوالات کے منتخب کردہ زبانوں میں موجودہ مواد کو برطرف کردیں گی۔",
          generatingTranslations: "ترجمے تیار کر رہے ہیں",
          incompleteTranslations:
            "ترجمہ مکمل ہوگیا ہے، لیکن کچھ مواد کو ایک خرابی کی وجہ سے ترجمہ نہیں کیا گیا۔ براہ کرم اس مواد کو دیکھیں اور دوبارہ کوشش کریں۔",
        },
      },
      table: {
        status: {
          published: "شائع",
          publishedDescription: "یہ سوال شائع ہے اور کھیلنے کے لئے دستیاب ہے",
          unpublished: "غیرشائع",
          unpublishedDescription: "یہ سوال غیرشائع ہے",
          changed: "غیرشائع تبدیلیاں",
          changedDescription: "اس سوال میں غیرشائع تبدیلیاں ہیں",
          incomplete: "نامکمل",
          incompleteDescription:
            "یہ سوال نامکمل ہے اور اسے شائع نہیں کیا جا سکتا ہے",
          backgroundInfo: "پس منظر معلومات",
          backgroundInfoDescription: "اس سوال میں پس منظر معلومات ہیں",
          noBackgroundInfoDescription: "اس سوال میں پس منظر معلومات نہیں ہیں",
        },
        controls: {
          createQuestion: "سوال تخلیق کریں",
          actions: {
            label: "کارروائیاں",
            publish: "شائع کریں",
            unpublish: "غیرشائع کریں",
            copy: "کاپی کریں",
            move: "دوسری جگہ لے جائیں",
            delete: "حذف کریں",
            addTags: "ٹیگز شامل کریں",
            removeTags: "ٹیگز مٹائیں",
            autoTranslate: "خود بخود ترجمہ کریں",
          },
          filters: {
            published: "شائع",
            unpublished: "غیرشائع",
            changed: "غیرشائع تبدیلیاں",
          },
          dialogs: {
            deleteConfirmation: {
              header: "سوالات حذف کریں",
              content: "کیا آپ منتخب سوالات واقعی حذف کرنا چاہتے ہیں؟",
              successMessage: "منتخب سوالات حذف ہو گئے ہیں",
            },
          },
        },
        columns: {
          select: "منتخب کریں",
          thumbnail: "تصویر",
          question: "سوال",
          tags: "ٹیگز",
          status: "حالت",
          comments: "تبصرے",
          backgroundInfo: "پس منظر معلومات",
          actions: {
            edit: "تدوین کریں",
            publish: "شائع کریں",
            publishAll: "سبھی ترجمے شائع کریں",
            unpublish: "غیرشائع کریں",
            unpublishAll: "سبھی ترجمے غیرشائع کریں",
            delete: "حذف کریں",
          },
          dialogs: {
            deleteConfirmation: {
              header: "سوال حذف کریں",
              content: 'کیا آپ "{0}" سوال واقعی حذف کرنا چاہتے ہیں',
              successMessage: "سوال حذف ہو گیا ہے",
            },
          },
        },
      },
      details: {
        components: {
          comments: {
            deletedMessage: "تبصرہ حذف ہو گیا ہے",
            savedMessage: "تبصرہ محفوظ ہو گیا ہے",
            delete: "حذف کریں",
            yourComment: "آپ کا تبصرہ",
            addComment: "تبصرہ شامل کریں",
          },
        },
        form: {
          useBaseImage: "بیس میڈیا کا استعمال کریں۔",
          question: "سوال",
          answers: "جوابات",
          tags: "ٹیگز",
          backgroundInformation: "پس منظر معلومات",
          enableBackgroundInformation: "پس منظر معلومات فعال کریں",
          majorChange: "بڑی تبدیلی",
          majorChangeDescription:
            "اگر آپ کا ماننا ہے کہ اس سوال میں آپ کے ذریعہ کی گئی تبدیلیاں اتنی اہم ہیں کہ اس سوال سے وابستہ اعداد و شمار کو پھر سے ترتیب دینا چاہئے تو ٹوگل کریں اور اس سوال کو پھر سے شائع کریں۔ اس سے ان صارفین کو بھی نئی تبدیلیاں معلوم ہوں گی جنہوں نے اس سوال میں پہلے ہی مہارت حاصل کر لی ہے۔",
          lastUpdate: "آخری اپڈیٹ",
          title: "تعارف",
          description: "تفصیلات",
          comments: "تبصرے",
          preview: "پیش نظارہ",
          createdMessage: "سوال تخلیق ہو گیا ہے",
          updatedMessage: "سوال اپڈیٹ ہو گیا ہے",
          commentsUnavailable:
            "مشترکہ یا مقفل عنوانات میں تبصرے کا سیکشن دستیاب نہیں ہے۔",
        },
        errors: {
          itemChanged:
            "تدوین کرتے وقت آئٹم میں ایک سرور پر تبدیلی آ گئی۔ صفحہ ریفریش کریں اور تبدیلیاں دوبارہ جمع کریں۔",
          problemsOccurred: "درج ذیل ترجموں کو محفوظ کرتے وقت مسائل ہو گئے:",
        },
      },
      messages: {
        questionPublished: "سوال منظر عام ہوگیا ہے",
        questionsPublished: "منتخب سوالات منظر عام ہوگئے ہیں",
        questionUnpublished: "سوال منظر عام نہیں ہوا ہے",
        questionsUnpublished: "منتخب سوالات منظر عام نہیں ہوئے",
        incompleteQuestionsNotPublished:
          "{0} نامکمل سوالات منظر عام نہیں کیے جا سکے",
        incompleteQuestionNotPublished:
          "{0} نامکمل سوال منظر عام نہیں کیا جا سکا",
      },
    },
    introduction: {
      header: "تعارف",
      topics: "موضوعات",
      questions: "سوالات",
      introductionMaterial: "تعارفی مواد",
      createIntroduction: "تعارف تخلیق کریں",
      editIntroduction: "تعارف میں تدوین کریں",
      table: {
        status: {
          published: "شائع",
          publishedDescription: "تعارف شائع ہے اور کھیلنے کے لئے دستیاب ہے",
          unpublished: "غیرشائع",
          unpublishedDescription: "تعارف غیرشائع ہے",
          changed: "بدلاہوا",
          changedDescription: "تعارف میں غیرشائع تبدیلیاں ہیں",
          incomplete: "نامکمل",
          incompleteDescription: "تعارف نامکمل ہے اور شائع نہیں کیا جا سکتا ہے",
        },
        controls: {
          createIntroduction: "تعارف تخلیق کریں",
          actions: {
            label: "کارروائیاں",
            publish: "شائع کریں",
            unpublish: "غیرشائع کریں",
            delete: "حذف کریں",
            autoTranslate: "خود بخود ترجمہ کریں",
          },
          dialogs: {
            deleteConfirmation: {
              header: "تعارف حذف کریں",
              content: "کیا آپ منتخب تعارفی مواد واقعی حذف کرنا چاہتے ہیں؟",
              successMessage: "تعارفی مواد حذف ہو گیا ہے",
            },
          },
          messages: {
            selectedPublished: "منتخب تعارفی مواد شائع ہو گیا ہے",
            selectedUnpublished: "منتخب تعارفی مواد غیرشائع ہو گیا ہے",
          },
        },
        messages: {
          published: "تعارفی مواد شائع ہو گیا ہے",
          unpublished: "تعارفی مواد غیرشائع ہو گیا ہے",
        },
        published: "شائع",
        changed: "تبدیل کردہ",
        unpublished: "غیرشائع",
        incomplete: "نامکمل",
        columns: {
          select: "منتخب کریں",
          title: "تعارف",
          thumbnail: "تصویر",
          status: "حالت",
          actions: {
            edit: "تدوین کریں",
            moveUp: "اوپر جائیں",
            setIndex: "اشاریہ ترتیب دیں",
            moveDown: "نیچے جائیں",
            publish: "شائع کریں",
            unpublish: "غیرشائع کریں",
            publishAll: "سبھی ترجمے شائع کریں",
            unpublishAll: "سبھی ترجمے غیرشائع کریں",
            delete: "حذف کریں",
          },
          dialogs: {
            replaceIndex: {
              header: "اشاریہ بدلیں",
              save: "محفوظ کریں",
              successMessage: "تعارفی اشاریہ کو بدل دیا گیا ہے",
            },
            deleteConfirmation: {
              header: "تعارف حذف کریں",
              content: 'کیا آپ تعارف "{0}" واقعی حذف کرنا چاہتے ہیں',
              successMessage: "تعارفی مواد حذف ہو گیا ہے",
            },
          },
        },
      },
      details: {
        introduction: "تعارف",
        text: "عبارت",
        form: {
          useBaseImage: "بیس میڈیا کا استعمال کریں۔",
        },
        createdMessage: "تعارفی آئٹم تخلیق ہو گیا ہے",
        updatedMessage: "تعارفی آئٹم اپڈیٹ ہو گیا ہے",
        errors: {
          itemChanged:
            "تدوین کرتے وقت ایک سرور پر آئٹم بدل گیا۔ صفحہ ریفریش کریں اور تبدیلیاں پھرسے جمع کریں۔",
          problemsOccurred: "درج ذیل ترجموں کو محفوظ کرتے وقت مسائل ہو گئے:",
        },
      },
      modals: {
        autoTranslate: {
          header: "خود بخود ترجمہ کریں",
          translate: "ترجمہ کریں",
          cancel: "منسوخ کریں",
          warning:
            "اس کارروائی سے منتخب کردہ تعارفی اشیاء کے منتخب کردہ زبانوں میں موجودہ مواد کو برطرف کردیں گی۔",
          generatingTranslations: "ترجمے تیار کر رہے ہیں",
          incompleteTranslations:
            "ترجمہ مکمل ہوگیا ہے، لیکن کچھ مواد کو ایک خرابی کی وجہ سے ترجمہ نہیں کیا گیا۔ براہ کرم اس مواد کو دیکھیں اور دوبارہ کوشش کریں۔",
        },
      },
      messages: {
        introductionItemPublished: "معرفی آئٹم منظر عام ہوگیا ہے",
        introductionItemsPublished: "منتخب معرفی آئٹمز منظر عام ہوگئے ہیں",
        introductionItemUnpublished: "منتخب معرفی آئٹم منظر عام نہیں ہوا ہے",
        introductionItemsUnpublished: "منتخب معرفی آئٹمز منظر عام نہیں ہوئے",
        incompleteIntroductionItemsNotPublished:
          "{0} نامکمل معرفی آئٹمز منظر عام نہیں کیے جا سکے",
        incompleteIntroductionItemNotPublished:
          "{0} نامکمل معرفی آئٹم منظر عام نہیں کیا جا سکا",
      },
    },
    certification: {
      header: "تصدیق",
      table: {
        filters: {
          certified: "تصدیق شدہ",
          onTrack: "راہ پر",
          uncertified: "راہ پر",
          expired: "میعاد ختم",
          missed: "زائد المیعاد",
          mandatory: "لازمی",
          optional: "تجویز کردہ",
        },
        columns: {
          firstName: "پہلا نام",
          lastName: "آخری نام",
          email: "ای میل",
          status: "سرٹیفکیٹ کی حیثیت",
          topic: "موضوع",
          isMandatory: "لازمی",
          deadline: "ڈیڈ لائن",
          certifiedAt: "حاصل کردہ تاریخ",
          expiresAt: "درست",
        },
      },
    },
    externalTraining: {
      header: "بیرونی تربیت",
      table: {
        controls: {
          actions: {
            label: "اعمال",
            delete: "حذف کریں۔",
            edit: "ترمیم",
          },
          create: "تربیت بنائیں",
        },
        columns: {
          name: "بیرونی تربیت کا نام",
          num_users: "#صارفین",
          num_invited: "# صارفین کو مدعو کیا گیا۔",
          num_completed: "# صارفین مکمل ہو گئے۔",
          tags: "ٹیگز",
        },
      },
      details: {
        createTraining: "تربیت بنائیں",
        editTraining: "تربیت میں ترمیم کریں۔",
        form: {
          group: "گروپ",
          users: "صارفین",
          noGroupsSelected: "کوئی گروپ منتخب نہیں کیا گیا۔",
          inputs: {
            name: {
              label: "تربیت کا نام",
            },
            groups: {
              placeholder: "گروپ شامل کریں۔",
            },
          },
        },
      },
      dialogs: {
        deleteConfirmation: {
          header: "تربیت کو حذف کریں۔",
          content: "کیا آپ واقعی تربیت {0} کو حذف کرنا چاہتے ہیں؟",
        },
        deleteManyConfirmation: {
          header: "تربیت کو حذف کریں۔",
          content: "کیا آپ واقعی منتخب تربیت کو حذف کرنا چاہتے ہیں؟",
          dataLostWarning:
            "یہ عمل مستقل ہے۔ منتخب تربیت سے متعلق تمام ڈیٹا ضائع ہو جائے گا۔",
        },
      },
      users: {
        externalTraining: "بیرونی تربیت",
        table: {
          controls: {
            actions: {
              label: "اعمال",
              invite: "دعوت دیں۔",
              setStatus: "حیثیت تبدیل کریں۔",
            },
            filters: {
              readyForInvite: "دعوت کے لیے تیار ہیں۔",
              invited: "مدعو کیا۔",
              completed: "مکمل",
              incomplete: "نامکمل",
            },
            downloadList: "فہرست ڈاؤن لوڈ کریں۔",
          },
          columns: {
            email: "ای میل",
            start: "کے لیے مدعو کیا گیا۔",
            location: "مقام",
            status: "حالت",
            tags: "ٹیگز",
          },
        },
        modals: {
          update: {
            header: "اپ ڈیٹ منتخب کیا گیا۔",
            selectStatus: "حیثیت منتخب کریں۔",
          },
        },
        invite: {
          header: "صارفین کو مدعو کریں۔",
          form: {
            createNewEvent: "نیا ایونٹ بنائیں",
            selectExistingEvent: "موجودہ ایونٹ کو منتخب کریں۔",
            selectEvent: "ایونٹ منتخب کریں۔",
            event: "تقریب",
            invite: "دعوت دیں۔",
            startDate: "شروع کرنے کی تاریخ",
            selectStartDate: "آغاز کی تاریخ منتخب کریں۔",
            endDate: "آخری تاریخ",
            selectEndDate: "اختتامی تاریخ منتخب کریں۔",
            location: "مقام",
            enableCustomInvitationMessage: "حسب ضرورت دعوتی پیغام",
            customInvitationMessage: "دعوتی ای میل کے لیے حسب ضرورت پیغام",
            enableCustomCompletionMessage: "حسب ضرورت تکمیل کا پیغام",
            customCompletionMessage: "تکمیلی ای میل کے لیے حسب ضرورت پیغام",
            errors: {
              startInPast: "تاریخ آغاز ماضی میں سیٹ نہیں کی جا سکتی",
              endBeforeStart:
                "اختتامی تاریخ شروع ہونے کی تاریخ سے پہلے مقرر نہیں کی جا سکتی",
            },
          },
        },
      },
    },
    marketplace: {
      header: "مارکیٹ",
      bundle: {
        lastPublished: "آخری ترتیب",
        availableIn: "دستیاب ہے",
        tags: "ٹیگز",
        getQuote: "قیمت حاصل کریں",
        bundleIncludes: "اس بنڈل میں شامل ہیں",
        numCourses: "{0} کورسز",
        numTopics: "کل {0} موضوعات",
        numBackgroundInformation: "{0} پس منظر کی معلومات",
        numIntroductionMaterial: "{0} تعارفی مواد",
        numQuestions: "{0} سوالات",
        continuousSupport: "مسلسل Knowingo حمایت",
        certifiedContent: "Knowingo سندہی مواد",
        openBundle: "بنڈل کھولیں",
        owned: "ملک",
        topics: "موضوعات",
        courses: "کورسز",
        quoteModal: {
          header: "ایک قیمت کا تصدیق کریں",
          content:
            "اسے جمع کرنے سے یہ ہوگا کہ کنونگو سیلز ٹیم کو ایک قیمت فراہم کرنے کی خبر ہوگی۔ وہ آپ سے رابطہ کریں گے تاکہ اس مواد تک رسائی کے شرائط قائم کی جا سکیں۔",
          confirmation: "قیمت کی درخواست بھیج دی گئی ہے",
        },
      },
      components: {
        tagFilter: {
          tags: "ٹیگز",
        },
        languageFilter: {
          languages: "زبانیں",
        },
        contentFilter: {
          generalContent: "عام مواد",
          privateContent: "خصوصی مواد",
        },
        ownedFilter: {
          bundles: "بنڈلز",
          all: "تمام بنڈلز",
          owned: "صرف ملکیت والے بنڈلز",
          not_owned: "صرف غیر ملکیت والے بنڈلز",
        },
      },
    },
    messages: {
      header: "پیغامات",
      table: {
        controls: {
          createMessage: "پیغام بنائیں",
        },
        columns: {
          select: "منتخب کریں",
          subject: "موضوع",
          status: "حالت",
          recipients: "وصول کنندگان",
        },
        scheduledFor: "مقرر کیا گیا ہے",
        processing: "پروسیسنگ",
        sentAt: "بھیجا گیا ہے",
        more: "+{0} مزید",
      },
      details: {
        editMessage: "پیغام میں ترمیم",
        createMessage: "پیغام بنائیں",
        now: "اب",
        scheduleFor: "کیلئے شیڈول",
        notifications: {
          messageSent: "پیغام محفوظ کر دیا گیا ہے اور جلد ہی بھیجا جائے گا۔",
          messageScheduled: "پیغام محفوظ کر دیا گیا ہے اور بھیجا جائے گا: {0}",
        },
        messageSubject: "پیغام کا موضوع",
        subject: "موضوع",
        messageBody: "پیغام کا متن",
        body: "باڈی",
        recipientGroups: "وصول کنندہ گروہ",
        send: "بھیجیں",
        scheduledFor: "مقرر کیا گیا ہے",
        language: "زبان",
        languageDescription:
          "پیغام کی زبان کلائنٹ ایپ میں متن کی ترتیب کا تعین کرنے کے لئے استعمال ہوتی ہے۔ زبان منتخب کردہ وصول کنندگان کو متاثر نہیں کرتی۔",
        readonlyWarning:
          "پیغامات جو پہلے ہی بھیجے جا چکے ہیں، اب تبدیل نہیں کیے جا سکتے۔",
      },
      dialogs: {
        deleteMessage: {
          header: "پیغام حذف کریں",
          content:
            "کیا آپ واقعی منتخب کردہ پیغام {0} کو حذف کرنا چاہتے ہیں؟ حذف کرنے کے بعد پیغام ایپ کے ان باکس میں دکھایا نہیں جائے گا۔",
        },
        deleteMessages: {
          header: "منتخب کردہ پیغامات حذف کریں",
          content:
            "کیا آپ واقعی منتخب کردہ پیغامات کو حذف کرنا چاہتے ہیں؟ حذف کرنے کے بعد پیغامات ایپ کے ان باکس میں دکھایا نہیں جائے گا۔",
        },
      },
      notifications: {
        messageDeleted: "پیغام حذف کر دیا گیا ہے",
        messagesDeleted: "منتخب کردہ پیغامات حذف کر دی گئی ہیں",
      },
    },
  },
  components: {
    header: {
      change: "بدلیں",
      tryBetaDashboard: "ہمارا نیا بیٹا ڈیش بورڈ آزمائیں",
    },
    trainingStatus: {
      notReady: "ابھی تک دعوت کے لیے تیار نہیں۔",
      ready: "دعوت کے لیے تیار ہیں۔",
      invited: "مدعو کیا۔",
      completed: "مکمل",
      incomplete: "نامکمل",
    },
    select: {
      report: {
        reportType: "رپورٹ کی قسم",
        adoption: {
          name: "گود لینا",
          description:
            "گود لینے کی رپورٹ آپ کو ظاہر کرتی ہے کہ آپ کی آبادی کو رجسٹر کرنے اور کھیلنے کے حوالے سے ریاست کی کیا حیثیت ہے۔ اس سے آپ کو صارفین کے مختلف گروپس کو نشانہ بنانے میں مدد ملے گی اور آپ کے گود لینے کے عمل کو کام کرنے کی وجہ سے بصیرت ملے گی۔ اس رپورٹ میں موجود گروپس کو صارفین کی ایک فہرست بنانے کے لیے ملایا گیا ہے۔",
        },
        group_ranking: {
          name: "گروپ کی درجہ بندی",
          description:
            "گروپ رپورٹ آپ کو ان لوگوں کی شناخت میں مدد کرتی ہے جنہوں نے ہر گروپ کے لیے اس پچھلے مہینے سب سے زیادہ سیکھا ہے۔ اگر کوئی انعام واجب الادا ہے، تو اس طرح آپ یہ معلوم کر سکتے ہیں کہ کون اپنی پوری کوشش کر رہا ہے۔",
        },
        question_analytics: {
          name: "سوالات کے تجزیات",
          description:
            "سوال کے تجزیات کی رپورٹ ہر سوال کے لیے تمام میٹرکس دکھاتی ہے۔ اس سے آپ کو یہ تعین کرنے میں مدد ملے گی کہ کس قسم کے سوالات اچھے کام کرتے ہیں اور کس قسم کے سوالات سے سیکھنا مشکل ہے۔",
        },
        impact: {
          name: "کے اثرات",
          description:
            "Knowingo امپیکٹ رپورٹ اس بات پر روشنی ڈالتی ہے کہ Knowingo نے آپ کے صارفین پر کس قسم کے اثرات مرتب کیے ہیں۔ آپ اپنی آبادی کے علم میں اضافہ دیکھ سکیں گے۔ یہ اضافہ ابتدائی علم، موجودہ علم اور ممکنہ علم کے ذریعے دکھایا گیا ہے۔",
        },
        certificates: {
          name: "سرٹیفکیٹس",
          description:
            "سرٹیفیکیشن رپورٹ صارفین کے سرٹیفکیٹس کے حوالے سے ان کی حیثیت کو ظاہر کرتی ہے۔ آپ اس بات کا تعین کر سکیں گے کہ آپ کی آبادی کی موجودہ حالت ان کی ترقی سے کیا ہے۔",
        },
        user_dump: {
          name: "یوزر ڈمپ",
          description:
            "رپورٹ میں صارف کے سرٹیفیکیشن، علم اور گروپس کے بارے میں مختلف ڈیٹا شامل ہے۔",
        },
        group_certificates: {
          name: "گروپ سرٹیفکیٹس",
          description:
            "تصدیق رپورٹ صارفین کے ان کی حالت کو دکھاتی ہے جو ان کے سرٹیفکیٹس کے حوالے سے ہوتی ہے۔ آپ ان کی پیش رفت کے ساتھ اپنی آبادی کی موجودہ حالت کو معین کر سکیں گے۔",
        },
        certificates_postnl: {
          name: "سرٹیفکیٹس (پوسٹ این ایل)",
          description:
            "تصدیق رپورٹ صارفین کے اہلیت کی حالت دکھاتی ہے۔ آپ اپنے عوام کی موجودہ حالت کو ان کے پیش رفت کے ساتھ معلوم کر سکتے ہیں۔",
        },
        compliance: {
          name: "تعمیل",
          description:
            "تعمیل کی رپورٹ ان صارفین سے جمع کردہ ڈیٹا کو ظاہر کرتی ہے جنہیں تعمیل کے موضوعات تفویض کیے گئے ہیں۔ اس سے آپ کو اپنی آبادی کی تعمیل کی سطحوں کا اندازہ لگانے میں مدد ملے گی۔",
        },
      },
      group: {
        selectGroup: "گروپ منتخب کریں",
        loading: "لوڈ ہو رہا ہے۔",
        noResults: "کے لیے تلاش کا کوئی نتیجہ نہیں ہے۔ {0}",
      },
      tags: {
        selectTags: "ٹیگز منتخب کریں",
        successMessage: "ٹیگ {0} تخلیق کر دیا گیا ہے",
        createOption: "ٹیگ تخلیق کریں",
      },
      company: {
        selectCompany: "کمپنی منتخب کریں",
      },
      language: {
        selectLanguage: "زبان منتخب کریں۔",
        en: "انگریزی",
        nl: "ڈچ",
        de: "جرمن",
        fr: "فرانسیسی",
        ru: "روسی",
        ar: "عربی",
        pt: "پرتگالی",
        es: "ہسپانوی",
        cs: "چیک",
        he: "عبرانی",
        ur: "اردو",
        th: "تھائی",
        zh: "چینی",
        hi: "ہندی",
        it: "اطالوی",
        pl: "پولش",
      },
      topic: {
        selectTopic: "موضوع منتخب کریں",
        selectTopics: "موضوعات منتخب کریں",
      },
      content: {
        placeholder: "مواد منتخب کریں۔",
        course: "کورس",
        topic: "موضوع",
        publishedQuestions: "شائع شدہ سوالات",
      },
      compliance: {
        selectCompliance: "تعمیل کا انتخاب کریں",
      },
      user: {
        selectUser: "صارف منتخب کریں",
        notConfirmed: "تصدیق نہیں",
      },
      webhook: {
        placeholder: "اختر الحدث",
      },
      timezone: {
        timezone: "ٹائم زون",
      },
      leaders: {
        groupLeaders: "گروپ مینیجر",
        selectGroupManager: "گروپ مینیجر منتخب کریں",
      },
      role: {
        label: "کردار",
        placeholder: "کردار منتخب کریں",
        userRoles: "صارف کے کردار",
        whatRoles: "کردار کیا ہیں؟",
        user: {
          header: "صارف",
          description:
            "موبائل اور ویب ایپلیکیشن (Knowingo App) تک مکمل رسائی اور ڈیش بورڈ تک رسائی نہیں۔",
        },
        moderator: {
          header: "مینیجر",
          description:
            "Knowingo ایپ اور ڈیش بورڈ تک مکمل رسائی۔ مینیجر کو مواد تخلیق کنندہ اور صارف مینیجر کے کرداروں کی مشترکہ اجازتیں اور رسائی کے حقوق حاصل ہوتے ہیں، ڈیش بورڈ کے تمام صفحات تک رسائی اور ڈیش بورڈ پر کسی بھی معلومات یا مواد میں ترمیم کرنے کی صلاحیت کے ساتھ۔ مینیجر دوسرے مینیجرز کو پلیٹ فارم سے ایڈٹ، تخلیق یا ہٹا سکتے ہیں۔",
        },
        user_manager: {
          header: "صارف مینیجر",
          description:
            "Knowingo ایپ تک مکمل رسائی اور ڈیش بورڈ تک جزوی رسائی۔ مینیجرز لرننگ کے اعداد و شمار دیکھ سکتے ہیں، اور صارفین، گروپس اور ان کے لرننگ پلانز کا انتظام کر سکتے ہیں لیکن مواد بنانے یا ترمیم کرنے کے قابل نہیں ہوتے ہیں۔",
        },
        group_manager: {
          header: "گروپ مینیجر",
          description:
            "Knowingo ایپ تک مکمل رسائی اور ڈیش بورڈ تک جزوی رسائی۔ گروپ مینیجر لرننگ کے اعداد و شمار دیکھ سکتے ہیں اور تفویض کردہ گروپوں، لرننگ پلانز اور ان کے صارفین کا انتظام کر سکتے ہیں۔ گروپ مینیجرز اپنے گروپوں کے لیے رپورٹس تیار کر سکتے ہیں اور تمام مواد تک صرف پڑھنے کے قابل رسائی رکھتے ہیں۔ منظم گروپوں کو گروپس کے صفحہ پر صارف کو مدعو کرنے کے بعد تفویض کیا جا سکتا ہے۔",
        },
        content_creator: {
          header: "مواد تخلیق کرنے والا",
          description:
            "Knowingo ایپ تک مکمل رسائی اور ڈیش بورڈ تک جزوی رسائی۔ مواد تخلیق کنندگان موضوعات کا مواد تخلیق اور ترمیم کر سکتے ہیں اور سوال کی سطح پر تجزیہ تک رسائی رکھتے ہیں لیکن صارفین اور لرننگ پلانز میں کوئی تبدیلی کرنے کے قابل نہیں ہیں۔",
        },
      },
    },
    clipboard: {
      successMessage: "کلپ بورد پر کاپی ہو گیا",
      errorMessage: "کاپی نہیں کر سکا۔ برائے مہربانی خود سے کاپی کریں",
    },
    spanDropdown: {
      day: "دن",
      week: "ہفتے",
      month: "مہینے",
      quarter: "سہ ماہیاں",
      year: "سال",
    },
    comments: {
      deletedMessage: "تبصرہ حذف ہو گیا ہے",
      savedMessage: "تبصرہ محفوظ ہو گیا ہے",
      delete: "حذف کریں",
      yourComment: "آپ کا تبصرہ",
      addComment: "تبصرہ شامل کریں",
    },
    sidebar: {
      avatar: {
        alt: "صارف کا اوتار",
        roles: {
          admin: "ایڈمن",
          moderator: "موڈریٹر",
        },
      },
      items: {
        dashboard: "ڈیش بورڈ",
        users: "صارفین",
        groups: "گروپس",
        topics: "موضوعات",
        courses: "کورسز",
        certification: "تصدیق",
        accountInfo: "اکاؤنٹ کی معلومات",
        api: "API",
        companySettings: "ترتیبات",
        whiteLabeling: "وائٹ لیبلنگ",
        webHooks: "خطافات الويب",
        documentation: "دستاویز سازی",
        moderatorCompanies: "کمپنیاں",
        contentSharing: "مشمولات کا اشتراک",
        questionAnalytics: "سوال کے تجزیے",
        feedback: "تاثر",
        shareOverview: "اشتراک کا خلاصہ",
        myProfile: "میری پروفائل",
        signupURLs: "سائن اپ یو آر ایلز",
        logout: "لاگ آؤٹ کریں",
        newTag: "نیا!",
        proTag: "پرو",
        shareLink: "شیئرلنک",
        library: "لائبریری",
        disabledItemNotice:
          "کمپنی موڈریٹر کو یہ آئٹم نظر نہیں آ سکتا ہے کیونکہ فیچر غیرفعال ہے",
        contentLibrary: "مشمولات کی لائبریری",
        reporting: "رپورٹ کرنا",
        activityLog: "سرگرمی کا اندراج",
        enterpriseStats: "انٹرپرائز کے اعدادوشمار",
        externalTraining: "بیرونی تربیت",
        marketplace: "مارکیٹ",
        messages: "پیغامات",
      },
    },
    table: {
      controls: {
        filterDropdown: {
          filterBy: "فلٹر کرنے کا معیار",
          clearFilters: "فلٹرز ہٹائیں",
          filters: {
            sent: "بھیجی گئی",
            scheduled: "شیڈول شدہ",
          },
        },
        actionsDropdown: {
          label: "کارروائیاں",
          delete: "منتخب حذف کریں",
        },
        searchBox: {
          search: "تلاش کریں",
        },
      },
      actions: {
        delete: "حذف کریں",
      },
      noSearchResults: "اس کا کوئی تلاش نتیجہ نہیں: {0}",
      noData: "کوئی ڈیٹا نہیں",
      moreItems:
        "{0} مزید آئٹمز۔ تمام آئٹمز کو نئے ٹیب میں کھولنے کے لیے یہاں کلک کریں۔",
    },
    charts: {
      week: "ہفتہ",
      quarter: "سہ ماہی",
    },
    submissionConfirmation: {
      successMessage: "محفوظ !",
    },
    statusIcon: {
      published: "شائع",
      unpublished: "غیرشائع",
      changed: "بدل گیا",
      incomplete: "نامکمل مسودہ",
    },
    cropper: {
      errors: {
        unsupportedFileType: "غیر سپورٹ کردہ فائل کی قسم",
      },
      buttons: {
        upload: "اپ لوڈ کریں۔",
        delete: "حذف کریں۔",
        confirm: "تصدیق کریں۔",
      },
    },
    form: {
      buttons: {
        save: "محفوظ کریں",
        loading: "لوڈ ہو رہا ہے۔",
        actionAndClose: "{0} اور بند کریں۔",
      },
      fields: {
        repeatSelect: {
          every: "ہر",
          days: "دن",
          weeks: "ہفتے",
          months: "مہینے",
          daily: "یومیہ",
          weekly: "ہفتہ واری",
          monthly: "ماہانہ",
          custom: "حسب ضرورت",
        },
        weekdaySelect: {
          mon: "پیر",
          tue: "منگل",
          wed: "بدھ",
          thu: "جمعرات",
          fri: "جمعہ",
          sat: "سنیچر",
          sun: "اتوار",
        },
      },
      validators: {
        required: "یہ خانہ ضروری ہے",
        invalidUrl: "غلط URL",
      },
    },
    richTextEditor: {
      errors: {
        textTooLong: "داخل کردہ عبارت بہت لمبی ہے",
      },
      toolbox: {
        bold: "بولڈ",
        italic: "ترچھا",
        underline: "انڈر لائن",
        strikethrough: "سٹرائیک تھرو",
        superscript: "سپر اسکرپٹ",
        subscript: "سبسکرپٹ",
        link: "لنک",
        fontSize: "حرف کا سائز",
        fontColor: "لکھائی کا رنگ",
        highlightColor: "رنگ کو نمایاں کریں۔",
        clearFormatting: "فارمیٹنگ صاف کریں۔",
      },
    },
    downloader: {
      title: "ڈاؤن لوڈ کریں",
      generatingFile:
        "درخواست کردہ فائل بن رہی ہے۔ برائے مہربانی انتظار کریں۔۔۔",
      fileReady:
        "آپ کی فائل تیار ہے! اسے اپنے کمپیوٹر پر محفوظ کرنے کے لئے نیچے کسی بٹن پر کلک کریں۔",
      loading: "لوڈ ہو رہا ہے",
      download: "ڈاؤن لوڈ کریں",
      close: "بند کریں",
    },
    confirm: {
      confirm: "توثیق کریں",
      cancel: "منسوخ کریں",
    },
    certification: {
      unknown: "نامعلوم",
      certified: "تصدیق شدہ",
      on_track: "راہ پر",
      expiring: "میعاد ختم ہو رہی ہے۔",
      expired: "میعاد ختم",
      overdue: "آخری تاریخ چھوٹ گئی۔",
      uncertified: "غیر مصدقہ",
    },
    mediaComponent: {
      type: "قسم",
      text: "متن",
      image: "تصویر",
      video: "تصویر",
      videoUrl: "ویڈیو یو آر ایل",
      thumbnail: "تھمب نیل",
      videoPreview: "ویڈیو پیش نظارہ",
      videoWarning: "ویڈیو لوڈ کرنے سے قاصر",
    },
    topicInfo: {
      assignedGroups: "مخصوص گروہ",
      assignedCourses: "مخصوص کورسز",
      noAssignedGroups: "کوئی مخصوص گروہ نہیں",
      noAssignedCourses: "کوئی مخصوص کورس نہیں",
      topicOwner: "موضوع کا مالک",
    },
    datePicker: {
      hour: "گھنٹہ",
      minutes: "منٹ",
      date: "تاریخ",
    },
    input: {
      originalText: "اصل متن",
      errorWhileTranslating:
        "مواد کا ترجمہ کرتے وقت ایک خرابی پیش آئی۔ براہ کرم بعد میں دوبارہ کوشش کریں۔",
    },
  },

  inputs: {
    startDate: {
      label: "شروع کرنے کی تاریخ",
    },
    endDate: {
      label: "آخری تاریخ",
    },
    frequency: {
      label: "تعدد",
    },
    weekday: {
      label: "ہفتے کا دن",
    },
    includeDisabledUsers: {
      label: "غیر فعال صارفین کو شامل کریں۔",
    },
    includeMetaFields: {
      label: "میٹا فیلڈز شامل کریں۔",
    },
    ignoreMinAnswers: {
      label: "میٹرکس کے بغیر سوالات شامل کریں",
      warning:
        "کچھ میٹرکس جات جو سوال تجزیہ سے متعلق ہیں ممکن ہے کہ کھلاڑیوں کو کم دیکھ پڑنے کی وجہ سے قابل اعتماد نہ ہوں۔",
    },
    name: {
      label: "نام",
    },
    repeatSelect: {
      daily: "روزانہ",
      weekly: "ہفتہ وار",
      monthly: "ماہانہ",
    },
    enabled: {
      label: "فعال",
      description:
        "آپ منتخب وصول کنندگان کو ای میلز بھیجنا معطل کرنے کے لیے اس فیلڈ کو غیر چیک کر سکتے ہیں۔",
    },
    monthSelect: {
      label: "مہینہ منتخب کریں۔",
    },
    filters: {
      name: "نام",
      created: "بنایا گیا",
      updated: "اپ ڈیٹ کیا گیا",
      published: "شائع ہوا",
      num_questions: "# سوالات",
      num_topics: "# موضوعات",
      num_courses: "# کورسز",
      num_tips: "# ٹپس",
      num_theories: "# تعارف",
    },
    noFiltersAvailable: "کوئی فلٹر دستیاب نہیں ہے",
    url: {
      placeholder: "یو آر ایل درج کریں",
    },
  },

  errors: {
    resourceUpdated:
      "کارروائی انجام دیتے وقت ماخذ اپڈیٹ ہو گیا ہے۔ صفحہ کو ریفریش کریں اور پھر سے کوشش کریں۔",
    topicIsShared:
      "اشتراک کیا گیا موضوع حذف نہیں کر سکتے۔ موجودہ سبھی اشتراکات کو منسوخ کریں اور پھر سے کوشش کریں۔",
    tryAgain: "پھر سے کوشش کریں",
  },
  errorBoundary: {
    updateAvailableHeader: "اپڈیٹ دستیاب",
    updateAvailableContent:
      "نوونگو ڈیش بورڈ کا نیا ورژن دستیاب ہے۔ صفحہ کو پھر سے لوڈ کرنے کے لئے نیچے بٹن پر کلک کریں۔",
    reloadButton: "پھر سے لوڈ کریں",
    somethingWentWrongHeader: "افوہ۔۔۔ کچھ گڑبڑ ہوگیا",
    somethingWentWrongContent:
      "ایسا معلوم ہوتا ہے کہ ہمیں کوئی اندرونی مسئلہ درپیش ہے۔ ہماری ٹیم کو اطلاع دے دی گئی ہے۔",
    pleaseReload:
      "برائے مہربانی صفحہ پھر سے لوڈ کریں اور دوبارہ کوشش کریں۔ اگر مسئلہ برقرار رہتا ہے تو برائے مہربانی {0} پر سپورٹ سے رابطہ کریں",
    eventIdentifier: "وقوعہ کا شناخت کنندہ",
  },
  form: {
    errors: {
      start_in_past: "تاریخ آغاز کو ماضی میں سیٹ نہیں کیا جا سکتا",
      end_before_start:
        "اختتامی تاریخ کو آغاز کی تاریخ سے پہلے مقرر نہیں کیا جا سکتا",
      "Missing data for required field.": "مطلوبہ فیلڈ کے لیے ڈیٹا غائب ہے۔",
      "Length must be between 1 and 35.":
        "لمبائی 1 اور 35 کے درمیان ہونی چاہیے۔",
      "Length must be between 3 and 120.":
        "لمبائی 3 اور 120 کے درمیان ہونی چاہیے۔",
      unknownError: "نامعلوم خرابی",
      "Not a valid URL.": "صحیح یو آر ایل نہیں ہے۔",
      "Invalid domain": "غیر موزوں ای میل ڈومین۔",
    },
  },
  loginPage: {
    htmlTitle: "نوونگو | لاگ ان",
    header: "نوونگو{0} میں سائن ان کریں",
    emailAddress: "ای میل پتہ",
    password: "پاس ورڈ",
    rememberMe: "مجھے یاد رکھیں",
    loginBtn: "سائن ان کریں",
    loginWithSSOBtn: "ایس ایس او استعمال کر کے سائن ان کریں",
    forgotPassword: "پاس ورڈ بھول گئے؟",
    goBack: "واپس جائیں",
    here: "یہاں",
    inputs: {
      domain: {
        placeholder: "ڈومین",
      },
    },
    errors: {
      invalidCredentials: "فراہم کردہ ای میل پتہ یا پاس ورڈ غیر درست ہے۔",
      disabledAccount:
        "آپ کا اکاؤنٹ غیرفعال ہے۔ مزید معلومات کے لئے support@knowingo.com سے رابطہ کریں۔",
      deletedAccount:
        "آپ کا اکاؤنٹ حذف ہو گیا ہے۔ مزید معلومات کے لئے support@knowingo.com سے رابطہ کریں۔",
      tooManyAttempts:
        "لاگ ان کرنے کی بہت ساری ناکام کوششیں۔ 5 منٹ میں دوبارہ کوشش کریں۔",
      permissionError:
        "لاگ ان کرنے کی اجازت صرف کمپنی کے موڈریٹروں کو ہے۔ نوونگو+ ایپ ڈاؤن لوڈ کریں اور اپنے موبائل ڈیوائس پر کھیلیں۔ مزید معلومات کے لئے support@knowingo.com سے رابطہ کریں۔",
      cookiesError:
        "ایسا لگتا ہے کہ آپ کا براؤزر تیسرا فریق کوکیز کو بلاک کرتا ہے۔ برائے مہربانی اپنے براؤزر کی سیکورٹی سیٹنگز میں اس سائٹ کے لئے انھیں فعال کریں۔",
      invalidEmail: "غیر درست ای میل پتہ",
      invalidPassword: "غیر درست پاس ورڈ",
      notConfirmed:
        "آپ کا اکاؤنٹ توثیق شدہ نہیں ہے۔ برائے مہربانی اپنا ای میل پتہ چیک کریں اور فعال کاری لنک پر کلک کریں",
      ssoAccount:
        "آپ کا اکاؤنٹ ایک مختلف لاگ ان فراہم کنندہ سے مربوط ہے۔ برائے مہربانی لاگ ان کے طریقہ کے طور پر ایس ایس او استعمال کریں۔",
      companyAccessDenied: "آپ کو اس کمپنی تک رسائی حاصل نہیں ہے۔",
      emailNotConfirmed:
        "لاگ ان کرنے سے پہلے اپنا میل باکس چیک کریں اور ای میل پتہ کی توثیق کریں۔ توثیقی لنک والا ای میل دوبارہ بھیجنے کے لئے یہاں کلک کریں۔",
      noSeats: "کمپنی میں کوئی سیٹ دستیاب نہیں ہے۔",
      alreadyLoggedIn:
        "پہلے ہی سے لاگ ان ہے۔ ڈیش بورڈ کھولنے کے لئے یہاں کلک کریں۔",
      unknownError: "نامعلوم خرابی",
    },
    confirmationRequestSent: "توثیقی لنک والا ای میل بھیج دیا گیا",
    successLogin: "لاگ ان ہو گیا!",
  },
  notFound: {
    header: "نہیں ملا",
    textCompany:
      "ایسا لگتا ہے کہ داخل کردہ کمپنی ڈومین نوونگو میں موجود نہیں ہے۔ برائے مہربانی داخل کردہ یو آر ایل کی تصدیق کریں۔",
  },
  ssoLoginPage: {
    header: "ایس ایس او فراہم کنندہ منتخب کریں",
    errors: {
      ssoNotSupported:
        "آپ کی تنظیم واحد سائن آن کو سپورٹ نہیں کرتی ہے۔ تفصیلات کے لئے برائے مہربانی اپنے منتظم سے رابطہ کریں۔",
    },
  },
  domainPage: {
    errors: {
      companyNotFound:
        "کمپنی نہیں ملی۔ برائے مہربانی داخل کردہ ڈومین نام چیک کریں۔",
    },
  },
  forgotPassword: {
    htmlTitle: "نوونگو | پاس ورڈ بھول گئے",
    formHeader: "پاس ورڈ دوبارہ ترتیب دیں",
    formEmail: "ای میل",
    formSubmit: "پاس ورڈ دوبارہ ترتیب دیں",
    backButton: "لاگ ان پر واپس جائیں",
    success: "برائے مہربانی اپنا ای میل چیک کریں",
    errors: {
      invalidEmail: "غیر درست ای میل پتہ",
      alreadyLoggedIn: "پہلے ہی سے لاگ ان ہے",
      userNotFound: "صارف نہیں ملا",
      unknownError: "نامعلوم خرابی",
    },
    inputs: {
      email: {
        placeholder: "ای میل پتہ",
      },
    },
  },
  dashboard: {
    htmlTitle: "نوونگو | ڈیش بورڈ",
    toggleNavigation: "نیویگیشن میں ادھر ادھر جائیں",
    welcome: "ہائے{0}",
  },
  pageNotFound: "صفحہ نہیں ملا",
  overview: {
    title: "ڈیش بورڈ",
  },
  activityLog: {
    columns: {
      activity: "سرگرمی",
      created: "تخلیق کردہ",
      user: "صارف",
    },
  },
};

module.exports = { ur };
